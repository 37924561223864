import * as React from "react";
import styles from "./fifthPLRC.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFourth,
  setSummary,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney } from "react-icons/md";
import { IdPlrcNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPlrcContentObject } from "../../../../../../redux/actions/plrcContentObject.actions";
import { useState } from "react";

function FifthPLRC(props: any) {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [independantCalculationOfRate, setIndependantCalculationOfRate] =
    useState<string>(
      props.plrcContentObject.idRateChangeNavigation
        .independantCalculationOfRate
    );
  const [rateChangeDescription, setRateChangeDescription] = useState<string>(
    props.plrcContentObject.idRateChangeNavigation.rateChangeDescription
  );
  const [oemParameters, setOemParameters] = useState<string>(
    props.plrcContentObject.idRateChangeNavigation.oemParametersRequired
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdAttachMoney className={styles.requestStageIcon} />
        <h5>Rate change (2/2):</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Independant calculation of rate</h4>
            <p>Describe the rule for calculation.</p>
            <textarea
              value={independantCalculationOfRate}
              onChange={(e) => setIndependantCalculationOfRate(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Rate change description</h4>
            <p>
              Give a summary description of the rate change including above
              parameters
            </p>
            <textarea
              value={rateChangeDescription}
              onChange={(e) => setRateChangeDescription(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>OEM parameters required</h4>
            <p>What parameters are needed for the rules or rate calculation?</p>
            <textarea
              value={oemParameters}
              onChange={(e) => setOemParameters(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setSummary();

          props.setPlrcContentObject({
            idRateChangeNavigation: {
              ...props.plrcContentObject.idRateChangeNavigation,
              independantCalculationOfRate: independantCalculationOfRate,
              rateChangeDescription: rateChangeDescription,
              oemParametersRequired: oemParameters,
            },
          });
        }}
        back={() => {
          props.setFourth();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    plrcContentObject: state.plrcContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFourth: () => dispatch(setFourth()),
    setSummary: () => dispatch(setSummary()),

    setPlrcContentObject: (plrcObject: IdPlrcNavigation) =>
      dispatch(setPlrcContentObject(plrcObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FifthPLRC);
