import { setUSER } from "../constants/user.types";
import { SetUserAction } from "../../interfaces/setUserAction";

const INITIAL_STATE = "unknown";

const UserReducer = function (state = INITIAL_STATE, action: SetUserAction) {
  switch (action.type) {
    //ADMIN
    case setUSER:
      return action.payload;
    default:
      return state;
  }
};

export default UserReducer;
