import { AiOutlineRead } from "react-icons/ai";
import { BsGear } from "react-icons/bs";
import { HiOutlineTicket } from "react-icons/hi";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdConnectWithoutContact } from "react-icons/md";
import { SiMaildotru } from "react-icons/si";
import { connect } from "react-redux";
import { IStakeholders } from "../../../../interfaces/stakeholders";
import {
  setContactList,
  setCurrentRequests,
  setRequest,
  setTicketing,
} from "../../../../redux/actions/currentPage.actions";
import { setStakeholdersObject } from "../../../../redux/actions/stakeholders.actions";
import styles from "./mainMenuTiles.module.scss";

function MainMenuTiles(props: any) {
  return (
    <div>
      <div className={styles.homeTilesHeaderContainer}>
        <h4>Menu</h4>
      </div>
      <div className={styles.homeTilesSection}>
        <div
          onClick={() => {
            props.setRequest();
            setStakeholdersObject({
              requestingPerson: "new",
              contactPerson: undefined,
              subsidiary: undefined,
              otherPartner: undefined,
              country: undefined,
              customerName: undefined,
              customerURL: undefined,
              customerContactPerson: undefined,
            });
          }}
        >
          <h3>Customized Solution</h3>
          <IoIosAddCircleOutline className={styles.icon} />
          <h4>Add request</h4>
        </div>
        <div
          onClick={() => {
            props.setTicketing();
            console.log("TPIRequest");
          }}
        >
          <h3>Ticketing Partner</h3>
          <HiOutlineTicket className={styles.icon} />

          <h4>Integrations</h4>
        </div>
        <div onClick={() => props.setCurrentRequests()}>
          <h3>Current Requests</h3>
          <BsGear className={styles.icon} />

          <h4>Read more</h4>
        </div>
        <div onClick={() => props.setContactList()}>
          <h3>Contact List</h3>
          <MdConnectWithoutContact className={styles.icon} />

          <h4>Read more</h4>
        </div>
        <div onClick={() => (window.location.href = "mailto:ccs@skidata.com")}>
          <h3>e-mail</h3>
          <SiMaildotru className={styles.icon} />

          <h4>Send Message</h4>
        </div>
        <div onClick={() => props.setHelp()}>
          <h3>Help</h3>
          <AiOutlineRead className={styles.icon} />

          <h4>Read more</h4>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    page: state.page,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setContactList: () => dispatch(setContactList()),
    setCurrentRequests: () => dispatch(setCurrentRequests()),
    setRequest: () => dispatch(setRequest()),
    setTicketing: () => dispatch(setTicketing()),
    setStakeholdersObject: (stakeholdersObject: IStakeholders) =>
      dispatch(setStakeholdersObject(stakeholdersObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuTiles);
