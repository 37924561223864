import {
  setHOME,
  setREQUEST,
  setCURRENTREQUESTS,
  setHELP,
  setCONTACTLIST,
  setTICKETING,
  setADMINPANEL,
  setREQUESTVIEW,
} from "../constants/currentPage.types";

const INITIAL_STATE = "home";

const CurrentPageReducer = function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    //ADMIN
    case setHOME:
      return "home";
    case setREQUEST:
      return "request";
    case setTICKETING:
      return "ticketing";
    case setCURRENTREQUESTS:
      return "currentRequests";
    case setHELP:
      return "help";
    case setCONTACTLIST:
      return "contactList";
    case setADMINPANEL:
      return "adminPanel";
    case setREQUESTVIEW:
      return "requestView";
    default:
      return state;
  }
};

export default CurrentPageReducer;
