import { IRequestObject } from "../../interfaces/serviceModels/IRequestJSON";
import {
  resetJSONREQUESTOBJECT,
  setJSONREQUESTOBJECT,
} from "../constants/jsonRequestObject.types";

export const setJsonRequestObject = (requestObject: IRequestObject | null) => {
  return {
    type: setJSONREQUESTOBJECT,
    payload: requestObject,
  };
};

export const resetJsonRequestObject = () => {
  return {
    type: resetJSONREQUESTOBJECT,
  };
};
