import { IRequestObject } from "../../interfaces/serviceModels/IRequestJSON";
import {
  resetJSONREQUESTOBJECT,
  setJSONREQUESTOBJECT,
} from "../constants/jsonRequestObject.types";
const INITIAL_STATE: IRequestObject | null = null;

const JsonRequestObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IRequestObject }
) {
  switch (action.type) {
    case setJSONREQUESTOBJECT:
      return action.payload;
    case resetJSONREQUESTOBJECT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default JsonRequestObjectReducer;
