import { IUserInterfaceObject } from "../../interfaces/IUserInterfaceObject";
import { setUSERINTERFACEOBJECT } from "../constants/userInterfaceObject.types";

export const setUserInterfaceObject = (
  userInterfaceObject: IUserInterfaceObject
) => {
  return {
    type: setUSERINTERFACEOBJECT,
    payload: userInterfaceObject,
  };
};
