import { IUseCase } from "../../interfaces/IUseCase";
import { SetUseCasesAction } from "../../interfaces/setUseCasesAction";
import { setUSECASESOBJECT } from "../constants/useCases.types";

const INITIAL_STATE: IUseCase[] = [];

const UseCasesObjectReducer = function (
  state = INITIAL_STATE,
  action: SetUseCasesAction
) {
  switch (action.type) {
    //ADMIN
    case setUSECASESOBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default UseCasesObjectReducer;
