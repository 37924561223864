import { useEffect, useState } from "react";
import { BsListUl } from "react-icons/bs";
import { FaRegQuestionCircle } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";

import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { connect } from "react-redux";
import {
  setHome,
  setRequest,
} from "../../../../redux/actions/currentPage.actions";
import TicketingPartnersTable from "../../molecules/ticketingPartnersTable/ticketingPartnersTable";

import styles from "./ticketingPartners.module.scss";

import {
  setFirstAfterType,
  setStakeholders,
} from "../../../../redux/actions/requestStage.actions ";
import { setTicketingRequest } from "../../../../redux/actions/requestType.actions";
import { IRequest } from "../../../../interfaces/IRequest";
import { setRequestObject } from "../../../../redux/actions/requestObject.actions";
import ActiveAccounts from "./ActiveAccounts/ActiveAccounts";
import Header from "../../../../assets/tickets.jpg";

function TicketingPartners(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [displayPartnersList, setDisplayPartnersList] =
    useState<Boolean>(false);
  const [tsiDocumentation, setTsiDocumentation] = useState();

  return (
    <div className={styles.contactList__mainContainer}>
      <div className={styles.contactList__buttonContainer}>
        {!displayPartnersList ? (
          <button onClick={() => props.setHome()}>
            <MdOutlineArrowBackIosNew />
            Back to Home
          </button>
        ) : (
          <button onClick={() => setDisplayPartnersList(!displayPartnersList)}>
            <MdOutlineArrowBackIosNew />
            Back
          </button>
        )}
      </div>

      <div className={styles.requestContainer}>
        <div className={styles.requestHeader}>
          <div className={styles.requestHeader__container}>
            <h2>Ticketing Partner Integrations</h2>

            <div className={styles.requestHeader__image}>
              <div></div>
              <img src={Header} />
            </div>
          </div>
        </div>
        {!displayPartnersList ? (
          <div className={styles.requestTypeTilesSection}>
            <div
              onClick={() => {
                props.setRequestObject({
                  requestLabel: "New TSI Request",
                  creationDate: new Date().toISOString(),
                  lastModifiedDate: new Date().toISOString(),
                  status: "requesting",
                  assignedDeveloper: "none",
                });
                props.setFirstAfterType();
                props.setTicketingRequest();
                props.setRequest();
              }}
            >
              <h3>Ticketing Partner</h3>
              <IoIosAddCircleOutline className={styles.icon} />
              <h4>Integration Request</h4>
            </div>
            <div onClick={() => setDisplayPartnersList(!displayPartnersList)}>
              <h3>Ticketing Partner</h3>
              <BsListUl className={styles.icon} />
              <h4>See List</h4>
            </div>
            <a href="https://nagragrp.sharepoint.com/teams/TSIWorkbook/SitePages/TSI-Workbook-Introduction.aspx">
              <div onClick={() => props.setPspRequest()}>
                <h3>Documentation</h3>
                <FaRegQuestionCircle className={styles.icon} />
                <h4>Read more</h4>
              </div>
            </a>
          </div>
        ) : (
          <ActiveAccounts />
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setStakeholders: () => dispatch(setStakeholders()),
    setRequest: () => dispatch(setRequest()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setTicketingRequest: () => dispatch(setTicketingRequest()),
    setRequestObject: (requestObject: IRequest) =>
      dispatch(setRequestObject(requestObject)),
  };
};

export default connect(null, mapDispatchToProps)(TicketingPartners);
