import * as React from "react";
import { connect } from "react-redux";
import { setHome } from "../../../../redux/actions/currentPage.actions";
import { setPopOutFalse } from "../../../../redux/actions/popOutWindow.actions";
import { setPopOutBackToHome } from "../../../../redux/actions/popOutWindowType.actions";
import { ResetBar } from "../../../../redux/actions/progressBarStatus.actions";
import { setStakeholders } from "../../../../redux/actions/requestStage.actions ";
import styles from "./popOut.module.scss";
import { IRequest } from "../../../../interfaces/IRequest";
import { setRequestObject } from "../../../../redux/actions/requestObject.actions";
import { resetRequestObject } from "../../../../redux/actions/currentRequestObject.actions";
import { setDefaultRequestType } from "../../../../redux/actions/requestType.actions";
import { setAddResponseStatus } from "../../../../redux/actions/addResponseStatus.actions";
import { FaDownload } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { useEffect, useState } from "react";
import { IRequestObject } from "../../../../interfaces/serviceModels/IRequestJSON";
import {
  resetJsonRequestObject,
  setJsonRequestObject,
} from "../../../../redux/actions/jsonRequestObject.actions";
import { MdClose } from "react-icons/md";

function PopOut(props: any) {
  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(props.jsonRequestObject)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "request.json";

    link.click();
  };

  const [showTryAgainButton, setShowTryAgainButton] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowTryAgainButton(true), 7000);
  });

  return (
    <div
      className={styles.popOut__container}
      style={
        props.popOutWindowVisible ? { display: "block" } : { display: "none" }
      }
    >
      {props.popOutWindowType === "ticketingAccounts" && (
        <div className={styles.popOut__ticketingAccountWindow}>
          <div className={styles.popOut__ticketingAccountHeader}>
            <h4>Ticketing Partner Datails</h4>
            <div
              className={styles.popOut__closeButton__Wrapper}
              onClick={() => {
                props.setPopOutFalse();
                props.setPopOutBackToHome();
              }}
            >
              <MdClose />
            </div>
          </div>
          <div className={styles.popOut__ticketingAccountContent}>
            <div className={styles.popOut__ticketingAccountContent__columns}>
              <div className={styles.popOut__ticketingAccountContent__column}>
                <div>
                  <h5>Account Name:</h5>
                  <p>{props.currentAccountObject.accountName}</p>
                </div>
                <div>
                  <h5>Account Number:</h5>
                  <p>{props.currentAccountObject.accountNumber}</p>
                </div>
                <div>
                  <h5>Internal System Name:</h5>
                  <p>{props.currentAccountObject.accountNumber}</p>
                </div>
                <div>
                  <h5>Parrent Account:</h5>
                  <p>{props.currentAccountObject.parentAccount}</p>
                </div>
                <div>
                  <h5>Virtual Entity:</h5>
                  <p>{props.currentAccountObject.virtualEntity}</p>
                </div>
              </div>
              <div className={styles.popOut__ticketingAccountContent__column}>
                <div>
                  <h5>Owner:</h5>
                  <p>{props.currentAccountObject.owner}</p>
                </div>
                <div>
                  <h5>Primary Contact:</h5>
                  <p>{props.currentAccountObject.primaryContact}</p>
                </div>
                <div>
                  <h5>Business Segment:</h5>
                  <p>{props.currentAccountObject.businessSegment}</p>
                </div>
                <div>
                  <h5>Industry:</h5>
                  <p>{props.currentAccountObject.industry}</p>
                </div>
              </div>
            </div>
            <div className={styles.popOut__ticketingAccountContent__columns}>
              <div className={styles.popOut__ticketingAccountContent__column}>
                <div>
                  <h5>Location:</h5>
                  <p>{props.currentAccountObject.city}</p>
                  <p>{props.currentAccountObject.postCode}</p>
                  <p>{props.currentAccountObject.country}</p>
                </div>
              </div>
              <div className={styles.popOut__ticketingAccountContent__column}>
                <div>
                  <h5>Segment:</h5>
                  <p>Stadium</p>
                  <p>Fair</p>
                  <p>Amusement</p>
                </div>
              </div>
            </div>
            <div
              className={
                styles.popOut__ticketingAccountContent__columns__checkbox
              }
            >
              <div>
                <h5>Supported Data Carriers:</h5>
              </div>
              <div>
                <h5>Supported Use Cases:</h5>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.popOutWindowType === "backToHome" && (
        <div className={styles.popOut__window}>
          <h4 className={styles.popOut__header}>
            Your current request will not be save.
          </h4>
          <p>Do you want to go back to main menu anyway?</p>
          <div className={styles.popOut__buttons__container}>
            <button
              className={styles.popOut__yes__button}
              onClick={() => {
                props.setHome();
                props.resetRequestObject();
                props.setDefaultRequestType();
                props.setPopOutFalse();
                props.setStakeholders();
                props.resetBar();
              }}
            >
              YES
            </button>
            {/* <button
              className={styles.popOut__no__button}
              onClick={() => {
                props.setHome();
                props.setPopOutFalse();
                props.setStakeholders();
              }}
            >
              NO
            </button> */}

            <button
              className={styles.popOut__cancel__button}
              onClick={() => {
                props.setPopOutFalse();
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      )}
      {props.popOutWindowType === "requestSummary" && (
        <div className={styles.popOut__window}>
          {props.addResponseStatus === "" ? (
            <>
              <h4 className={styles.popOut__header}>Sending...</h4>
              <div className={styles.popOut__loadingIcon}>
                <AiOutlineLoading />
              </div>
              {showTryAgainButton && (
                <button
                  className={styles.popOut__button}
                  onClick={() => {
                    props.setPopOutFalse();
                    props.setAddResponseStatus("");
                    setShowTryAgainButton(false);
                  }}
                >
                  TRY AGAIN
                </button>
              )}
            </>
          ) : props.addResponseStatus === "201" ? (
            <>
              <h4 className={styles.popOut__header}>Thank you!</h4>
              <p className={styles.popOut__text}>
                Our Solution Request is now ready and will be processed by our
                CCS team. A confirmation email containing a summary document
                will be sent to {props.email} within a few minutes.
                Additionally, you can monitor the status of your created
                solution by accessing the "Current Requests" section in the
                menu.
              </p>
              <button
                className={styles.popOut__button}
                onClick={() => {
                  props.setHome();
                  props.resetRequestObject();
                  props.setDefaultRequestType();
                  props.setPopOutFalse();
                  props.setPopOutBackToHome();
                  props.setStakeholders();
                  props.resetBar();
                  props.setAddResponseStatus("");
                  props.resetJsonRequestObject();
                }}
              >
                BACK TO MENU
              </button>
            </>
          ) : (
            <>
              <h4 className={styles.popOut__header}>
                Service error, but nothing to worry...
              </h4>
              <p className={styles.popOut__text}>
                We apologize for any inconvenience caused. Currently, we are
                facing connectivity issues with our service. Rest assured, our
                team is actively working to resolve this matter as quickly as
                possible. In the meantime, if you would like to proceed with
                your request, we kindly suggest trying to send it again by going
                back to the summary and pressing the "Send" button again.
                Alternatively, you can download your request in JSON format here
                and send it to us as an attachment via email to ccs@skidata.com.
                Additionally, please also attach all request related files to
                the message. We greatly appreciate your understanding and
                patience.
              </p>
              <div
                className={styles.popOut__downloadContainer}
                onClick={() => {
                  exportData();
                  console.log("Download");
                }}
              >
                <span>
                  <FaDownload />
                </span>

                <p>DOWNLOAD REQUEST FILE</p>
              </div>

              <div className={styles.popOut__buttons__container}>
                <button
                  className={styles.popOut__button}
                  onClick={() => {
                    props.setPopOutFalse();
                    props.setAddResponseStatus("");
                  }}
                >
                  TRY AGAIN
                </button>
                <button
                  className={styles.popOut__button}
                  onClick={() => {
                    props.setHome();
                    props.resetRequestObject();
                    props.setDefaultRequestType();
                    props.setPopOutFalse();
                    props.setPopOutBackToHome();
                    props.setStakeholders();
                    props.resetBar();
                    props.setAddResponseStatus("");
                    props.resetJsonRequestObject();
                  }}
                  style={{ background: "#71787d" }}
                >
                  BACK TO MENU
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    email: state.email,
    popOutWindowVisible: state.popOutWindowVisible,
    popOutWindowType: state.popOutWindowType,
    addResponseStatus: state.addResponseStatus,
    jsonRequestObject: state.jsonRequestObject,
    currentAccountObject: state.currentAccountObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetBar: () => dispatch(ResetBar()),
    setHome: () => dispatch(setHome()),
    setPopOutFalse: () => dispatch(setPopOutFalse()),
    setStakeholders: () => dispatch(setStakeholders()),
    setPopOutBackToHome: () => dispatch(setPopOutBackToHome()),
    resetRequestObject: () => dispatch(resetRequestObject()),
    setDefaultRequestType: () => dispatch(setDefaultRequestType()),
    setAddResponseStatus: (status: string) =>
      dispatch(setAddResponseStatus(status)),
    resetJsonRequestObject: () => dispatch(resetJsonRequestObject()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopOut);
