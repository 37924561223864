import { IAccountJSON } from "../../interfaces/serviceModels/IAccountsJSON";
import { setCURRENTACCOUNTOBJECT } from "../constants/currentAccountObject.types";
import {
  resetREQUESTOBJECT,
  setREQUESTOBJECT,
} from "../constants/requestObject.types";

const INITIAL_STATE: IAccountJSON | null = null;

const CurrentAccountObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IAccountJSON }
) {
  switch (action.type) {
    case setCURRENTACCOUNTOBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default CurrentAccountObjectReducer;
