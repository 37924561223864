import {
  setDEFAULTREQUESTTYPE,
  setEPIREQUEST,
  setFISCALDEVICEREQUEST,
  setPARKINGRATE,
  setPARKINGREQUEST,
  setPSPREQUEST,
  setTICKETINGREQUEST,
  setUPLOADREQUESTTYPE,
} from "../constants/requestType.types";

const INITIAL_STATE: string | null = null;

const RequestTypeReducer = function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    //ADMIN
    case setPARKINGREQUEST:
      return "parkingRequest";
    case setPARKINGRATE:
      return "parkingRate";
    case setTICKETINGREQUEST:
      return "ticketingRequest";
    case setPSPREQUEST:
      return "pspRequest";
    case setEPIREQUEST:
      return "epiRequest";
    case setFISCALDEVICEREQUEST:
      return "fiscalDeviceRequest";
    case setUPLOADREQUESTTYPE:
      return "uploadRequest";
    case setDEFAULTREQUESTTYPE:
      return null;
    default:
      return state;
  }
};

export default RequestTypeReducer;
