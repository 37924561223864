import * as React from "react";
import { connect } from "react-redux";

import styles from "./requestViewPSP.module.scss";
import { useEffect, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import Download from "../../../atoms/downloadButton/download";

function RequestViewPSP(props: any) {
  interface FileListProps {
    url: string;
  }
  const containerName = "requestfiles";
  const accountName = "requestpoint";
  const sasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T23:37:22Z&st=2023-04-18T14:37:22Z&spr=https&sig=kk7j1tFdRCxNp5MZAJhH2kn9JNajJpzhyrKve5DD%2B08%3D";

  const [files, setFiles] = useState<string[]>([]);

  var directory = `${props.currentRequestObject.idStakeholdersNavigation.requestingPerson}/${props.currentRequestObject.requestLabel}/`;

  useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net${sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(fileList);
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [
    accountName,
    sasToken,
    props.containerName,
    directory,
    props.currentRequestObject,
  ]);

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Product Description</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>SD product(s) to integrate</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idProductDescriptionNavigation.sdproductToIntegrate.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idProductDescriptionNavigation.sdproductToIntegrate
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Country(s) or region(s)</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idProductDescriptionNavigation.countryRegion.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idProductDescriptionNavigation.countryRegion
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>With revenue-share?</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idProductDescriptionNavigation.revenueShare.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idProductDescriptionNavigation.revenueShare
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>API Description</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>PSP API documentation (URL)</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idApiDescriptionSetNavigation.apiDescription.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idApiDescriptionSetNavigation.apiDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>PSP API documentation (files):</h4>
            {files.filter((str) => str.includes("apides")).length > 0 ? (
              files
                .filter((str) => str.includes("apides"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Integration Description</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idIntegrationDescriptionNavigation.choiceOfIntegrationMethd
                .length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idIntegrationDescriptionNavigation.choiceOfIntegrationMethd
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Process flow description(s)</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idIntegrationDescriptionNavigation.processFlowDescription
                .length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idIntegrationDescriptionNavigation.processFlowDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Asynchronous callbacks or notifications</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idIntegrationDescriptionNavigation.asynchronusCallback.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idIntegrationDescriptionNavigation.asynchronusCallback
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Localisation data</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Localization: Languages(s)</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idLocalisationDataNavigation.language.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idLocalisationDataNavigation.language
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Locale</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idLocalisationDataNavigation.locale.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idLocalisationDataNavigation.locale
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Payment methods to support</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idLocalisationDataNavigation.paymentMethods.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idLocalisationDataNavigation.paymentMethods
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Others:</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Branding</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idPspOthersNavigation.branding.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idPspOthersNavigation.branding
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>PSP testing endpoint</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idPspOthersNavigation.testingEndpoint.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idPspOthersNavigation.testingEndpoint
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Test endpoint connection credentials</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idPspOthersNavigation.testEndopointCredentials.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idPspOthersNavigation.testEndopointCredentials
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>PSP Production endpoint</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idPspOthersNavigation.productionEndpoint.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idPspOthersNavigation.productionEndpoint
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Production endpoint connection credentials</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idPspOthersNavigation.productionEdpointCredentials.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idPspOthersNavigation.productionEdpointCredentials
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Test credit cards and phone numbers</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPspNavigation
                .idPspOthersNavigation.testCardsAndPhone.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPspNavigation
                  .idPspOthersNavigation.testCardsAndPhone
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Logo file</h4>
            {files.filter((str) => str.includes("logofile")).length > 0 ? (
              files
                .filter((str) => str.includes("logofile"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    currentRequestObject: state.currentRequestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestViewPSP);
