import * as React from "react";
import { connect } from "react-redux";

import styles from "./requestViewUpload.module.scss";
import { useEffect, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import Download from "../../../atoms/downloadButton/download";

function RequestViewUpload(props: any) {
  interface FileListProps {
    url: string;
  }
  const containerName = "requestfiles";
  const accountName = "requestpoint";
  const sasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T23:37:22Z&st=2023-04-18T14:37:22Z&spr=https&sig=kk7j1tFdRCxNp5MZAJhH2kn9JNajJpzhyrKve5DD%2B08%3D";

  const [files, setFiles] = useState<string[]>([]);

  var directory = `${props.currentRequestObject.idStakeholdersNavigation.requestingPerson}/${props.currentRequestObject.requestLabel}/`;

  useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net${sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(fileList);
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [
    accountName,
    sasToken,
    props.containerName,
    directory,
    props.currentRequestObject,
  ]);

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>File Uploaded</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>File to send:</h4>
            {files.filter((str) => str.includes("uploadReq")).length > 0 ? (
              files
                .filter((str) => str.includes("uploadReq"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
            <h4>Additional Description</h4>
            <p>
              {
                props.currentRequestObject.idContentNavigation
                  .idUploadNavigation.additionalDescription
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    currentRequestObject: state.currentRequestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestViewUpload);
