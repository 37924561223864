import * as React from "react";

import { connect } from "react-redux";

import UseCases from "./useCases/useCases";
import Context from "./context/context";
import UserInterface from "./userInterface/userInterface";
import Reports from "./reports/reports";
import { useEffect } from "react";
import Acceptance from "./acceptance/acceptance";
import OutOfScope from "./outOfScope/outOfScope";
import Summary from "../summary/summary";
import { setPopOutTrue } from "../../../../../redux/actions/popOutWindow.actions";

function PLGR(props: any) {
  useEffect(() => {
    document.getElementById("logo")?.scrollIntoView({ behavior: "smooth" });
  }, [props.progressBarStatus]);

  return (
    <>
      {props.requestStage === "firstAfterType" && <UseCases />}
      {props.requestStage === "context" && <Context />}
      {props.requestStage === "userInterface" && <UserInterface />}
      {props.requestStage === "reports" && <Reports />}
      {props.requestStage === "acceptance" && <Acceptance />}
      {props.requestStage === "outOfScope" && <OutOfScope />}
      {props.requestStage === "summary" && <Summary />}
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestStage: state.requestStage,
    progressBarStatus: state.progressBarStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutTrue: () => dispatch(setPopOutTrue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PLGR);
