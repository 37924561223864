import { setEmail } from "../actions/email.actions";
import { setEMAIL } from "../constants/email.types";

const INITIAL_STATE = "unknown";

const EmailReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: string }
) {
  switch (action.type) {
    //ADMIN
    case setEMAIL:
      return action.payload;
    default:
      return state;
  }
};

export default EmailReducer;
