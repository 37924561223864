import * as React from "react";
import styles from "./context.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFirstAfterType,
  setUseCases,
  setUserInterface,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";

import { FaBuromobelexperte } from "react-icons/fa";
import { setFunctionsAndContext } from "../../../../../../redux/actions/functionsAndContext.actions";
import { IFunctionsAndContext } from "../../../../../../interfaces/IFunctionsAndContext";
import { useEffect, useState } from "react";

function Context(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [functionsAndContextObject, setFunctionsAndContextObject] =
    useState<IFunctionsAndContext>(props.funtionsAndContext);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <FaBuromobelexperte className={styles.requestStageIcon} />
        <h5>Functions and Context</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Functions / Features</h4>
            <p>
              If the usecase descriptions are insufficient, specific required
              features of the solution can be described in detail separately
              e.g. a monthly status report. This is also useful for considering
              all possible edge-cases and errors situations.
            </p>
            <textarea
              value={functionsAndContextObject.functionsFeatures}
              onChange={(e: any) =>
                setFunctionsAndContextObject({
                  functionsFeatures: e.target.value,
                  solutionContext: functionsAndContextObject.solutionContext,
                  supportedPlenv: functionsAndContextObject.supportedPlenv,
                })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Solution Context</h4>
            <p>
              Which internal or external systems/components/modules play a part
              in the solution e.g. CRM, SAP, ERP or BI system? What interfaces
              exist between solution components? Describe clearly the system
              boundaries to help define the scope of the solution.
            </p>
            <textarea
              value={functionsAndContextObject.solutionContext}
              onChange={(e: any) =>
                setFunctionsAndContextObject({
                  functionsFeatures:
                    functionsAndContextObject.functionsFeatures,
                  solutionContext: e.target.value,
                  supportedPlenv: functionsAndContextObject.supportedPlenv,
                })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Supported P.L environment</h4>
            <p>
              What versions of Parking.Logic must be supported by this solution?
            </p>
            <textarea
              value={functionsAndContextObject.supportedPlenv}
              onChange={(e: any) =>
                setFunctionsAndContextObject({
                  functionsFeatures:
                    functionsAndContextObject.functionsFeatures,
                  solutionContext: functionsAndContextObject.solutionContext,
                  supportedPlenv: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setUserInterface();
          props.setFunctionsAndContext(functionsAndContextObject);
        }}
        back={() => {
          props.setFirstAfterType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    funtionsAndContext: state.functionsAndContext,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFunctionsAndContext: (functionsAndContextObject: IFunctionsAndContext) =>
      dispatch(setFunctionsAndContext(functionsAndContextObject)),
    setHome: () => dispatch(setHome()),
    setUserInterface: () => dispatch(setUserInterface()),
    setUseCases: () => dispatch(setUseCases()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Context);
