import * as React from "react";
import styles from "./fifthPSP.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFourth,
  setSummary,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdPayments } from "react-icons/md";
import Dropzone from "react-dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IdPspNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPspContentObject } from "../../../../../../redux/actions/pspContentObject.actions";
import { useEffect, useState } from "react";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";
import { addFileName } from "../../../../../../redux/actions/fileNames.actions";

function FifthPLRC(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [branding, setBranding] = useState<string>(
    props.pspContentObject.idPspOthersNavigation.branding
  );
  const [testingEndpoint, setTestingEndpoint] = useState<string>(
    props.pspContentObject.idPspOthersNavigation.testingEndpoint
  );
  const [testingEndpointCredentials, setTestingEndpointCredentials] =
    useState<string>(
      props.pspContentObject.idPspOthersNavigation.testEndopointCredentials
    );
  const [productionEndpoint, setProductionEndpoint] = useState<string>(
    props.pspContentObject.idPspOthersNavigation.productionEndpoint
  );
  const [productionEndpointCredentials, setProductionEndpointCredentials] =
    useState<string>(
      props.pspContentObject.idPspOthersNavigation.productionEdpointCredentials
    );
  const [testCreditCardsAndPhoneNumbers, setTestCreditCardsAndPhoneNumbers] =
    useState<string>(
      props.pspContentObject.idPspOthersNavigation.testCardsAndPhone
    );

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<Boolean>(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/${props.requestObject.creationDate}/logofile-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          setIsFileUploaded(true);
          props.addFileName(file.name);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  React.useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdPayments className={styles.requestStageIcon} />
        <h5>Others:</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Branding</h4>
            <p>
              If the PSP API supports some level of branding, what should be
              supported by the SKIDATA integration?
            </p>
            <textarea
              value={branding}
              onChange={(e) => setBranding(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>PSP testing endpoint</h4>
            <p>Give this URL unless it is documented in API</p>
            <textarea
              value={testingEndpoint}
              onChange={(e) => setTestingEndpoint(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Test endpoint connection credentials</h4>
            <p>
              E.g. Merchant ID, user/password for basic auth. SSL cert needed?
            </p>
            <textarea
              value={testingEndpointCredentials}
              onChange={(e) => setTestingEndpointCredentials(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>PSP Production endpoint</h4>
            <p>Give this URL if available (will be needed for final testing)</p>
            <textarea
              value={productionEndpoint}
              onChange={(e) => setProductionEndpoint(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Production endpoint connection credentials</h4>
            <p>
              E.g. Merchant ID, user/password for basic auth. SSL cert needed?
            </p>
            <textarea
              value={productionEndpointCredentials}
              onChange={(e) => setProductionEndpointCredentials(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Test credit cards and phone numbers</h4>
            <p>Needed for each use case and edge case</p>
            <textarea
              value={testCreditCardsAndPhoneNumbers}
              onChange={(e) =>
                setTestCreditCardsAndPhoneNumbers(e.target.value)
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Logo file</h4>
            <p>Only needed if we draw the pay button or for branding</p>
            <p>Please upload any relevant documents.</p>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {isFileUploaded ? <li>{file?.name}</li> : <li>No files</li>}
            </ul>
            {isFileUploaded && <p>File has been successfully uploaded. </p>}
            {uploadingError && <p>{uploadingError}</p>}
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setSummary();
          props.setPspContentObject({
            idPspOthersNavigation: {
              branding: branding,
              testingEndpoint: testingEndpoint,
              testEndopointCredentials: testingEndpointCredentials,
              productionEndpoint: productionEndpoint,
              productionEdpointCredentials: productionEndpointCredentials,
              testCardsAndPhone: testCreditCardsAndPhoneNumbers,
            },
          });
        }}
        back={() => {
          props.setFourth();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    pspContentObject: state.pspContentObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFourth: () => dispatch(setFourth()),
    setSummary: () => dispatch(setSummary()),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),

    setPspContentObject: (pspObject: IdPspNavigation) =>
      dispatch(setPspContentObject(pspObject)),
    addFileName: (fileName: string) => dispatch(addFileName(fileName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FifthPLRC);
