import * as React from "react";
import { connect } from "react-redux";

import styles from "./requestViewPLRC.module.scss";

function RequestViewPLRC(props: any) {
  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Rules for special case</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Applicable time-windows</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.applicableTimeWindows.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.applicableTimeWindows
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Restricted car parks</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.restrictedCarParks.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.restrictedCarParks
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Dependant on previous stays</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.dependantOnPreviousStays
                .length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.dependantOnPreviousStays
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Minimum/maximum stay duration</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.minMaxStayDuration.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.minMaxStayDuration
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Validation</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.validation.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.validation
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Payment method</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.paymentMethod.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.paymentMethod
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>P.L articles</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.plArticles.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.plArticles
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Use case description</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRulesForSpecialCaseNavigation.useCaseDescription.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRulesForSpecialCaseNavigation.useCaseDescription
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Rate change</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Change of article</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRateChangeNavigation.changeOfArticle.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRateChangeNavigation.changeOfArticle
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Change of rate table</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRateChangeNavigation.changeOfRateTable.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRateChangeNavigation.changeOfRateTable
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Adjustment of P.L rate</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRateChangeNavigation.adjustmentOfPlRate.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRateChangeNavigation.adjustmentOfPlRate
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Independant calculation of rate</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRateChangeNavigation.independantCalculationOfRate.length >
              0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRateChangeNavigation.independantCalculationOfRate
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Rate change description</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRateChangeNavigation.rateChangeDescription.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRateChangeNavigation.rateChangeDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>OEM parameters required</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idPlrcNavigation
                .idRateChangeNavigation.oemParametersRequired.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idPlrcNavigation
                  .idRateChangeNavigation.oemParametersRequired
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    currentRequestObject: state.currentRequestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestViewPLRC);
