import {
  setACCTEPTANCE,
  setCONTEXT,
  setFIFTH,
  setFIRSTAFTERTYPE,
  setFOURTH,
  setOUTOFSCOPE,
  setREPORTS,
  setREQUESTTYPE,
  setSECOND,
  setSTAKEHOLDERS,
  setSUMMARY,
  setTHIRD,
  setUSECASES,
  setUSERINTERFACE,
} from "../constants/requestStage.types ";

const INITIAL_STATE = "stakeholders";

const RequestStageReducer = function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case setSTAKEHOLDERS:
      return "stakeholders";
    case setREQUESTTYPE:
      return "requestType";
    case setUSECASES:
      return "useCases";
    case setCONTEXT:
      return "context";
    case setUSERINTERFACE:
      return "userInterface";
    case setREPORTS:
      return "reports";
    case setACCTEPTANCE:
      return "acceptance";
    case setOUTOFSCOPE:
      return "outOfScope";
    case setSUMMARY:
      return "summary";
    case setFIRSTAFTERTYPE:
      return "firstAfterType";
    case setSECOND:
      return "second";
    case setTHIRD:
      return "third";
    case setFOURTH:
      return "fourth";
    case setFIFTH:
      return "fifth";
    default:
      return state;
  }
};

export default RequestStageReducer;
