import {
  setPREVIOUSADMIN,
  setPREVIOUSCURRENT,
  setPREVIOUSHOME,
} from "../constants/previousPage.types";

const INITIAL_STATE = "home";

const PreviousPageReducer = function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    //ADMIN
    case setPREVIOUSHOME:
      return "previousHome";
    case setPREVIOUSADMIN:
      return "previousAdmin";
    case setPREVIOUSCURRENT:
      return "previousCurrent";

    default:
      return state;
  }
};

export default PreviousPageReducer;
