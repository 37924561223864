import * as React from "react";
import styles from "./thirdPLRC.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFourth,
  setSecond,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney } from "react-icons/md";
import { useEffect, useState } from "react";
import { IdPlrcNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPlrcContentObject } from "../../../../../../redux/actions/plrcContentObject.actions";

function ThirdPLRC(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [plArticles, setPlArticles] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation.plArticles
  );
  const [useCasesDescription, setUseCasesDescription] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation.useCaseDescription
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdAttachMoney className={styles.requestStageIcon} />
        <h5>Rules for special case (3/3):</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>P.L articles</h4>
            <p>
              Is the special rate dependant on specific articles? Which ones?
            </p>
            <textarea
              value={plArticles}
              onChange={(e) => setPlArticles(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Use case description</h4>
            <p>
              Give a description of the special case including all above
              conditions.
            </p>
            <textarea
              value={useCasesDescription}
              onChange={(e) => setUseCasesDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setFourth();
          props.setPlrcContentObject({
            idRulesForSpecialCaseNavigation: {
              ...props.plrcContentObject.idRulesForSpecialCaseNavigation,
              plArticles: plArticles,
              useCaseDescription: useCasesDescription,
            },
          });
        }}
        back={() => {
          props.setSecond();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    plrcContentObject: state.plrcContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setSecond: () => dispatch(setSecond()),
    setFourth: () => dispatch(setFourth()),

    setPlrcContentObject: (plrcObject: IdPlrcNavigation) =>
      dispatch(setPlrcContentObject(plrcObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPLRC);
