import {
  setDEFAULTREQUESTTYPE,
  setEPIREQUEST,
  setFISCALDEVICEREQUEST,
  setPARKINGRATE,
  setPARKINGREQUEST,
  setPSPREQUEST,
  setTICKETINGREQUEST,
  setUPLOADREQUESTTYPE,
} from "../constants/requestType.types";

export const setParkingRequest = () => {
  return {
    type: setPARKINGREQUEST,
  };
};

export const setParkingRate = () => {
  return {
    type: setPARKINGRATE,
  };
};

export const setTicketingRequest = () => {
  return {
    type: setTICKETINGREQUEST,
  };
};

export const setPspRequest = () => {
  return {
    type: setPSPREQUEST,
  };
};

export const setEpiRequest = () => {
  return {
    type: setEPIREQUEST,
  };
};

export const setFiscalDeviceRequest = () => {
  return {
    type: setFISCALDEVICEREQUEST,
  };
};

export const setUploadRequestType = () => {
  return {
    type: setUPLOADREQUESTTYPE,
  };
};

export const setDefaultRequestType = () => {
  return {
    type: setDEFAULTREQUESTTYPE,
  };
};
