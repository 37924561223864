export const setSTAKEHOLDERS = "setSTAKEHOLDERS";
export const setREQUESTTYPE = "setREQUESTTYPE";
export const setUSECASES = "setUSECASES";
export const setCONTEXT = "setCONTEXT";
export const setUSERINTERFACE = "setUSERINTERFACE";
export const setREPORTS = "setREPORTS";
export const setACCTEPTANCE = "setACCTEPTANCE";
export const setOUTOFSCOPE = "setOUTOFSCOPE";
export const setSUMMARY = "setSUMMARY";
export const setFIRSTAFTERTYPE = "setFIRSTAFTERTYPE";
export const setSECOND = "setSECOND";
export const setTHIRD = "setTHIRD";
export const setFOURTH = "setFOURTH";
export const setFIFTH = "setFIFTH";
