import * as React from "react";
import { connect } from "react-redux";

import styles from "./requestViewEPI.module.scss";
import { useEffect, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import Download from "../../../atoms/downloadButton/download";
import { IUseCase } from "../../../../../interfaces/IUseCase";

function RequestViewEPI(props: any) {
  interface FileListProps {
    url: string;
  }
  const containerName = "requestfiles";
  const accountName = "requestpoint";
  const sasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T23:37:22Z&st=2023-04-18T14:37:22Z&spr=https&sig=kk7j1tFdRCxNp5MZAJhH2kn9JNajJpzhyrKve5DD%2B08%3D";

  const [files, setFiles] = useState<string[]>([]);

  var directory = `${props.currentRequestObject.idStakeholdersNavigation.requestingPerson}/${props.currentRequestObject.requestLabel}/`;

  useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net${sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(fileList);
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [
    accountName,
    sasToken,
    props.containerName,
    directory,
    props.currentRequestObject,
  ]);

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Use Case(s)</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <div className={styles.checkboxes__container}>
              <div className={styles.checkboxes__cloumn__container}>
                <h5>Car</h5>
                <div className={styles.checkbox__container}>
                  <input
                    type="checkbox"
                    checked={
                      props.currentRequestObject.idContentNavigation
                        .idEpiNavigation.idStandardUseCasesNavigation.manualCash
                    }
                  />
                  <p>Manual Cash</p>
                </div>
                <div className={styles.checkbox__container}>
                  <input
                    type="checkbox"
                    checked={
                      props.currentRequestObject.idContentNavigation
                        .idEpiNavigation.idStandardUseCasesNavigation
                        .automaticPaymentMachine
                    }
                  />
                  <p>Automatic Payment Machine</p>
                </div>
                <div className={styles.checkbox__container}>
                  <input
                    type="checkbox"
                    checked={
                      props.currentRequestObject.idContentNavigation
                        .idEpiNavigation.idStandardUseCasesNavigation.exitColumn
                    }
                  />
                  <p>Exit Column</p>
                </div>
              </div>
              <div className={styles.checkboxes__cloumn__container}>
                <h5>Mountain</h5>
                <div className={styles.checkbox__container}>
                  <input
                    type="checkbox"
                    checked={
                      props.currentRequestObject.idContentNavigation
                        .idEpiNavigation.idStandardUseCasesNavigation.touchCash
                    }
                  />
                  <p>Touch Cash</p>
                </div>
                <div className={styles.checkbox__container}>
                  <input
                    type="checkbox"
                    checked={
                      props.currentRequestObject.idContentNavigation
                        .idEpiNavigation.idStandardUseCasesNavigation
                        .mountainSkiosk
                    }
                  />
                  <p>Skiosk</p>
                </div>
              </div>
              <div className={styles.checkboxes__cloumn__container}>
                <h5>Event</h5>
                <div className={styles.checkbox__container}>
                  <input
                    type="checkbox"
                    checked={
                      props.currentRequestObject.idContentNavigation
                        .idEpiNavigation.idStandardUseCasesNavigation
                        .eventSkiosk
                    }
                  />
                  <p>Skiosk</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Non-standard use case(s)</h3>

          <div className={styles.useCasesSummary__tableContainer}>
            <div className={styles.useCasesSummary__tableHeader}>
              <div>
                <p>Description</p>
              </div>
              <div>
                <p>Pre-conditions</p>
              </div>
              <div>
                <p>Post-conditions</p>
              </div>
            </div>
            {props.currentRequestObject.idContentNavigation.idEpiNavigation
              .epiUseCases.length > 0 ? (
              props.currentRequestObject.idContentNavigation.idEpiNavigation.epiUseCases.map(
                (useCase: IUseCase) => (
                  <div
                    className={
                      useCase ===
                      props.currentRequestObject.idContentNavigation.idEpiNavigation.epiUseCases.slice(
                        -1
                      )[0]
                        ? styles.useCasesSummary__tableRow__last
                        : styles.useCasesSummary__tableRow
                    }
                  >
                    <div>
                      <p>{useCase.description}</p>
                    </div>
                    <div>
                      <p>{useCase.preConditions}</p>
                    </div>
                    <div>
                      <p>{useCase.postConditions}</p>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className={styles.emptyTable}>
                <p>Empty</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Termianl Details</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Termianl Model</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTerminalDetailsNavigation.terminalModel.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTerminalDetailsNavigation.terminalModel
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Physical connection</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTerminalDetailsNavigation.physicalConnection.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTerminalDetailsNavigation.physicalConnection
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Card Types</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTerminalDetailsNavigation.cardTypes.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTerminalDetailsNavigation.cardTypes
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Programming interface documentation</h4>
            {files.filter((str) => str.includes("progrInter")).length > 0 ? (
              files
                .filter((str) => str.includes("progrInter"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
            <h4>Payment methods</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTerminalDetailsNavigation.paymentMethods.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTerminalDetailsNavigation.paymentMethods
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Testing Environment</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Test terminal availability</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTestEnvironmentNavigation.testTerminal.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTestEnvironmentNavigation.testTerminal
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Virtual ECR test environment</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTestEnvironmentNavigation.virtualEcrtest.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTestEnvironmentNavigation.virtualEcrtest
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Availability of test cards</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idTestEnvironmentNavigation.testCards.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idTestEnvironmentNavigation.testCards
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Configuration</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Currencies</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idConfigurationNavigation.currencies.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idConfigurationNavigation.currencies
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Languages</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idConfigurationNavigation.languages.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idConfigurationNavigation.languages
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Refunds</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idConfigurationNavigation.refunds.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idConfigurationNavigation.refunds
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Cancelation</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idConfigurationNavigation.cancelation.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idConfigurationNavigation.cancelation
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Others</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Loyalty</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idEpiOthersNavigation.loyality.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idEpiOthersNavigation.loyality
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>EOD / Settlement</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idEpiOthersNavigation.settlement.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idEpiOthersNavigation.settlement
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Multiple patial payments</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idEpiNavigation
                .idEpiOthersNavigation.multiplePayments.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idEpiNavigation
                  .idEpiOthersNavigation.multiplePayments
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Testing</h4>
            {files.filter((str) => str.includes("testEPI")).length > 0 ? (
              files
                .filter((str) => str.includes("testEPI"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    currentRequestObject: state.currentRequestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestViewEPI);
