import { combineReducers } from "redux";
import AcceptanceObjectReducer from "./acceptaceObject.reducer";
import CurrentPageReducer from "./currentPage.reducer";
import CustomerPersonsArrayReducer from "./customerPersonsArray.reducer";
import FileNamesReducer from "./fileNames.reducer";
import FunctionsAndContextReducer from "./functionsAndContext.reducer";
import OtherPLGRReducer from "./otherPLGRObject.reducer";
import PlrcContentObjectReducer from "./plrcContentObject.reducer";
import PopOutWindowReducer from "./popOutWindow.reducer";
import PopOutWindowTypeReducer from "./popOutWindowType.reducer";
import ProgressBarStatusReducer from "./progressBarStatus.reducer";
import PspContentObjectReducer from "./pspContentObject.reducer";
import ReportsObjectReducer from "./reportsObject.reducer";
import RequestObjectReducer from "./requestObject.reducer";
import RequestStageReducer from "./requestStage.reducer";
import RequestTypeReducer from "./requestType.reducer";
import StakeholdersObjectReducer from "./stakeholders.reducer";
import UseCasesObjectReducer from "./useCases.reducer";
import UserReducer from "./user.reducer";
import UserInterfaceObjectReducer from "./userInterfaceObject.reducer";
import CurrentRequestObjectReducer from "./currentRequestObject.reducer";
import PreviousPageReducer from "./previousPage.reducer";
import EpiContentObjectReducer from "./epiContentObject.reducer";
import EmailReducer from "./email.reducer";
import TsiContentObjectReducer from "./tsiContentObject.reducer";
import AddResponseStatusReducer from "./addResponseStatus.reducer";
import JsonRequestObjectReducer from "./jsonRequestObject.reducer";
import CurrentAccountObjectReducer from "./currentAccountObject.reducer";
//Reducers

const rootReducer = combineReducers({
  //Genreral
  page: CurrentPageReducer,
  previousPage: PreviousPageReducer,
  user: UserReducer,
  email: EmailReducer,
  customerContactPersonArray: CustomerPersonsArrayReducer,
  popOutWindowVisible: PopOutWindowReducer,
  popOutWindowType: PopOutWindowTypeReducer,
  //Request View
  currentRequestObject: CurrentRequestObjectReducer,
  //Request
  requestObject: RequestObjectReducer,
  stakeholders: StakeholdersObjectReducer,
  requestStage: RequestStageReducer,
  requestType: RequestTypeReducer,
  progressBarStatus: ProgressBarStatusReducer,
  fileNames: FileNamesReducer,
  addResponseStatus: AddResponseStatusReducer,
  jsonRequestObject: JsonRequestObjectReducer,
  currentAccountObject: CurrentAccountObjectReducer,
  //PLGR
  useCasesObject: UseCasesObjectReducer,
  functionsAndContext: FunctionsAndContextReducer,
  userInterfaceObject: UserInterfaceObjectReducer,
  reportsObject: ReportsObjectReducer,
  acceptanceObject: AcceptanceObjectReducer,
  otherPLGRObject: OtherPLGRReducer,
  //PLRC
  plrcContentObject: PlrcContentObjectReducer,
  //TSI
  tsiContentObject: TsiContentObjectReducer,
  //PSP
  pspContentObject: PspContentObjectReducer,
  //EPI
  epiContentObject: EpiContentObjectReducer,
});

export default rootReducer;
