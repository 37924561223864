import { useEffect, useState } from "react";
import styles from "./searchBar.module.scss";
import { IAccountJSON } from "../../../../interfaces/serviceModels/IAccountsJSON";
import axios from "axios";
import { connect } from "react-redux";
import { setPopOutTrue } from "../../../../redux/actions/popOutWindow.actions";
import { setPopOutTicketingAccounts } from "../../../../redux/actions/popOutWindowType.actions";
import { setCurrentAccountObject } from "../../../../redux/actions/currentAccountObject.actions";

function SearchBar(props: any) {
  const [inputValue, setInputValue] = useState<string>("");
  const [ticketingPartnersList, setTicketingPartnersList] = useState<
    IAccountJSON[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredPartnersList, setFilteredPartnersList] = useState<
    IAccountJSON[]
  >([]);

  useEffect(() => {
    if (isLoading) {
      axios
        .get(
          `https://requestpointservice20230301084348.azurewebsites.net/ticketingPartners`
        )
        .then((response) => {
          setTicketingPartnersList(response.data);
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  useEffect(() => {
    setFilteredPartnersList(
      ticketingPartnersList
        .filter((partner) =>
          partner.accountName?.toLowerCase().includes(inputValue.toLowerCase())
        )
        .slice(0, 9)
    );
  }, [inputValue, ticketingPartnersList]);

  return (
    <div className={styles.searchBar__container}>
      <input
        placeholder="Search by account name..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      {inputValue !== "" && (
        <div className={styles.searchBar__searchRowsContainer}>
          {filteredPartnersList.length > 0 ? (
            filteredPartnersList.map((partner: IAccountJSON) => (
              <div
                onClick={() => {
                  setInputValue("");
                  props.setCurrentAccountObject(partner);
                  props.setPopOutTicketingAccounts();
                  props.setPopOutTrue();
                }}
              >
                {partner.accountName && <p>{partner.accountName}</p>}
              </div>
            ))
          ) : (
            <div>{<p>No results...</p>}</div>
          )}
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentAccountObject: (currentAccountObject: IAccountJSON) =>
      dispatch(setCurrentAccountObject(currentAccountObject)),
    setPopOutTrue: () => dispatch(setPopOutTrue()),
    setPopOutTicketingAccounts: () => dispatch(setPopOutTicketingAccounts()),
  };
};

export default connect(null, mapDispatchToProps)(SearchBar);
