import {
  setPOPOUTBACKTOHOME,
  setPOPOUTREQUESTSUMMARY,
  setPOPOUTTICKETINGACCOUNTS,
} from "../constants/popOutWindowType.types";

const INITIAL_STATE: string = "backToHome";

const PopOutWindowTypeReducer = function (
  state: string = INITIAL_STATE,
  action: any
) {
  switch (action.type) {
    case setPOPOUTBACKTOHOME:
      return "backToHome";
    case setPOPOUTREQUESTSUMMARY:
      return "requestSummary";
    case setPOPOUTTICKETINGACCOUNTS:
      return "ticketingAccounts";
    default:
      return state;
  }
};

export default PopOutWindowTypeReducer;
