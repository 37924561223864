import * as React from "react";
import styles from "./secondTSI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFirstAfterType,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { BsCalendarWeek } from "react-icons/bs";

import Calendar from "react-calendar";

import "./Calendar.css";
import { useEffect, useState } from "react";
import { IdTsiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setTsiContentObject } from "../../../../../../redux/actions/tsiContentObject.actions";
import { MdClose } from "react-icons/md";

function SecondTSI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let date = new Date(Date.now());

  const [selectedCategoryButton, setSelectedCategoryButton] =
    useState<number>(0);

  //Date States
  const [tsiIntegrationDate, setTsiIntegrationDate] = useState(
    props.tsiContentObject.idTimelineNavigation.kickOffDate
  );
  const [softwareInstallationDate, setSoftwareInstallationDate] = useState(
    props.tsiContentObject.idTimelineNavigation.softwareInstallation
  );
  const [hardwareInstallationDate, setHardwareInstallationDate] = useState(
    props.tsiContentObject.idTimelineNavigation.hardwareInstallation
  );
  const [userAcceptanceTestDate, setUserAcceptanceTestDate] = useState(
    props.tsiContentObject.idTimelineNavigation.userAcceptanceTest
  );
  const [firstLiveEventDate, setFirstLiveEventDate] = useState(
    props.tsiContentObject.idTimelineNavigation.firstLiveEvent
  );
  const [additionalDescription, setAdditionalDescription] = useState(
    props.tsiContentObject.idTimelineNavigation.additionalDescription
  );

  //Validation
  const [requestDatesValidationMessage, setRequestDatesValidationMessage] =
    useState<boolean>(false);

  function handleDateChange(date: Date | (Date | null)[]) {
    if (date !== null) {
      {
        selectedCategoryButton === 1 &&
          setTsiIntegrationDate(date.toLocaleString("de-DE").split(",")[0]);
      }
      {
        selectedCategoryButton === 2 &&
          setSoftwareInstallationDate(
            date.toLocaleString("de-DE").split(",")[0]
          );
      }
      {
        selectedCategoryButton === 3 &&
          setHardwareInstallationDate(
            date.toLocaleString("de-DE").split(",")[0]
          );
      }
      {
        selectedCategoryButton === 4 &&
          setUserAcceptanceTestDate(date.toLocaleString("de-DE").split(",")[0]);
      }
      {
        selectedCategoryButton === 5 &&
          setFirstLiveEventDate(date.toLocaleString("de-DE").split(",")[0]);
      }
    }
  }

  const [value, onChange] = useState(new Date());

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <BsCalendarWeek className={styles.requestStageIcon} />
        <h5>Timeline</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <p className={styles.description__text}>
            Please provide information about the important milestones within the
            project. To set up date click milestone type button and than select
            related date.
          </p>
          <div className={styles.buttonContainer}>
            <div>
              <button
                onClick={() => setSelectedCategoryButton(1)}
                className={
                  selectedCategoryButton === 1 &&
                  styles.timeline__selectedButton
                }
              >
                TSI Integration Kick-Off<span>*</span>
              </button>
              <button
                onClick={() => setSelectedCategoryButton(2)}
                className={
                  selectedCategoryButton === 2 &&
                  styles.timeline__selectedButton
                }
              >
                Software installation
              </button>
              <button
                onClick={() => setSelectedCategoryButton(3)}
                className={
                  selectedCategoryButton === 3 &&
                  styles.timeline__selectedButton
                }
              >
                {" "}
                Hardware installation
              </button>
              <button
                onClick={() => setSelectedCategoryButton(4)}
                className={
                  selectedCategoryButton === 4 &&
                  styles.timeline__selectedButton
                }
              >
                User acceptance tests
              </button>
              <button
                onClick={() => setSelectedCategoryButton(5)}
                className={
                  selectedCategoryButton === 5 &&
                  styles.timeline__selectedButton
                }
              >
                1st live event<span>*</span>
              </button>
            </div>
            <div className={styles.timeline__dateValues__container}>
              <div
                className={
                  requestDatesValidationMessage &&
                  tsiIntegrationDate === "Empty"
                    ? styles.timeline__date__container__error
                    : selectedCategoryButton === 1 &&
                      styles.timeline__date__container__active
                }
              >
                <p>{tsiIntegrationDate}</p>
              </div>
              <div
                className={
                  selectedCategoryButton === 2 &&
                  styles.timeline__date__container__active
                }
              >
                <p>{softwareInstallationDate}</p>
                {softwareInstallationDate !== "Empty" && (
                  <span onClick={() => setSoftwareInstallationDate("Empty")}>
                    <MdClose />
                  </span>
                )}
              </div>
              <div
                className={
                  selectedCategoryButton === 3 &&
                  styles.timeline__date__container__active
                }
              >
                <p>{hardwareInstallationDate}</p>
                {hardwareInstallationDate !== "Empty" && (
                  <span onClick={() => setHardwareInstallationDate("Empty")}>
                    <MdClose />
                  </span>
                )}
              </div>
              <div
                className={
                  selectedCategoryButton === 4 &&
                  styles.timeline__date__container__active
                }
              >
                <p>{userAcceptanceTestDate}</p>
                {userAcceptanceTestDate !== "Empty" && (
                  <span onClick={() => setUserAcceptanceTestDate("Empty")}>
                    <MdClose />
                  </span>
                )}
              </div>
              <div
                className={
                  requestDatesValidationMessage &&
                  firstLiveEventDate === "Empty"
                    ? styles.timeline__date__container__error
                    : selectedCategoryButton === 5 &&
                      styles.timeline__date__container__active
                }
                placeholder="..."
              >
                <p>{firstLiveEventDate}</p>
              </div>
            </div>
          </div>
          <div className={styles.calendarContainer}>
            <Calendar
              showDoubleView={true}
              value={value}
              onChange={(value: Date | (Date | null)[] | null, Event: any) => {
                if (value !== null) {
                  handleDateChange(value);
                }
              }}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Additional timeline description</h4>
            <p>
              Please consider any factors that may be relevant to the timeline.
            </p>
            <textarea
              value={additionalDescription}
              onChange={(e: any) => setAdditionalDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <p
        className={styles.errorMessage}
        style={
          requestDatesValidationMessage
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        Please select suitable dates for both the TSI integration Kick-Off and
        the first live event.
      </p>
      <NextBackButtons
        next={() => {
          if (
            tsiIntegrationDate !== "Empty" &&
            firstLiveEventDate !== "Empty"
          ) {
            props.setTsiContentObject({
              ...props.tsiContentObject,
              idTimelineNavigation: {
                kickOffDate: tsiIntegrationDate,
                softwareInstallation: softwareInstallationDate,
                hardwareInstallation: hardwareInstallationDate,
                userAcceptanceTest: userAcceptanceTestDate,
                firstLiveEvent: firstLiveEventDate,
                additionalDescription: additionalDescription,
              },
            });
            props.Increment();
            setRequestDatesValidationMessage(false);
            props.setThird();
          } else {
            setRequestDatesValidationMessage(true);
          }
        }}
        back={() => {
          props.setFirstAfterType();
          props.Decrement();
          setRequestDatesValidationMessage(false);
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    tsiContentObject: state.tsiContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setThird: () => dispatch(setThird()),
    setTsiContentObject: (tsiContentObject: IdTsiNavigation) =>
      dispatch(setTsiContentObject(tsiContentObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondTSI);
