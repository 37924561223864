import { connect } from "react-redux";
import styles from "./ticketingPartnersTable.module.scss";

import axios from "axios";
import { useEffect, useState } from "react";
import { IRequest } from "../../../../interfaces/IRequest";
import { setRequest } from "../../../../redux/actions/currentPage.actions";
import { setSummary } from "../../../../redux/actions/requestStage.actions ";
import { IAccountJSON } from "../../../../interfaces/serviceModels/IAccountsJSON";
import { setCurrentAccountObject } from "../../../../redux/actions/currentAccountObject.actions";
import { setPopOutTrue } from "../../../../redux/actions/popOutWindow.actions";
import { setPopOutTicketingAccounts } from "../../../../redux/actions/popOutWindowType.actions";

interface IRowExtended {
  rowId: number;
  isExtended: boolean;
}

function TicketingPartnersTable(props: any) {
  const [ticketingPartnersList, seTticketingPartnersList] = useState<
    IAccountJSON[]
  >([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isRowExtended, setIsRowExtended] = useState<IRowExtended>();

  useEffect(() => {
    if (isLoading) {
      axios
        .get(
          `https://requestpointservice20230301084348.azurewebsites.net/ticketingPartners`
        )
        .then((response) => {
          seTticketingPartnersList(response.data);
          console.log(response.data);
          setIsLoading(false);
        });
    }
  }, [ticketingPartnersList]);

  return (
    <div className={styles.ticketingPartnerTable__mainContainer}>
      <div className={styles.ticketingPartnerTableHeader}></div>
      <div className={styles.ticketingPartnerTable__table__header}>
        <div>
          <p>Account Name</p>
        </div>
        <div>
          <p>Account Number</p>
        </div>
        <div className={styles.ticketingPartnerTable__table__dateField}>
          <p>Parent Account</p>
        </div>
        <div>
          <p>Virtual Entity</p>
        </div>
        <div>
          <p>Owner</p>
        </div>
      </div>
      <div className={styles.ticketingPartnersList__rowsContainer}>
        {isLoading ? (
          <p>Loading</p>
        ) : ticketingPartnersList.length == 0 ? (
          <p>No requests</p>
        ) : (
          ticketingPartnersList.map((account: IAccountJSON) => (
            <>
              <div
                className={
                  isRowExtended?.rowId === account.idAccount &&
                  isRowExtended.isExtended
                    ? styles.ticketingPartnerTable__table__rowClicked
                    : styles.ticketingPartnerTable__table__row
                }
                onClick={() => {
                  props.setCurrentAccountObject(account);
                  props.setPopOutTicketingAccounts();
                  props.setPopOutTrue();
                }}
              >
                <div>
                  <p>{account.accountName}</p>
                </div>
                <div className={styles.ticketingPartnerTable__table__nameField}>
                  <p>{account.accountNumber}</p>
                </div>
                <div>
                  <p>{account.parentAccount}</p>
                </div>
                <div>
                  <p>{account.virtualEntity}</p>
                </div>
                <div>
                  <p>{account.owner}</p>
                </div>
              </div>
              {isRowExtended?.rowId === account.idAccount &&
                isRowExtended.isExtended && (
                  <div
                    className={styles.ticketingPartnerTable__table__extendedRow}
                    onClick={() => {
                      props.setCurrentAccountObject(account);
                      props.setPopOutTicketingAccounts();
                      props.setPopOutTrue();
                    }}
                  >
                    <div
                      className={
                        styles.ticketingPartnerTable__table__extendedHeader
                      }
                    >
                      <div>
                        <p>Addresss:</p>
                      </div>
                      <div>
                        <p>Primary contact:</p>
                      </div>
                      <div
                        className={
                          styles.ticketingPartnerTable__table__dateField
                        }
                      >
                        <p>Business segment:</p>
                      </div>
                      <div>
                        <p>Indrustry:</p>
                      </div>
                      <div>
                        <p>Relationship type:</p>
                      </div>
                    </div>
                    <div
                      className={
                        styles.ticketingPartnerTable__table__extendedRowData
                      }
                    >
                      <div
                        className={
                          styles.ticketingPartnerTable__table__row__address
                        }
                      >
                        <p>{account.city}</p>
                        <p>{account.country}</p>
                        <p>{account.postCode}</p>
                      </div>
                      <div
                        className={
                          styles.ticketingPartnerTable__table__nameField
                        }
                      >
                        <p>{account.primaryContact}</p>
                      </div>
                      <div>
                        <p>{account.businessSegment}</p>
                      </div>
                      <div>
                        <p>{account.industry}</p>
                      </div>
                      <div>
                        <p>{account.relationshipType}</p>
                      </div>
                    </div>
                  </div>
                )}
            </>
          ))
        )}
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    page: state.page,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRequest: () => dispatch(setRequest()),
    setSummary: () => dispatch(setSummary()),
    setCurrentAccountObject: (currentAccountObject: IAccountJSON) =>
      dispatch(setCurrentAccountObject(currentAccountObject)),
    setPopOutTrue: () => dispatch(setPopOutTrue()),
    setPopOutTicketingAccounts: () => dispatch(setPopOutTicketingAccounts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketingPartnersTable);
