import { IUserInterfaceObject } from "../../interfaces/IUserInterfaceObject";
import { setUSERINTERFACEOBJECT } from "../constants/userInterfaceObject.types";

const INITIAL_STATE: IUserInterfaceObject = {
  uiDescription: "",
};

const UserInterfaceObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IUserInterfaceObject }
) {
  switch (action.type) {
    case setUSERINTERFACEOBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default UserInterfaceObjectReducer;
