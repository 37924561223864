import { setPOPOUTFALSE, setPOPOUTTRUE } from "../constants/popOutWindow.types";

export const setPopOutTrue = () => {
  return {
    type: setPOPOUTTRUE,
  };
};

export const setPopOutFalse = () => {
  return {
    type: setPOPOUTFALSE,
  };
};
