import { IdEpiNavigation } from "../../interfaces/serviceModels/IRequestJSON";
import { setEPICONTENTOBJECT } from "../constants/epiContentObject.types";

const INITIAL_STATE: IdEpiNavigation = {
  idStandardUseCases: undefined,
  idTerminalDetails: undefined,
  idTestEnvironment: undefined,
  idConfiguration: undefined,
  idEpiOthers: undefined,
  epiUseCases: [],

  idStandardUseCasesNavigation: {
    manualCash: false,
    automaticPaymentMachine: false,
    exitColumn: false,
    touchCash: false,
    mountainSkiosk: false,
    eventSkiosk: false,
  },
  idTerminalDetailsNavigation: {
    terminalModel: "",
    physicalConnection: "",
    cardTypes: "",
    paymentMethods: "",
  },
  idTestEnvironmentNavigation: {
    testTerminal: "",
    virtualEcrTest: "",
    testCards: "",
  },
  idConfigurationNavigation: {
    currencies: "",
    languages: "",
    refunds: "",
    cancelation: "",
  },
  idEpiOthersNavigation: {
    loyality: "",
    settlement: "",
    multiplePayments: "",
    testing: "",
  },
};

const EpiContentObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IdEpiNavigation }
) {
  switch (action.type) {
    case setEPICONTENTOBJECT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default EpiContentObjectReducer;
