import * as React from "react";
import styles from "./requestType.module.scss";
//Components
import NextBackButtons from "../../../molecules/nextBackButtons/nextBackButtons";
//React Icons
import { HiOutlineTicket, HiReceiptTax } from "react-icons/hi";
import { BsDiagram3Fill } from "react-icons/bs";
import { FaCashRegister, FaParking } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
//Redux
import {
  setFirstAfterType,
  setStakeholders,
  setSummary,
  setUseCases,
} from "../../../../../redux/actions/requestStage.actions ";
import {
  Decrement,
  Increment,
} from "../../../../../redux/actions/progressBarStatus.actions";
import { connect } from "react-redux";
import {
  setEpiRequest,
  setFiscalDeviceRequest,
  setParkingRate,
  setParkingRequest,
  setPspRequest,
  setTicketingRequest,
  setUploadRequestType,
} from "../../../../../redux/actions/requestType.actions";
import { setRequestObject } from "../../../../../redux/actions/requestObject.actions";
import { IRequest } from "../../../../../interfaces/IRequest";

import {
  BlobServiceClient,
  AnonymousCredential,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";
import { Buffer } from "buffer";
import { AiOutlineLoading } from "react-icons/ai";
import { MdCloudDone } from "react-icons/md";

function RequestType(props: any) {
  const [errorMessage, setErrorMessage] = useState<Boolean>(false);
  const [requestLabelErrorMessage, setRequestLabelErrorMessage] =
    useState<boolean>();
  const [requestLabel, setRequestLabel] = useState<string>(
    props.requestObject.requestLabel
  );
  const [requestTypeAbbreviation, setRequestTypeAbbreviation] =
    useState<string>("");

  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    switch (props.requestType) {
      case "parkingRequest":
        setRequestTypeAbbreviation("PLGR");
        break;
      case "parkingRate":
        setRequestTypeAbbreviation("PLRC");
        break;
      case "ticketingRequest":
        setRequestTypeAbbreviation("TI");
        break;
      case "pspRequest":
        setRequestTypeAbbreviation("PSP");
        break;
      case "epiRequest":
        setRequestTypeAbbreviation("EPI");
        break;
      case "fiscalDeviceRequest":
        setRequestTypeAbbreviation("FD");
        break;
      case "uploadRequest":
        setRequestTypeAbbreviation("U");
        break;
      default:
        setRequestTypeAbbreviation("");
    }
  }, [props.requestType]);

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<Boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      handleUpload();
    }
  };

  const handleUpload = async () => {
    if (file) {
      setFileUploading(true);
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.user}/${requestLabel}/uploadReq-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${filePath}" uploaded to Azure Blob Storage.`);
          setFileUploading(false);
          setIsFileUploaded(true);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <h3>Request Information</h3>
        <div className={styles.requestNameContainer}>
          <h5>
            Request name<span>*</span>
          </h5>
          <p>
            A unique project name is important to identify the project and to
            prevent misunderstandings.
          </p>
          <input
            value={requestLabel}
            onChange={(e) => setRequestLabel(e.target.value)}
            placeholder="Name your request. (max 50char)"
            className={styles.requestNameInput}
          />
        </div>

        <p>Please choose your request type.</p>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.requestTypeTilesSection}>
          <div
            className={
              props.requestType === "parkingRequest"
                ? styles.markedTile
                : undefined
            }
            onClick={() => props.setParkingRequest()}
          >
            <h3>Parking.Logic </h3>
            <FaParking className={styles.icon} />
            <h4>Generic Request</h4>
          </div>
          <div
            className={
              props.requestType === "parkingRate"
                ? styles.markedTile
                : undefined
            }
            onClick={() => props.setParkingRate()}
          >
            <h3>Parking.Logic</h3>
            <FaParking className={styles.icon} />
            <h4>Special Rate Calculation</h4>
          </div>

          <div
            className={
              props.requestType === "pspRequest" ? styles.markedTile : undefined
            }
            onClick={() => props.setPspRequest()}
          >
            <h3>PSP</h3>
            <BsDiagram3Fill className={styles.icon} />
            <h4>Request</h4>
          </div>
          <div
            className={
              props.requestType === "epiRequest" ? styles.markedTile : undefined
            }
            onClick={() => props.setEpiRequest()}
          >
            <h3>EPI</h3>
            <FaCashRegister className={styles.icon} />
            <h4>Request</h4>
          </div>
          <div
            className={styles.disabledTile}
            //onClick={() => props.setFiscalDeviceIntegration()}
          >
            <h3>Fiscal Device</h3>
            <HiReceiptTax className={styles.icon} />
            <h4>Request</h4>
          </div>
          {/* <div
            className={
              props.requestType === "ticketingRequest"
                ? styles.markedTile
                : undefined
            }
            onClick={() => props.setTicketingRequest()}
          >
            <h3>Ticketing Partner </h3>
            <HiOutlineTicket className={styles.icon} />
            <h4>Integration</h4>
          </div> */}
          <div
            className={
              props.requestType === "uploadRequest"
                ? styles.markedTile
                : undefined
            }
            onClick={() => {
              props.setUploadRequestType();
              setFile(null);
              setIsFileUploaded(false);
              inputFile.current?.click();
            }}
          >
            <h3>Upload</h3>
            <HiOutlineTicket className={styles.icon} />
            <h4>Request File</h4>
          </div>
          <input
            type="file"
            ref={inputFile}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
      </div>

      <div
        className={styles.fileUpload__container}
        style={
          file != null ? { visibility: "visible" } : { visibility: "hidden" }
        }
      >
        {file != null && !isFileUploaded && (
          <p>
            File to upload: <span>{file.name}</span>
          </p>
        )}
        {!fileUploading && isFileUploaded ? (
          <div className={styles.loadingContainer__uploaded}>
            <p>Request File Uploaded</p>
            <span>
              <MdCloudDone />
            </span>
            <p>Go to the next page for summary and to submit your request.</p>
          </div>
        ) : (
          <div
            className={styles.loadingContainer}
            style={
              fileUploading
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            <p>Uploading file...</p>
            <span>
              <AiOutlineLoading />
            </span>
          </div>
        )}
      </div>

      <p
        className={styles.errorMessage}
        style={
          errorMessage ? { visibility: "visible" } : { visibility: "hidden" }
        }
      >
        Please choose the request type and than press next button.
      </p>
      <p
        className={styles.errorMessage}
        style={
          requestLabelErrorMessage
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        Please provide a descriptive title for your inquiry that consists of
        more than three characters.
      </p>
      <NextBackButtons
        next={() => {
          if (
            requestLabel !== "New Request" &&
            requestLabel.length >= 3 &&
            requestLabel !== ""
          ) {
            if (props.requestType === "uploadRequest") {
              handleUpload();
              setErrorMessage(false);
              setRequestLabelErrorMessage(false);
              if (isFileUploaded) {
                props.setSummary();
                props.setRequestObject({
                  ...props.requestObject,
                  requestLabel: requestLabel,
                  Content: { RequestType: requestTypeAbbreviation },
                });
              }
            } else if (props.requestType != null) {
              props.setFirstAfterType();
              props.Increment();
              props.setRequestObject({
                ...props.requestObject,
                requestLabel: requestLabel,
                Content: { RequestType: requestTypeAbbreviation },
              });
            } else {
              setErrorMessage(true);
            }
          } else {
            setRequestLabelErrorMessage(true);
          }
        }}
        back={() => {
          props.setStakeholders();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    requestObject: state.requestObject,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    //Request Stage
    setUseCases: () => dispatch(setUseCases()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setStakeholders: () => dispatch(setStakeholders()),
    setSummary: () => dispatch(setSummary()),
    //Progres Bar Status
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    //Request Types
    setParkingRequest: () => dispatch(setParkingRequest()),
    setParkingRate: () => dispatch(setParkingRate()),
    setTicketingRequest: () => dispatch(setTicketingRequest()),
    setPspRequest: () => dispatch(setPspRequest()),
    setEpiRequest: () => dispatch(setEpiRequest()),
    setFiscalDeviceIntegration: () => dispatch(setFiscalDeviceRequest()),
    setUploadRequestType: () => dispatch(setUploadRequestType()),
    //RequestObject
    setRequestObject: (requestObject: IRequest) =>
      dispatch(setRequestObject(requestObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestType);
