import * as React from "react";
import styles from "./reports.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setAcceptance,
  setUserInterface,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import Dropzone from "react-dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineFileExclamation } from "react-icons/ai";
import { useEffect, useState } from "react";
import { IReportsObject } from "../../../../../../interfaces/IReportsObject";
import { setReportsObject } from "../../../../../../redux/actions/reportsObject.actions";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";

function Reports(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [reportsObject, setReportsObject] = useState<IReportsObject>(
    props.reportsObject
  );

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-01-01T20:50:46Z&st=2023-04-18T11:50:46Z&spr=https&sig=ORA641LR96%2F96KnizZjE14RQ1qyGOJNCazTu8J1VxwQ%3D";
  const ContainerName = "requestfiles";

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<Boolean>(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/${props.requestObject.creationDate}/rep-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          setIsFileUploaded(true);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  React.useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <AiOutlineFileExclamation className={styles.requestStageIcon} />
        <h5>Reports</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Reports Description</h4>
            <p>
              Reports should be detailed carefully: - Headers - Rows & columns
              (header names) - Filters & sorting (save parameters for reuse?) -
              Layout & styles and branding & CI - Source of data (if necessary,
              correlation of every data element) - Data retention – how long? -
              Output (scheduled, printed, emailed, archived, external access) -
              Accessibility – user restrictions? - Localisation & multiple
              languages Mockups are very helpful. Are data expected from 3rd
              party systems? Should reports be made using P.L flexible reports
              or external reporting tool or sweb.Report integration?
            </p>
            <textarea
              value={reportsObject?.reportsDescription}
              onChange={(e) =>
                setReportsObject({ reportsDescription: e.target.value })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Reports Files</h4>

            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {isFileUploaded ? <li>{file?.name}</li> : <li>No files</li>}
            </ul>
            {isFileUploaded && <p>File has been successfully uploaded. </p>}
            {uploadingError && <p>{uploadingError}</p>}
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setAcceptance();
          props.setReportsObject(reportsObject);
        }}
        back={() => {
          props.setUserInterface();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    reportsObject: state.reportsObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setUserInterface: () => dispatch(setUserInterface()),
    setAcceptance: () => dispatch(setAcceptance()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setReportsObject: (reportsObject: IReportsObject) =>
      dispatch(setReportsObject(reportsObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
