import { IdTsiNavigation } from "../../interfaces/serviceModels/IRequestJSON";
import { setTSICONTENTOBJECT } from "../constants/tsiContentObject.types";

const INITIAL_STATE: IdTsiNavigation = {
  idTsiprojectInformation: undefined,
  idTimeline: undefined,
  idVenue: undefined,
  idIntegrationType: undefined,
  barcodeDataCarriers: [],
  idIntegrationTypeNavigation: {
    standardTsi: false,
    pii: false,
    extendedCheck: false,
    update: false,
  },
  idTimelineNavigation: {
    kickOffDate: "Empty",
    softwareInstallation: "Empty",
    hardwareInstallation: "Empty",
    userAcceptanceTest: "Empty",
    firstLiveEvent: "Empty",
    additionalDescription: "",
  },
  idTsiprojectInformationNavigation: {
    orderNumber: "",
    strategic: false,
    partnerCompanyName: "",
    partnerUrl: "",
  },
  idVenueNavigation: {
    fair: false,
    leisure: false,
    attraction: false,
    stadion: false,
    arena: false,
    venueOwnValue: "",
    eventBased: false,
    permanentOperation: false,
    eventOwnValue: "",
    expectedPerEvent: "",
    maximumPerHour: "",
    averagePerHour: "",
    averageperDay: "",
    averageperYear: "",
    isSingleClient: true,
    flexGate: "",
    varioGate: "",
    arenaGate: "",
    mobileGate: "",
    handheldGate: "",
    liteGate: "",
    doorGate: "",
    keyDetector: "",
    otherAmount: "",
  },
  rfidtechnology: [],
};

const TsiContentObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IdTsiNavigation }
) {
  switch (action.type) {
    case setTSICONTENTOBJECT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default TsiContentObjectReducer;
