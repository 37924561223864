import {
  setPREVIOUSADMIN,
  setPREVIOUSCURRENT,
  setPREVIOUSHOME,
} from "../constants/previousPage.types";

export const setPreviousHome = () => {
  return {
    type: setPREVIOUSHOME,
  };
};

export const setPreviousAdmin = () => {
  return {
    type: setPREVIOUSADMIN,
  };
};

export const setPreviousCurrent = () => {
  return {
    type: setPREVIOUSCURRENT,
  };
};
