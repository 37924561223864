import * as React from "react";
import styles from "./fourthPLRC.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFifth,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney } from "react-icons/md";
import { useState } from "react";
import { setPlrcContentObject } from "../../../../../../redux/actions/plrcContentObject.actions";
import { IdPlrcNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";

function FirstPLRC(props: any) {
  let requestTypeName = RequestTypeName(props.requestType);

  const [changeOfArticle, setChangeOfArticle] = useState<string>(
    props.plrcContentObject.idRateChangeNavigation.changeOfArticle
  );
  const [changeofRateTable, setChangeofRateTable] = useState<string>(
    props.plrcContentObject.idRateChangeNavigation.changeOfRateTable
  );
  const [adjustmentOfPlRatr, setAdjustmentOfPlRatr] = useState<string>(
    props.plrcContentObject.idRateChangeNavigation.adjustmentOfPlRate
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdAttachMoney className={styles.requestStageIcon} />
        <h5>Rate change (1/2):</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Change of article</h4>
            <p>Change to which article?</p>
            <textarea
              value={changeOfArticle}
              onChange={(e) => setChangeOfArticle(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Change of rate table</h4>
            <p>Change to which rate table?</p>
            <textarea
              value={changeofRateTable}
              onChange={(e) => setChangeofRateTable(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Adjustment of P.L rate</h4>
            <p>
              How should the rate be adjusted? E.g. percentage or fixed
              adjustment, set fee, or calculated based on rule parameters.
            </p>
            <textarea
              value={adjustmentOfPlRatr}
              onChange={(e) => setAdjustmentOfPlRatr(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setFifth();
          props.setPlrcContentObject({
            idRateChangeNavigation: {
              ...props.plrcContentObject.idRateChangeNavigation,
              changeOfArticle: changeOfArticle,
              changeOfRateTable: changeofRateTable,
              adjustmentOfPlRate: adjustmentOfPlRatr,
            },
          });
        }}
        back={() => {
          props.setThird();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    plrcContentObject: state.plrcContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setThird: () => dispatch(setThird()),
    setFifth: () => dispatch(setFifth()),

    setPlrcContentObject: (plrcObject: IdPlrcNavigation) =>
      dispatch(setPlrcContentObject(plrcObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstPLRC);
