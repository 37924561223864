import { setADDRESPONSESTATUS } from "../constants/addResponseStatus.types";

const INITIAL_STATE = "";

const AddResponseStatusReducer = function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case setADDRESPONSESTATUS:
      return action.payload;
    default:
      return state;
  }
};

export default AddResponseStatusReducer;
