import { IdPspNavigation } from "../../interfaces/serviceModels/IRequestJSON";
import { setPSPCONTENTOBJECT } from "../constants/pspContentObject.types";

const INITIAL_STATE: IdPspNavigation = {
  idProductDescription: undefined,
  idApiDescriptionSet: undefined,
  idIntegrationDescription: undefined,
  idLocalisationData: undefined,
  idPspOthers: undefined,
  idProductDescriptionNavigation: {
    sdproductToIntegrate: "",
    countryRegion: "",
    revenueShare: "",
  },
  idApiDescriptionSetNavigation: { apiDescription: "" },
  idIntegrationDescriptionNavigation: {
    choiceOfIntegrationMethd: "",
    processFlowDescription: "",
    asynchronusCallback: "",
  },
  idLocalisationDataNavigation: {
    language: "",
    locale: "",
    paymentMethods: "",
  },
  idPspOthersNavigation: {
    branding: "",
    testingEndpoint: "",
    testEndopointCredentials: "",
    productionEndpoint: "",
    productionEdpointCredentials: "",
    testCardsAndPhone: "",
  },
};

const PspContentObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IdPspNavigation }
) {
  switch (action.type) {
    case setPSPCONTENTOBJECT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default PspContentObjectReducer;
