import {
  IdPlrcNavigation,
  IdPspNavigation,
} from "../../interfaces/serviceModels/IRequestJSON";
import { setPLRCCONTENTOBJECT } from "../constants/plrcContentObject.types";

const INITIAL_STATE: IdPlrcNavigation = {
  idRateChangeNavigation: {
    changeOfArticle: "",
    changeOfRateTable: "",
    adjustmentOfPlRate: "",
    independantCalculationOfRate: "",
    rateChangeDescription: "",
    oemParametersRequired: "",
  },
  idRulesForSpecialCaseNavigation: {
    applicableTimeWindows: "",
    restrictedCarParks: "",
    dependantOnPreviousStays: "",
    minMaxStayDuration: "",
    validation: "",
    paymentMethod: "",
    plArticles: "",
    useCaseDescription: "",
  },
};

const PlrcContentObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IdPlrcNavigation }
) {
  switch (action.type) {
    case setPLRCCONTENTOBJECT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default PlrcContentObjectReducer;
