import { ICustomerContactPerson } from "../../interfaces/ICustomerContactPerson";
import { setCUSTOMERPERSONS } from "../constants/customerPersonsArray.types";

export const setCustomerPersons = (
  customerPersonsArray: ICustomerContactPerson[]
) => {
  return {
    type: setCUSTOMERPERSONS,
    payload: customerPersonsArray,
  };
};
