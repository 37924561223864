import * as React from "react";

import styles from "./index.module.scss";

import { connect } from "react-redux";

import BackToMenuButton from "../../atoms/backToMenuButton/backToMenuButton";
import Stakeholders from "./stakeholders/stakeholders";
import ProgressBar from "@ramonak/react-progress-bar";

import RequestType from "./requestType/requestType";

import { useEffect } from "react";

import { setPopOutTrue } from "../../../../redux/actions/popOutWindow.actions";
import PLGR from "./PLGR";
import PLRC from "./PLRC";
import PSP from "./PSP";
import TSI from "./TSI";
import EPI from "./EPI";

import Header from "../../../../assets/header.jpg";
import HeaderTickets from "../../../../assets/tickets.jpg";
import RequestTypeName from "./logic/requestTypeName.bs";
import Summary from "./summary/summary";

function Request(props: any) {
  useEffect(() => {
    document.getElementById("logo")?.scrollIntoView({ behavior: "smooth" });
  }, [props.progressBarStatus]);

  let requestTypeName = RequestTypeName(props.requestType);

  return (
    <div className={styles.requestMainCotnainer}>
      <BackToMenuButton
        onClick={() => {
          props.setPopOutTrue();
        }}
      />
      <div className={styles.requestContainer}>
        <div className={styles.requestHeader}>
          <div className={styles.requestHeader__container}>
            {props.requestType !== null ? (
              <h2>
                {requestTypeName[0]} {requestTypeName[1]}
              </h2>
            ) : (
              <h2>Customized Solution Request</h2>
            )}

            <div className={styles.requestHeader__image}>
              <div></div>
              {props.requestType === "ticketingRequest" ? (
                <img src={HeaderTickets} />
              ) : (
                <img src={Header} />
              )}
            </div>
          </div>
        </div>

        <ProgressBar
          completed={props.progressBarStatus}
          bgColor="goldenrod"
          borderRadius="0"
        />
        {props.requestStage === "stakeholders" && <Stakeholders />}
        {props.requestStage === "requestType" && <RequestType />}

        {props.requestType === "parkingRequest" && <PLGR />}
        {props.requestType === "parkingRate" && <PLRC />}
        {props.requestType === "pspRequest" && <PSP />}
        {props.requestType === "epiRequest" && <EPI />}
        {props.requestType === "ticketingRequest" && <TSI />}
        {props.requestType === "uploadRequest" &&
          props.requestStage === "summary" && <Summary />}
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestStage: state.requestStage,
    progressBarStatus: state.progressBarStatus,
    requestType: state.requestType,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutTrue: () => dispatch(setPopOutTrue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
