import { IFunctionsAndContext } from "../../interfaces/IFunctionsAndContext";
import { setFUNCTIONSANDCONTEXT } from "../constants/functionsAndContext.types";

export const setFunctionsAndContext = (
  functionAndContext: IFunctionsAndContext
) => {
  return {
    type: setFUNCTIONSANDCONTEXT,
    payload: functionAndContext,
  };
};
