import {
  setPOPOUTBACKTOHOME,
  setPOPOUTREQUESTSUMMARY,
  setPOPOUTTICKETINGACCOUNTS,
} from "../constants/popOutWindowType.types";

export const setPopOutBackToHome = () => {
  return {
    type: setPOPOUTBACKTOHOME,
  };
};

export const setPopOutRequestSummary = () => {
  return {
    type: setPOPOUTREQUESTSUMMARY,
  };
};

export const setPopOutTicketingAccounts = () => {
  return {
    type: setPOPOUTTICKETINGACCOUNTS,
  };
};
