import { useEffect, useState } from "react";

import { MdClose, MdPersonAddAlt1 } from "react-icons/md";
import { connect } from "react-redux";
import { ICustomerContactPerson } from "../../../../interfaces/ICustomerContactPerson";
import { IStakeholders } from "../../../../interfaces/stakeholders";
import { setCustomerPersons } from "../../../../redux/actions/customerPersonsArray.actions";
import { setStakeholdersObject } from "../../../../redux/actions/stakeholders.actions";
import AddButton from "../../atoms/backToMenuButton/addButton/addButton";
import styles from "./customerContactPersonsTSI.module.scss";

const emptyCustomerPerson: ICustomerContactPerson = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  prefix: "",
  phone: "",
};

function CustomerContactPersonTSI(props: any) {
  const [isCustomerContactListEmpty, setisCustomerContactListEmpty] =
    useState(true);

  const [customerPerson, setcustomerPerson] =
    useState<ICustomerContactPerson>(emptyCustomerPerson);

  useEffect(() => {
    if (props.customerContactPersonArray.length > 0) {
      setisCustomerContactListEmpty(false);
    }
  }, []);

  return (
    <div className={styles.stakeholders__customerContact__container}>
      <div className={styles.stakeholders__customerContact__container__section}>
        <h4>Project related contacts</h4>

        <div className={styles.useCases__tableContainer}>
          <div className={styles.useCases__tableContainer__header}>
            <div
              className={styles.useCases__tableContainer__header__description}
            >
              <p>First Name</p>
            </div>
            <div>
              <p>Last Name</p>
            </div>
            <div>
              <p>Role</p>
            </div>
            <div>
              <p>e-mail</p>
            </div>
            <div>
              <p>phone</p>
            </div>
          </div>
          {isCustomerContactListEmpty ? (
            <div
              className={styles.stakeholders__add__customer__container}
              onClick={() =>
                setisCustomerContactListEmpty(!isCustomerContactListEmpty)
              }
            >
              <p>
                <MdPersonAddAlt1 />
                Add new
              </p>
            </div>
          ) : (
            <>
              {props.customerContactPersonArray.length > 0 ? (
                props.customerContactPersonArray.map(
                  (contactPerson: ICustomerContactPerson) => (
                    <div
                      className={styles.customerPersons__tableContainer__row}
                    >
                      <div className={styles.useCases__tableContainer__row}>
                        <div>
                          <p>{contactPerson.firstName}</p>
                        </div>

                        <div>
                          <p>{contactPerson.lastName}</p>
                        </div>
                        <div>
                          <p>{contactPerson.role}</p>
                        </div>
                        <div>
                          <p>{contactPerson.email}</p>
                        </div>
                        <div>
                          <p>
                            {contactPerson.prefix} {contactPerson.phone}
                          </p>
                        </div>
                      </div>
                      <div className={styles.table__buttonsContainer}>
                        <button
                          onClick={() =>
                            props.setCustomerPersons(
                              props.customerContactPersonArray.filter(function (
                                el: ICustomerContactPerson
                              ) {
                                return el !== contactPerson;
                              })
                            )
                          }
                        >
                          <MdClose />
                        </button>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className={styles.emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.stakeholders__customerContact__container__section}>
        {isCustomerContactListEmpty ? (
          <></>
        ) : (
          <>
            <h5>Add Customer Contact Person</h5>
            <div className={styles.stakeholders__add__contact__container}>
              <div className={styles.conditionInputContainer}>
                <div>
                  <h5>First Name</h5>
                  <input
                    placeholder="Empty"
                    value={customerPerson?.firstName}
                    onChange={(e: any) =>
                      setcustomerPerson({
                        firstName: e.target.value,
                        lastName: customerPerson?.lastName,
                        role: customerPerson?.role,
                        email: customerPerson?.email,
                        prefix: customerPerson?.prefix,
                        phone: customerPerson?.phone,
                      })
                    }
                  />
                </div>
                <div>
                  <h5>Last Name</h5>
                  <input
                    placeholder="Empty"
                    value={customerPerson?.lastName}
                    onChange={(e: any) =>
                      setcustomerPerson({
                        firstName: customerPerson?.firstName,
                        lastName: e.target.value,
                        role: customerPerson?.role,
                        email: customerPerson?.email,
                        prefix: customerPerson?.prefix,
                        phone: customerPerson?.phone,
                      })
                    }
                  />
                </div>
                <div>
                  <h5>Role</h5>
                  <input
                    placeholder="eg. PM, Sales, Technician... "
                    value={customerPerson?.role}
                    onChange={(e: any) =>
                      setcustomerPerson({
                        firstName: customerPerson?.firstName,
                        lastName: customerPerson?.lastName,
                        role: e.target.value,
                        email: customerPerson?.email,
                        prefix: customerPerson?.prefix,
                        phone: customerPerson?.phone,
                      })
                    }
                  />
                </div>
                <div>
                  <h5>e-mail</h5>
                  <input
                    placeholder="Empty"
                    value={customerPerson?.email}
                    onChange={(e: any) =>
                      setcustomerPerson({
                        firstName: customerPerson?.firstName,
                        lastName: customerPerson?.lastName,
                        role: customerPerson?.role,
                        email: e.target.value,
                        prefix: customerPerson?.prefix,
                        phone: customerPerson?.phone,
                      })
                    }
                  />
                </div>
                <div className={styles.phoneInput}>
                  <h5>phone</h5>
                  <div className={styles.phoneInput__Container}>
                    <input
                      className={styles.phoneInput__prefix}
                      placeholder="0"
                      value={customerPerson?.prefix}
                      onChange={(e: any) =>
                        setcustomerPerson({
                          firstName: customerPerson?.firstName,
                          lastName: customerPerson?.lastName,
                          role: customerPerson?.role,
                          email: customerPerson?.email,
                          prefix: e.target.value,
                          phone: customerPerson?.phone,
                        })
                      }
                    />
                    <input
                      placeholder="Empty value"
                      value={customerPerson?.phone}
                      onChange={(e: any) =>
                        setcustomerPerson({
                          firstName: customerPerson?.firstName,
                          lastName: customerPerson?.lastName,
                          role: customerPerson?.role,
                          email: customerPerson?.email,
                          prefix: customerPerson?.prefix,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <AddButton
                onClick={() => {
                  if (
                    customerPerson !== emptyCustomerPerson &&
                    customerPerson
                  ) {
                    props.setCustomerPersons([
                      ...props.customerContactPersonArray,
                      customerPerson,
                    ]);
                    setcustomerPerson(emptyCustomerPerson);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    page: state.page,
    customerContactPersonArray: state.customerContactPersonArray,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCustomerPersons: (customerPersonsArray: ICustomerContactPerson[]) =>
      dispatch(setCustomerPersons(customerPersonsArray)),
    setStakeholdersObject: (stakeholdersObject: IStakeholders) =>
      dispatch(setStakeholdersObject(stakeholdersObject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactPersonTSI);
