import * as React from "react";
import styles from "./userInterface.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setContext,
  setReports,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import Dropzone from "react-dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaCodepen } from "react-icons/fa";
import { setUserInterfaceObject } from "../../../../../../redux/actions/userInterfaceObject.actions";
import { IUserInterfaceObject } from "../../../../../../interfaces/IUserInterfaceObject";
import { useEffect, useRef, useState } from "react";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";

function UserInterface(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [userInterfaceObject, setUserInterfaceObject] =
    useState<IUserInterfaceObject>(props.userInterfaceObject);

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<Boolean>(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/${props.requestObject.creationDate}/ui-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          console.log(`File extension "${file.name.split(".").pop()}" `);
          setIsFileUploaded(true);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <FaCodepen className={styles.requestStageIcon} />
        <h5>User Interface</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>UI Description</h4>
            <p>
              Describe any User Interfaces (UIs) e.g. data-entry screens or
              web-pages. Content and behaviour of each UI should be detailed -
              Mockups are very helpful for this. Pay attention to things like
              internationalisation & multi-language, SD UI guidelines & customer
              branding and also any technological requirements e.g. for security
              (e.g. SSL & certificates) for web-solutions.
            </p>
            <textarea
              value={userInterfaceObject.uiDescription}
              onChange={(e) =>
                setUserInterfaceObject({ uiDescription: e.target.value })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>UI Files</h4>
            <p>
              Please upload all User Interface files related to requested
              solution. You are only allowed to send one file here so please use
              any kind of archiver for multiple files.
            </p>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>
                    Drag 'n' drop some files here, or click to select files.
                  </p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {isFileUploaded ? <li>{file?.name}</li> : <li>No files</li>}
            </ul>
            {isFileUploaded && <p>File has been successfully uploaded. </p>}
            {uploadingError && <p>{uploadingError}</p>}
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setReports();
          props.setUserInterfaceObject(userInterfaceObject);
        }}
        back={() => {
          props.setContext();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    userInterfaceObject: state.userInterfaceObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setReports: () => dispatch(setReports()),
    setContext: () => dispatch(setContext()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setUserInterfaceObject: (userInterfaceObject: IUserInterfaceObject) =>
      dispatch(setUserInterfaceObject(userInterfaceObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInterface);
