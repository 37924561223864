import * as React from "react";
import { connect } from "react-redux";
import { IUseCase } from "../../../../../../interfaces/IUseCase";
import { setUseCasesObject } from "../../../../../../redux/actions/useCases.actions";
import styles from "./summaryPLGR.module.scss";
import { BlobServiceClient } from "@azure/storage-blob";
import { useEffect, useState } from "react";
import Download from "../../../../atoms/downloadButton/download";

function SummaryPLGR(props: any) {
  const containerName = "requestfiles";
  const accountName = "requestpoint";
  const sasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T23:37:22Z&st=2023-04-18T14:37:22Z&spr=https&sig=kk7j1tFdRCxNp5MZAJhH2kn9JNajJpzhyrKve5DD%2B08%3D";

  const [files, setFiles] = useState<string[]>([]);

  const [directory, setDirectory] = useState(
    `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/`
  );

  useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net${sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        console.log(directory);
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(`filelist: ${fileList}`);
        if (fileList.length === 0) {
          setDirectory(
            `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}`
          );
          console.log("fileList.length === 0");
          const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net${sasToken}`
          );
          const containerClient =
            blobServiceClient.getContainerClient(containerName);
          const response = containerClient.listBlobsFlat({ prefix: directory });
          console.log(directory);
          const fileList = [];
          for await (const blob of response) {
            fileList.push(blob.name);
          }
          setFiles(fileList);
          console.log(`filelist: ${fileList}`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [
    accountName,
    sasToken,
    props.containerName,
    directory,
    props.currentRequestObject,
  ]);

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Use Cases</h3>
          <div className={styles.useCasesSummary__tableContainer}>
            <div className={styles.useCasesSummary__tableHeader}>
              <div>
                <p>Description</p>
              </div>
              <div>
                <p>Pre-conditions</p>
              </div>
              <div>
                <p>Post-conditions</p>
              </div>
            </div>
            {props.useCasesObject.length > 0 ? (
              props.useCasesObject.map((useCase: IUseCase) => (
                <div className={styles.useCasesSummary__tableRow}>
                  <div>
                    <p>{useCase.description}</p>
                  </div>
                  <div>
                    <p>{useCase.preConditions}</p>
                  </div>
                  <div>
                    <p>{useCase.postConditions}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.emptyTable}>
                <p>Empty</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Functions and Context</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Functions and features</h4>
            <p>
              {props.functionsAndContext.functionsFeatures.length > 0 ? (
                props.functionsAndContext.functionsFeatures
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Solution context</h4>
            <p>
              {props.functionsAndContext.solutionContext.length > 0 ? (
                props.functionsAndContext.solutionContext
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Supported environment</h4>
            <p>
              {props.functionsAndContext.supportedPlenv.length > 0 ? (
                props.functionsAndContext.supportedPlenv
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>User Interface</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>UI description</h4>
            <p>
              {props.userInterfaceObject.uiDescription.length > 0 ? (
                props.userInterfaceObject.uiDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>UI Files:</h4>
            {files.filter((str) => str.includes("ui")).length > 0 ? (
              files
                .filter((str) => str.includes("ui"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Reports</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Reports description</h4>
            <p>
              {props.reportsObject.reportsDescription.length > 0 ? (
                props.reportsObject.reportsDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Reports Files:</h4>
            {files.filter((str) => str.includes("rep")).length > 0 ? (
              files
                .filter((str) => str.includes("rep"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Acceptance Criteria</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Acceptance criteria description</h4>
            <p>
              {props.acceptanceObject.acceptanceCriteriaDescription.length >
              0 ? (
                props.acceptanceObject.acceptanceCriteriaDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Test protocol documents:</h4>
            {files.filter((str) => str.includes("tespro")).length > 0 ? (
              files
                .filter((str) => str.includes("tespro"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Other</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Out of scope</h4>
            <p>
              {props.otherPLGRObject.outOfScope.length > 0 ? (
                props.otherPLGRObject.outOfScope
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Revelant decisions</h4>
            <p>
              {props.otherPLGRObject.revenatDecisionsn.length > 0 ? (
                props.otherPLGRObject.revenatDecisionsn
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Document references: </h4>
            {files.filter((str) => str.includes("docref")).length > 0 ? (
              files
                .filter((str) => str.includes("docref"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
            <h4>Non-functional requirements</h4>
            <p>
              {props.otherPLGRObject.nonFunctionalRequirements.length > 0 ? (
                props.otherPLGRObject.nonFunctionalRequirements
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Assumptions made</h4>
            <p>
              {props.otherPLGRObject.assumptionsMade.length > 0 ? (
                props.otherPLGRObject.assumptionsMade
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    useCasesObject: state.useCasesObject,
    functionsAndContext: state.functionsAndContext,
    userInterfaceObject: state.userInterfaceObject,
    reportsObject: state.reportsObject,
    acceptanceObject: state.acceptanceObject,
    otherPLGRObject: state.otherPLGRObject,
    requestObject: state.requestObject,
    fileNames: state.fileNames,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUseCasesObject: (useCasesobject: IUseCase[]) =>
      dispatch(setUseCasesObject(useCasesobject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPLGR);
