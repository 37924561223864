import * as React from "react";
import styles from "./thirdTSI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFourth,
  setRequestType,
  setSecond,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { HiOutlineTicket } from "react-icons/hi";
import Dropzone from "react-dropzone";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import {
  MdClose,
  MdOutlineAdd,
  MdOutlineAddCircleOutline,
} from "react-icons/md";
import { IdTsiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setTsiContentObject } from "../../../../../../redux/actions/tsiContentObject.actions";

interface IBarcodeObjectArray {
  barcodeType?: string;
  ticketType?: string;
  paperDimensions?: string;
  barcodeDimensions?: string;
}

interface IRfidObjectArray {
  rfidType: string;
  ticketType: string;
  serialNoOnly: boolean;
  blockSector: string;
  applicationId: string;
  madReadKey: string;
  layerReadKey: string;
}

function ThirdTSI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //barcode
  const [barcodeObjectArray, setBarcodeObjectArray] = useState<
    IBarcodeObjectArray[]
  >(props.tsiContentObject.barcodeDataCarriers);

  const [barcodeType, setBarcodeType] = useState("");
  const [barcodeTicketType, setBarcodeTicketType] = useState("");
  const [barcodePaperDimensions, setBarcodePaperDimensions] = useState("");
  const [barcodeDimensions, setBarcodeDimensions] = useState("");
  const [barcodeTypeValidationMessage, setBarcodeTypeValidationMessage] =
    useState(false);
  const [
    barcodeTicketTypeValidationMessage,
    setBarcodeTicketTypeValidationMessage,
  ] = useState(false);
  const [barcodeFirstValidationDone, setBarcodeFirstValidationDone] =
    useState(false);

  //rfid
  const [rfidObjectArray, setRfidObjectArray] = useState<IRfidObjectArray[]>(
    props.tsiContentObject.rfidtechnology
  );
  const [rfidTicketType, setRfidTicketType] = useState("");
  const [rfidType, setRfidType] = useState("");
  const [rfidSerialNoOnly, setRfidSerialNoOnly] = useState(false);
  const [rfidBlockSector, setRfidBlockSector] = useState("");
  const [rfidApplicationId, setRfidApplicationId] = useState("");
  const [rfidMadReadKey, setRfifMadReadKey] = useState("");
  const [rfidLayerReadKey, setRfidLayerReadKey] = useState("");
  const [rfidValidationMessage, setRfidValidationMessage] = useState(false);
  const [rfidTicketTypeValidationMessage, setRfidTicketTypeValidationMessage] =
    useState(false);
  const [rfidFirstValidationDone, setRfidFirstValidationDone] = useState(false);

  let requestTypeName = RequestTypeName(props.requestType);

  const [fileTypeToUpload, setFileTypeToUpload] = useState<string>("");

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const [file, setFile] = React.useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = React.useState<Boolean>(false);
  const [uploadingError, setUploadingError] = React.useState<string | null>(
    null
  );

  const [productCatalogeFileNames, setProductCatalogeFileNames] = useState<
    string[]
  >([]);
  const [sampleTicketsFileNames, setSampleTicketsFileNames] = useState<
    string[]
  >([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    setIsFileUploaded(false);
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/${props.requestObject.creationDate}/${fileTypeToUpload}-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          setIsFileUploaded(true);

          props.addFileName(file.name);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleUpload();
    if (isFileUploaded && file !== null) {
      {
        fileTypeToUpload === "tsiPC" &&
          setProductCatalogeFileNames([...productCatalogeFileNames, file.name]);
      }
      {
        fileTypeToUpload === "tsiST" &&
          setSampleTicketsFileNames([...sampleTicketsFileNames, file.name]);
      }
    }
  }, [fileTypeToUpload]);

  const [files, setFiles] = React.useState<string[]>([]);

  var directory = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/`;

  useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net${SasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(fileList);
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [isFileUploaded]);

  //Validation
  useEffect(() => {
    if (barcodeType === "") {
      setBarcodeTypeValidationMessage(true);
    } else {
      setBarcodeTypeValidationMessage(false);
    }
  }, [barcodeType]);

  useEffect(() => {
    if (barcodeTicketType === "") {
      setBarcodeTicketTypeValidationMessage(true);
    } else {
      setBarcodeTicketTypeValidationMessage(false);
    }
  }, [barcodeTicketType]);

  useEffect(() => {
    if (rfidType === "") {
      setRfidValidationMessage(true);
    } else {
      setRfidValidationMessage(false);
    }
  }, [rfidType]);

  useEffect(() => {
    if (rfidTicketType === "") {
      setRfidTicketTypeValidationMessage(true);
    } else {
      setRfidTicketTypeValidationMessage(false);
    }
  }, [rfidTicketType]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <HiOutlineTicket className={styles.requestStageIcon} />
        <h5>Ticket and Data Carriers:</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Product Catalogue</h4>
            <p>
              Please provide a product catalogue of which tickets are sold by
              the ticketing partner:
            </p>
            <Dropzone
              onDrop={async (acceptedFiles) => {
                setFileTypeToUpload("tsiPC");
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {files.filter((str) => str.includes("tsiPC")).length > 0 ? (
                files
                  .filter((str) => str.includes("tsiPC"))
                  .map((fileName: string) => <li>{fileName}</li>)
              ) : (
                <li>No files</li>
              )}
            </ul>
            {isFileUploaded && fileTypeToUpload === "tsiPC" && (
              <p>File has been successfully uploaded. </p>
            )}
            {uploadingError && fileTypeToUpload === "tsiPC" && (
              <p>{uploadingError}</p>
            )}
          </div>
          <div className={styles.inputColumn}>
            <h4>Sample Tickets</h4>
            <p>
              Please provide sample tickets which can be used for tests on
              SKIDATA side:
            </p>
            <Dropzone
              onDrop={(acceptedFiles) => {
                setFileTypeToUpload("tsiST");
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);

                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {files.filter((str) => str.includes("tsiST")).length > 0 ? (
                files
                  .filter((str) => str.includes("tsiST"))
                  .map((fileName: string) => <li>{fileName}</li>)
              ) : (
                <li>No files</li>
              )}
            </ul>
            {isFileUploaded && fileTypeToUpload === "tsiST" && (
              <p>File has been successfully uploaded. </p>
            )}
            {uploadingError && fileTypeToUpload === "tsiST" && (
              <p>{uploadingError}</p>
            )}
          </div>
          <div className={styles.inputColumn}>
            <h4>Barcode Data Carriers</h4>
            <p>
              Please provide the information regarding Barcode Data Carriers
            </p>
            <div className={styles.tsi__barcode__tableContainer}>
              <div className={styles.tsi__barcode__tableContainer__header}>
                <div
                  className={
                    styles.tsi__barcode__tableContainer__header__description
                  }
                >
                  <p>Barcode Type</p>
                </div>
                <div>
                  <p>Ticket Type</p>
                </div>
                <div>
                  <p>Paper Dimensions</p>
                </div>
                <div>
                  <p>Barcode Dimensions</p>
                </div>
              </div>
              {barcodeObjectArray.length > 0 ? (
                barcodeObjectArray.map((barcodeObject: any) => (
                  <div
                    className={
                      styles.tsi__barcode__tableContainer__rowWithButtons
                    }
                  >
                    <div className={styles.tsi__barcode__tableContainer__row}>
                      <div>
                        <p>{barcodeObject.barcodeType}</p>
                      </div>
                      <div>
                        <p>{barcodeObject.ticketType}</p>
                      </div>
                      <div>
                        <p>{barcodeObject.paperDimensions}</p>
                      </div>
                      <div
                        className={
                          styles.tsi__barcode__tableContainer__row__valueWithButtonsContainer
                        }
                      >
                        <p>{barcodeObject.barcodeDimensions}</p>
                        <div
                          className={
                            styles.tsi__barcode__table__buttonsContainer
                          }
                        >
                          <button
                            onClick={() =>
                              setBarcodeObjectArray(
                                barcodeObjectArray.filter(function (el: any) {
                                  return el !== barcodeObject;
                                })
                              )
                            }
                          >
                            <MdClose />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.tsi__barcode__emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </div>
            <div
              className={styles.tsi__ticketAndDataCarriers__barcode__container}
            >
              <div
                className={
                  styles.tsi__ticketAndDataCarriers__barcode__inputContainer
                }
              >
                <div
                  className={styles.tsi__ticketAndDataCarriers__barcode__column}
                >
                  <h5>Barcode Type</h5>
                  <div
                    className={
                      styles.tsi__ticketAndDataCarriers__barcode__barcodeType__container
                    }
                  >
                    <div
                      className={
                        styles.tsi__ticketAndDataCarriers__barcode__barcodeType__column
                      }
                    >
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Standard 2 of 5")}
                          checked={barcodeType === "Standard 2 of 5"}
                        />
                        <p>Standard 2 of 5</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Interleaved 2 of 5")}
                          checked={barcodeType === "Interleaved 2 of 5"}
                        />
                        <p>Interleaved 2 of 5</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("UPC A + E")}
                          checked={barcodeType === "UPC A + E"}
                        />
                        <p>UPC A + E</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("EAN 8, EAN 13")}
                          checked={barcodeType === "EAN 8, EAN 13"}
                        />
                        <p>EAN 8, EAN 13</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Code 39")}
                          checked={barcodeType === "Code 39"}
                        />
                        <p>Code 39</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Code 128")}
                          checked={barcodeType === "Code 128"}
                        />
                        <p>Code 128</p>
                      </div>
                    </div>
                    <div
                      className={
                        styles.tsi__ticketAndDataCarriers__barcode__barcodeType__column
                      }
                    >
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Datamatrix 2D")}
                          checked={barcodeType === "Datamatrix 2D"}
                        />
                        <p>Datamatrix 2D</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("PDF 417")}
                          checked={barcodeType === "PDF 417"}
                        />
                        <p>PDF 417</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("QR Code")}
                          checked={barcodeType === "QR Code"}
                        />
                        <p>QR Code</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Aztec")}
                          checked={barcodeType === "Aztec"}
                        />
                        <p>Aztec</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Codabar")}
                          checked={barcodeType === "Codabar"}
                        />
                        <p>Codabar</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setBarcodeType("Others")}
                          checked={barcodeType === "Others"}
                        />
                        <p>Others</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles.tsi__ticketAndDataCarriers__barcode__column__right
                  }
                >
                  <div>
                    <h5>Ticket Type</h5>
                    <input
                      className={
                        barcodeTicketTypeValidationMessage &&
                        barcodeFirstValidationDone &&
                        styles.input__validationNotSuccesfull
                      }
                      value={barcodeTicketType}
                      onChange={(e: any) =>
                        setBarcodeTicketType(e.target.value)
                      }
                    />
                    {barcodeTicketTypeValidationMessage &&
                      barcodeFirstValidationDone && (
                        <div className={styles.input__validationBox}>
                          <p>Please define a Ticket Type</p>
                        </div>
                      )}
                  </div>
                  <div>
                    <h5>Paper Dimensions</h5>
                    <input
                      value={barcodePaperDimensions}
                      onChange={(e: any) =>
                        setBarcodePaperDimensions(e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <h5>Barcode Dimensions</h5>
                    <input
                      value={barcodeDimensions}
                      onChange={(e: any) =>
                        setBarcodeDimensions(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.array__object__validationMessage__Barcode}>
                {barcodeTypeValidationMessage && barcodeFirstValidationDone && (
                  <p>
                    Please select the appropriate Barcode Type from the
                    available options.
                  </p>
                )}
              </div>
              <button
                className={styles.tsi__ticketAndDataCarriers__AddButton}
                onClick={() => {
                  setBarcodeFirstValidationDone(true);
                  if (
                    !barcodeTypeValidationMessage &&
                    !barcodeTicketTypeValidationMessage
                  ) {
                    setBarcodeFirstValidationDone(false);
                    setBarcodeObjectArray([
                      ...barcodeObjectArray,
                      {
                        barcodeType: barcodeType,
                        ticketType: barcodeTicketType,
                        paperDimensions: barcodePaperDimensions,
                        barcodeDimensions: barcodeDimensions,
                      },
                    ]);
                    setBarcodeType("");
                    setBarcodeTicketType("");
                    setBarcodePaperDimensions("");
                    setBarcodeDimensions("");
                  }
                }}
              >
                <MdOutlineAddCircleOutline
                  className={styles.tsi__ticketAndDataCarriers__AddButton__icon}
                />
                Add
              </button>
            </div>
          </div>
          <div className={styles.inputColumn}>
            <h4>RFID Technology</h4>
            <p>Please provide the details for RFID tehcnology.</p>
            <div className={styles.tsi__barcode__tableContainer}>
              <div className={styles.tsi__barcode__tableContainer__header}>
                <div
                  className={
                    styles.tsi__barcode__tableContainer__header__description
                  }
                >
                  <p>RFID Type</p>
                </div>
                <div>
                  <p>Serial No Only</p>
                </div>
                <div>
                  <p>Ticket Type</p>
                </div>
                <div>
                  <p>Block Sector</p>
                </div>
                <div>
                  <p>ApplicationId</p>
                </div>
                <div>
                  <p>MAD Read Key</p>
                </div>
                <div>
                  <p>Layer Read Key</p>
                </div>
              </div>
              {rfidObjectArray.length > 0 ? (
                rfidObjectArray.map((rfidObject: any) => (
                  <div
                    className={
                      styles.tsi__barcode__tableContainer__rowWithButtons
                    }
                  >
                    <div className={styles.tsi__barcode__tableContainer__row}>
                      <div>
                        <p>{rfidObject.rfidType}</p>
                      </div>
                      <div>
                        <p>{rfidObject.serialNoOnly ? "Yes" : "No"}</p>
                      </div>
                      <div>
                        <p>{rfidObject.ticketType}</p>
                      </div>
                      <div>
                        <p>{rfidObject.blockSector}</p>
                      </div>
                      <div>
                        <p>{rfidObject.applicationId}</p>
                      </div>
                      <div>
                        <p>{rfidObject.madReadKey}</p>
                      </div>

                      <div
                        className={
                          styles.tsi__barcode__tableContainer__row__valueWithButtonsContainer
                        }
                      >
                        <p>{rfidObject.layerReadKey}</p>
                        <div
                          className={
                            styles.tsi__barcode__table__buttonsContainer
                          }
                        >
                          <button
                            onClick={() =>
                              setRfidObjectArray(
                                rfidObjectArray.filter(function (el: any) {
                                  return el !== rfidObject;
                                })
                              )
                            }
                          >
                            <MdClose />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.tsi__barcode__emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </div>
            <div className={styles.tsi__ticketAndDataCarriers__rfid__container}>
              <div
                className={
                  styles.tsi__ticketAndDataCarriers__rfid__inputContainer
                }
              >
                <div
                  className={styles.tsi__ticketAndDataCarriers__rfid__column}
                >
                  <div
                    className={
                      styles.tsi__ticketAndDataCarriers__rfid__column__header
                    }
                  >
                    <h5>RFID Type</h5>
                    <h5>Serial No only</h5>
                  </div>
                  <div
                    className={
                      styles.tsi__ticketAndDataCarriers__rfid__rfidType__container
                    }
                  >
                    <div
                      className={
                        styles.tsi__ticketAndDataCarriers__rfid__rfidType__column
                      }
                    >
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("Keycard")}
                          checked={rfidType === "Keycard"}
                        />
                        <p>Keycard</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("NFC")}
                          checked={rfidType === "NFC"}
                        />
                        <p>NFC</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("Mifare")}
                          checked={rfidType === "Mifare"}
                        />
                        <p>Mifare</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("Infineon MyD")}
                          checked={rfidType === "Infineon MyD"}
                        />
                        <p>Infineon MyD</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("Felica")}
                          checked={rfidType === "Felica"}
                        />
                        <p>Felica</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("HD iClass")}
                          checked={rfidType === "HD iClass"}
                        />
                        <p>HD iClass</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => setRfidType("NXP iCode")}
                          checked={rfidType === "NXP iCode"}
                        />
                        <p>NXP iCode</p>
                      </div>
                    </div>
                    <div
                      className={
                        styles.tsi__ticketAndDataCarriers__rfid__rfidType__column
                      }
                    >
                      <div>
                        <input
                          type="checkbox"
                          onChange={() =>
                            setRfidSerialNoOnly(!rfidSerialNoOnly)
                          }
                          checked={rfidSerialNoOnly}
                        />
                        <p>Serial No Only</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles.tsi__ticketAndDataCarriers__rfid__column__right
                  }
                >
                  <div>
                    <h5>Ticket Type</h5>
                    <input
                      className={
                        rfidTicketTypeValidationMessage &&
                        rfidFirstValidationDone &&
                        styles.input__validationNotSuccesfull
                      }
                      value={rfidTicketType}
                      onChange={(e: any) => setRfidTicketType(e.target.value)}
                    />
                    {rfidTicketTypeValidationMessage &&
                      rfidFirstValidationDone && (
                        <div className={styles.input__validationBox}>
                          <p>Please define a Ticket Type</p>
                        </div>
                      )}
                  </div>

                  <div>
                    <h5>Block Sector</h5>
                    <input
                      value={rfidBlockSector}
                      onChange={(e: any) => setRfidBlockSector(e.target.value)}
                    />
                  </div>
                  <div>
                    <h5>Application ID</h5>
                    <input
                      value={rfidApplicationId}
                      onChange={(e: any) =>
                        setRfidApplicationId(e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <h5>MAD Read Key</h5>
                    <input
                      value={rfidMadReadKey}
                      onChange={(e: any) => setRfifMadReadKey(e.target.value)}
                    />
                  </div>
                  <div>
                    <h5>Layer Read Key</h5>
                    <input
                      value={rfidLayerReadKey}
                      onChange={(e: any) => setRfidLayerReadKey(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.array__object__validationMessage}>
                {rfidValidationMessage && rfidFirstValidationDone && (
                  <p>
                    "Please select the appropriate RFID type from the available
                    options.
                  </p>
                )}
              </div>
              <button
                className={styles.tsi__ticketAndDataCarriers__AddButton}
                onClick={() => {
                  setRfidFirstValidationDone(true);
                  if (
                    !rfidValidationMessage &&
                    !rfidTicketTypeValidationMessage
                  ) {
                    setRfidFirstValidationDone(false);
                    setRfidValidationMessage(false);
                    setRfidObjectArray([
                      ...rfidObjectArray,
                      {
                        rfidType: rfidType,
                        ticketType: rfidTicketType,
                        serialNoOnly: rfidSerialNoOnly,
                        blockSector: rfidBlockSector,
                        applicationId: rfidApplicationId,
                        madReadKey: rfidMadReadKey,
                        layerReadKey: rfidLayerReadKey,
                      },
                    ]);
                    setRfidTicketType("");
                    setRfidType("");
                    setRfidSerialNoOnly(false);
                    setRfidBlockSector("");
                    setRfidApplicationId("");
                    setRfifMadReadKey("");
                    setRfidLayerReadKey("");
                  }
                }}
              >
                <MdOutlineAddCircleOutline
                  className={styles.tsi__ticketAndDataCarriers__AddButton__icon}
                />
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          setBarcodeFirstValidationDone(false);
          setBarcodeTypeValidationMessage(false);
          setBarcodeTicketTypeValidationMessage(false);
          setRfidValidationMessage(false);
          props.setTsiContentObject({
            ...props.tsiContentObject,
            barcodeDataCarriers: barcodeObjectArray,
            rfidtechnology: rfidObjectArray,
          });

          props.Increment();
          props.setFourth();
          setRfidValidationMessage(false);
        }}
        back={() => {
          setBarcodeFirstValidationDone(false);
          setBarcodeTypeValidationMessage(false);
          setBarcodeTicketTypeValidationMessage(false);
          setRfidValidationMessage(false);
          props.setRequestType();
          props.setSecond();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    tsiContentObject: state.tsiContentObject,
    stakeholders: state.stakeholders,
    requestObject: state.requestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setTsiContentObject: (tsiContentObject: IdTsiNavigation) =>
      dispatch(setTsiContentObject(tsiContentObject)),
    setSecond: () => dispatch(setSecond()),
    setFourth: () => dispatch(setFourth()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdTSI);
