import { IRequest } from "../../interfaces/IRequest";
import {
  setCURRENTREQUESTOBJECT,
  resetCURRENTREQUESTOBJECT,
} from "../constants/currentRequestObject.types";

const INITIAL_STATE: IRequest | null = null;

const CurrentRequestObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IRequest }
) {
  switch (action.type) {
    case setCURRENTREQUESTOBJECT:
      return action.payload;
    case resetCURRENTREQUESTOBJECT:
      return state;
    default:
      return state;
  }
};

export default CurrentRequestObjectReducer;
