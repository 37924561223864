import * as React from "react";
import styles from "./firstEPI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setRequestType,
  setSecond,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney, MdClose } from "react-icons/md";
import { useEffect, useState } from "react";
import { setPlrcContentObject } from "../../../../../../redux/actions/plrcContentObject.actions";
import {
  IdEpiNavigation,
  IdPlrcNavigation,
} from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { FaCar, FaCogs, FaMountain } from "react-icons/fa";
import { IUseCase } from "../../../../../../interfaces/IUseCase";
import AddButton from "../../../../atoms/backToMenuButton/addButton/addButton";
import { BsCalendar4Event } from "react-icons/bs";
import { setEpiContentObject } from "../../../../../../redux/actions/epiContentObject.actions";

const emptyUseCase: IUseCase = {
  description: "",
  preConditions: "",
  postConditions: "",
};

function FirstEPI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [carManualCash, setCarManualCash] = useState<boolean>(
    props.epiContentObject.idStandardUseCasesNavigation.manualCash
  );
  const [carAutomaticPaymentMachine, setCarAutomaticPaymentMachine] =
    useState<boolean>(
      props.epiContentObject.idStandardUseCasesNavigation
        .automaticPaymentMachine
    );
  const [carExitColumn, setCarExitColumn] = useState<boolean>(
    props.epiContentObject.idStandardUseCasesNavigation.exitColumn
  );
  const [mountainTouchCash, setMountainTouchCash] = useState<boolean>(
    props.epiContentObject.idStandardUseCasesNavigation.touchCash
  );
  const [mountainSkiosk, setMountainSkiosk] = useState<boolean>(
    props.epiContentObject.idStandardUseCasesNavigation.mountainSkiosk
  );
  const [eventSkiosk, setEventSkiosk] = useState<boolean>(
    props.epiContentObject.idStandardUseCasesNavigation.eventSkiosk
  );

  const [useCase, setUseCase] = useState<IUseCase>(emptyUseCase);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <FaCogs className={styles.requestStageIcon} />
        <h5>Use Case(s)</h5>
        <p>Choose the use cases for requested solution.</p>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.checkboxes__container}>
          <div className={styles.checkboxes__cloumn__container}>
            <FaCar className={styles.checboxes__Icon} />
            <h5>Car</h5>
            <div className={styles.checkbox__container}>
              <input
                type="checkbox"
                checked={carManualCash}
                onChange={() => setCarManualCash(!carManualCash)}
              />
              <p>Manual Cash</p>
            </div>
            <div className={styles.checkbox__container}>
              <input
                type="checkbox"
                checked={carAutomaticPaymentMachine}
                onChange={() =>
                  setCarAutomaticPaymentMachine(!carAutomaticPaymentMachine)
                }
              />
              <p>Automatic Payment Machine</p>
            </div>
            <div className={styles.checkbox__container}>
              <input
                type="checkbox"
                checked={carExitColumn}
                onChange={() => setCarExitColumn(!carExitColumn)}
              />
              <p>Exit Column</p>
            </div>
          </div>
          <div className={styles.checkboxes__cloumn__container}>
            <FaMountain className={styles.checboxes__Icon} />
            <h5>Mountain</h5>
            <div className={styles.checkbox__container}>
              <input
                type="checkbox"
                checked={mountainTouchCash}
                onChange={() => setMountainTouchCash(!mountainTouchCash)}
              />
              <p>Touch Cash</p>
            </div>
            <div className={styles.checkbox__container}>
              <input
                type="checkbox"
                checked={mountainSkiosk}
                onChange={() => setMountainSkiosk(!mountainSkiosk)}
              />
              <p>Skiosk</p>
            </div>
          </div>
          <div className={styles.checkboxes__cloumn__container}>
            <BsCalendar4Event className={styles.checboxes__Icon} />
            <h5>Event</h5>
            <div className={styles.checkbox__container}>
              <input
                type="checkbox"
                checked={eventSkiosk}
                onChange={() => setEventSkiosk(!eventSkiosk)}
              />
              <p>Skiosk</p>
            </div>
          </div>
        </div>

        <div className={styles.nonStandardUseCases}>
          <h5>Non-Standard Use Case(s)</h5>
          <p>
            <b>If this is a non-standard </b> EPI terminal integration, please
            describe the use-case e.g. for a virtual payment system or virtual
            terminal to implement reverse QR-code payment via smartphone app.
            (Standard is a payment card terminal integration)
          </p>
        </div>
        <div className={styles.useCases__tableContainer}>
          <div className={styles.useCases__tableContainer__header}>
            <div
              className={styles.useCases__tableContainer__header__description}
            >
              <p>Description</p>
            </div>
            <div>
              <p>Pre-conditions</p>
            </div>
            <div>
              <p>Post-Condition</p>
            </div>
          </div>
          {props.epiContentObject.epiUseCases.length > 0 ? (
            props.epiContentObject.epiUseCases.map((useCase: IUseCase) => (
              <div className={styles.useCases__tableContainer__rowWithButtons}>
                <div className={styles.useCases__tableContainer__row}>
                  <div>
                    <p>{useCase.description}</p>
                  </div>
                  <div>
                    <p>{useCase.preConditions}</p>
                  </div>
                  <div>
                    <p>{useCase.postConditions}</p>
                  </div>
                </div>
                <div className={styles.table__buttonsContainer}>
                  <button
                    onClick={() =>
                      props.setUseCasesObject(
                        props.setEpiContentObject({
                          ...props.epiContentObject,
                          epiUseCases: [
                            ...props.epiContentObject.epiUseCases.filter(
                              function (el: IUseCase) {
                                return el !== useCase;
                              }
                            ),
                          ],
                        })
                      )
                    }
                  >
                    <MdClose />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.emptyContainer}>
              <p>Empty</p>
            </div>
          )}
        </div>
        <div className={styles.inputContainer}>
          <h4>Add Use Case</h4>
          <div className={styles.descriptionInputContainer}>
            <h5>Description</h5>
            <textarea
              placeholder="For example: We can authorize on page http://example.com/login"
              value={useCase?.description}
              onChange={(e: any) =>
                setUseCase({
                  description: e.target.value,
                  preConditions: useCase?.preConditions,
                  postConditions: useCase?.postConditions,
                })
              }
            />
          </div>
          <div className={styles.conditionInputContainer}>
            <div>
              <h5>Pre-conditions</h5>
              <textarea
                placeholder="Empty value"
                value={useCase?.preConditions}
                onChange={(e: any) =>
                  setUseCase({
                    description: useCase?.description,
                    preConditions: e.target.value,
                    postConditions: useCase?.postConditions,
                  })
                }
              />
            </div>
            <div>
              <h5>Post-conditions</h5>
              <textarea
                placeholder="Empty value"
                value={useCase?.postConditions}
                onChange={(e: any) =>
                  setUseCase({
                    description: useCase?.description,
                    preConditions: useCase?.preConditions,
                    postConditions: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.addButtonContainer}>
            <AddButton
              onClick={() => {
                if (useCase !== emptyUseCase) {
                  props.setEpiContentObject({
                    ...props.epiContentObject,
                    epiUseCases: [
                      ...props.epiContentObject.epiUseCases,
                      useCase,
                    ],
                  });
                  setUseCase(emptyUseCase);
                }
              }}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.setSecond();
          props.Increment();
          props.setEpiContentObject({
            ...props.epiContentObject,
            idStandardUseCasesNavigation: {
              manualCash: carManualCash,
              automaticPaymentMachine: carAutomaticPaymentMachine,
              exitColumn: carExitColumn,
              touchCash: mountainTouchCash,
              mountainSkiosk: mountainSkiosk,
              eventSkiosk: eventSkiosk,
            },
          });
        }}
        back={() => {
          props.setRequestType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    epiContentObject: state.epiContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setSecond: () => dispatch(setSecond()),

    setPlrcContentObject: (plrcObject: IdPlrcNavigation) =>
      dispatch(setPlrcContentObject(plrcObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setEpiContentObject: (epiContentObject: IdEpiNavigation) =>
      dispatch(setEpiContentObject(epiContentObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstEPI);
