import * as React from "react";
import styles from "./outOfScope.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setAcceptance,
  setSummary,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import Dropzone from "react-dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { IOtherPLGR } from "../../../../../../interfaces/IOtherPLGR";
import { setOtherPLGR } from "../../../../../../redux/actions/otherPLGRObject.actions";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";

function OutOfScope(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [otherObject, setOtherObject] = useState<IOtherPLGR>(
    props.otherPLGRObject
  );

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<Boolean>(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/${props.requestObject.creationDate}/docref-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          setIsFileUploaded(true);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  React.useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <AiOutlineQuestionCircle className={styles.requestStageIcon} />
        <h5>Other</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Out of scope</h4>
            <p>
              List all features and functionality that has been previously
              discussed or even just mentioned but should not be part of this
              implementation. This is very important to set the level of
              expectation. Also list any functionality you think could be
              “assumed” by the customer but were not explicitly requested. E.g.
              reports, configuration GUIs, apps, backup mechanisms,
              authentication or security mechanisms or integration with existing
              SAP, BI or other systems etc.
            </p>
            <textarea
              value={otherObject.outOfScope}
              onChange={(e) =>
                setOtherObject({
                  outOfScope: e.target.value,
                  revenatDecisionsn: otherObject.revenatDecisionsn,
                  nonFunctionalRequirements:
                    otherObject.nonFunctionalRequirements,
                  assumptionsMade: otherObject.assumptionsMade,
                })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Revelat decisions</h4>
            <p>
              Note any decisions that have been made that may aid understanding
              of the document e.g. ticket-media or LPRS etc. Optionally, include
              date and person that made each decision.
            </p>
            <textarea
              value={otherObject.revenatDecisionsn}
              onChange={(e) =>
                setOtherObject({
                  outOfScope: otherObject.outOfScope,
                  revenatDecisionsn: e.target.value,
                  nonFunctionalRequirements:
                    otherObject.nonFunctionalRequirements,
                  assumptionsMade: otherObject.assumptionsMade,
                })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Document references</h4>
            <p>Please upload any relevant documents.</p>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {isFileUploaded ? <li>{file?.name}</li> : <li>No files</li>}
            </ul>
            {isFileUploaded && <p>File has been successfully uploaded. </p>}
            {uploadingError && <p>{uploadingError}</p>}
          </div>
          <div className={styles.inputColumn}>
            <h4>Non-functional requirements</h4>
            <p>
              Specify any general requirements on the solution like performance,
              reliability, compliance, security, usability, localization etc.
            </p>
            <textarea
              value={otherObject.nonFunctionalRequirements}
              onChange={(e) =>
                setOtherObject({
                  outOfScope: otherObject.outOfScope,
                  revenatDecisionsn: otherObject.revenatDecisionsn,
                  nonFunctionalRequirements: e.target.value,
                  assumptionsMade: otherObject.assumptionsMade,
                })
              }
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Assumptions made</h4>
            <p>
              If you have made any assumptions in this specification, please
              note them here. They must be confirmed with the customer.
            </p>
            <textarea
              value={otherObject.assumptionsMade}
              onChange={(e) =>
                setOtherObject({
                  outOfScope: otherObject.outOfScope,
                  revenatDecisionsn: otherObject.revenatDecisionsn,
                  nonFunctionalRequirements:
                    otherObject.nonFunctionalRequirements,
                  assumptionsMade: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setSummary();
          props.setOtherPLGRObject(otherObject);
        }}
        back={() => {
          props.setAcceptance();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    otherPLGRObject: state.otherPLGRObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setAcceptance: () => dispatch(setAcceptance()),
    setSummary: () => dispatch(setSummary()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setOtherPLGRObject: (otherObject: IOtherPLGR) =>
      dispatch(setOtherPLGR(otherObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutOfScope);
