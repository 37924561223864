import * as React from "react";
import styles from "./secondPLRC.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFirstAfterType,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney } from "react-icons/md";
import { useEffect, useState } from "react";
import { IdPlrcNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPlrcContentObject } from "../../../../../../redux/actions/plrcContentObject.actions";

function SecondPLRC(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [minimumMaximum, setMinimumMaximum] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation.minMaxStayDuration
  );
  const [validation, setValidation] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation.validation
  );
  const [paymentMethod, setPaymentMethod] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation.paymentMethod
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdAttachMoney className={styles.requestStageIcon} />
        <h5>Rules for special case (2/3):</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Minimum/maximum stay duration</h4>
            <p>Is the special rate dependant on stay duration - how?</p>
            <textarea
              value={minimumMaximum}
              onChange={(e) => setMinimumMaximum(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Validation</h4>
            <p>
              Is the special rate dependant on specific validation(s)? Is there
              a limit to number of these validations?
            </p>
            <textarea
              value={validation}
              onChange={(e) => setValidation(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Payment method</h4>
            <p>
              Is the special rate dependant on a specific method of payment e.g.
              only for Visa
            </p>
            <textarea
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setThird();
          props.setPlrcContentObject({
            idRulesForSpecialCaseNavigation: {
              ...props.plrcContentObject.idRulesForSpecialCaseNavigation,
              minMaxStayDuration: minimumMaximum,
              validation: validation,
              paymentMethod: paymentMethod,
            },
          });
        }}
        back={() => {
          props.setFirstAfterType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    plrcContentObject: state.plrcContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setThird: () => dispatch(setThird()),

    setPlrcContentObject: (plrcObject: IdPlrcNavigation) =>
      dispatch(setPlrcContentObject(plrcObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondPLRC);
