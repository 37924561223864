import * as React from "react";
import styles from "./backToMenuButton.module.scss";

import { MdOutlineArrowBackIosNew } from "react-icons/md";

function BackToMenuButton({ onClick }: any) {
  return (
    <>
      <button onClick={onClick} className={styles.backToMenuButton}>
        <MdOutlineArrowBackIosNew /> BACK TO MENU
      </button>
    </>
  );
}

export default BackToMenuButton;
