import { useEffect } from "react";
import styles from "./ActiveAccounts.module.scss";
import TicketingPartnersTable from "../../../molecules/ticketingPartnersTable/ticketingPartnersTable";
import { connect } from "react-redux";
import SearchBar from "../../../atoms/searchBar/searchBar";

function ActiveAccounts(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={styles.TSI__activeAccounts__mainContainer}>
      <h2>Ticketing Partners Active Accounts</h2>
      <SearchBar />
      <TicketingPartnersTable />
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(null, mapDispatchToProps)(ActiveAccounts);
