import { ICustomerContactPerson } from "../../interfaces/ICustomerContactPerson";
import { SetcustomerContactPersonAction } from "../../interfaces/setCustomersPersonsArrayAction";
import { setCUSTOMERPERSONS } from "../constants/customerPersonsArray.types";

const INITIAL_STATE: ICustomerContactPerson[] = [];

const CustomerPersonsArrayReducer = function (
  state = INITIAL_STATE,
  action: SetcustomerContactPersonAction
) {
  switch (action.type) {
    case setCUSTOMERPERSONS:
      return action.payload;
    default:
      return state;
  }
};

export default CustomerPersonsArrayReducer;
