import * as React from "react";
import { connect } from "react-redux";
import { IUseCase } from "../../../../../../interfaces/IUseCase";
import { setUseCasesObject } from "../../../../../../redux/actions/useCases.actions";
import styles from "./summaryTSI.module.scss";
import { BlobServiceClient } from "@azure/storage-blob";
import Download from "../../../../atoms/downloadButton/download";

function SummaryTSI(props: any) {
  interface FileListProps {
    url: string;
  }
  const containerName = "requestfiles";
  const accountName = "requestpoint";
  const sasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T23:37:22Z&st=2023-04-18T14:37:22Z&spr=https&sig=kk7j1tFdRCxNp5MZAJhH2kn9JNajJpzhyrKve5DD%2B08%3D";

  const [files, setFiles] = React.useState<string[]>([]);

  var directory = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/`;

  React.useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net${sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(fileList);
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [
    accountName,
    sasToken,
    props.containerName,
    directory,
    props.currentRequestObject,
  ]);
  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Partner and Customer</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Strategic Integration</h4>
            {props.tsiContentObject.idTsiprojectInformationNavigation
              .strategic ? (
              <p>Yes</p>
            ) : (
              <p>No</p>
            )}
            <h4>Order Number</h4>
            <p>
              {props.tsiContentObject.idTsiprojectInformationNavigation
                .orderNumber === "" ? (
                <>Empty</>
              ) : (
                <>
                  {
                    props.tsiContentObject.idTsiprojectInformationNavigation
                      .orderNumber
                  }
                </>
              )}
            </p>
            <h4>Ticketing Partner company name</h4>
            <p>
              {props.tsiContentObject.idTsiprojectInformationNavigation
                .partnerCompanyName === "" ? (
                <>Empty</>
              ) : (
                <>
                  {
                    props.tsiContentObject.idTsiprojectInformationNavigation
                      .partnerCompanyName
                  }
                </>
              )}
            </p>
            <h4>Partner Company URL</h4>
            <p>
              {props.tsiContentObject.idTsiprojectInformationNavigation
                .partnerUrl === "" ? (
                <>Empty</>
              ) : (
                <>
                  {
                    props.tsiContentObject.idTsiprojectInformationNavigation
                      .partnerUrl
                  }
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Timeline</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>TSI Integration Kick-Off</h4>
            <p>{props.tsiContentObject.idTimelineNavigation.kickOffDate}</p>
            <h4>Software installation</h4>
            <p>
              {props.tsiContentObject.idTimelineNavigation.softwareInstallation}
            </p>
            <h4>Hardware installation</h4>
            <p>
              {props.tsiContentObject.idTimelineNavigation.hardwareInstallation}
            </p>
            <h4>User acceptance test</h4>
            <p>
              {props.tsiContentObject.idTimelineNavigation.userAcceptanceTest}
            </p>
            <h4>1st live event</h4>
            <p>{props.tsiContentObject.idTimelineNavigation.firstLiveEvent}</p>
            <h4>Additional timeline description</h4>

            {props.tsiContentObject.idTimelineNavigation
              .additionalDescription === "" ? (
              <p>Empty</p>
            ) : (
              <p>
                {
                  props.tsiContentObject.idTimelineNavigation
                    .additionalDescription
                }
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Ticket and Data Carriers:</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Product Catalogue</h4>
            {files.filter((str) => str.includes("tsiPC")).length > 0 ? (
              files
                .filter((str) => str.includes("tsiPC"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}

            <h4>Sample Tickets</h4>

            {files.filter((str) => str.includes("tsiST")).length > 0 ? (
              files
                .filter((str) => str.includes("tsiST"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}

            <h4>Barcode Data Carriers</h4>
            <div className={styles.tsi__barcode__tableContainer}>
              <div className={styles.tsi__barcode__tableContainer__header}>
                <div
                  className={
                    styles.tsi__barcode__tableContainer__header__description
                  }
                >
                  <p>Barcode Type</p>
                </div>
                <div>
                  <p>Ticket Type</p>
                </div>
                <div>
                  <p>Paper Dimensions</p>
                </div>
                <div>
                  <p>Barcode Dimensions</p>
                </div>
              </div>
              {props.tsiContentObject.barcodeDataCarriers.length > 0 ? (
                props.tsiContentObject.barcodeDataCarriers.map(
                  (barcodeObject: any) => (
                    <div
                      className={
                        styles.tsi__barcode__tableContainer__rowWithButtons
                      }
                    >
                      <div className={styles.tsi__barcode__tableContainer__row}>
                        <div>
                          <p>{barcodeObject.barcodeType}</p>
                        </div>
                        <div>
                          <p>{barcodeObject.ticketType}</p>
                        </div>
                        <div>
                          <p>{barcodeObject.paperDimensions}</p>
                        </div>
                        <div>
                          <p>{barcodeObject.barcodeDimensions}</p>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className={styles.tsi__barcode__emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </div>
            <h4>RFID Technology</h4>
            <div className={styles.tsi__barcode__tableContainer}>
              <div className={styles.tsi__barcode__tableContainer__header}>
                <div
                  className={
                    styles.tsi__barcode__tableContainer__header__description
                  }
                >
                  <p>RFID Type</p>
                </div>
                <div>
                  <p>Serial No Only</p>
                </div>
                <div>
                  <p>Block Sector</p>
                </div>
                <div>
                  <p>ApplicationId</p>
                </div>
                <div>
                  <p>MAD Read Key</p>
                </div>
                <div>
                  <p>Layer Read Key</p>
                </div>
              </div>
              {props.tsiContentObject.rfidtechnology.length > 0 ? (
                props.tsiContentObject.rfidtechnology.map((rfidObject: any) => (
                  <div
                    className={
                      styles.tsi__barcode__tableContainer__rowWithButtons
                    }
                  >
                    <div className={styles.tsi__barcode__tableContainer__row}>
                      <div>
                        <p>{rfidObject.rfidType}</p>
                      </div>
                      <div>
                        <p>{rfidObject.serialNoOnly ? "Yes" : "No"}</p>
                      </div>
                      <div>
                        <p>{rfidObject.blockSector}</p>
                      </div>
                      <div>
                        <p>{rfidObject.applicationId}</p>
                      </div>
                      <div>
                        <p>{rfidObject.madReadKey}</p>
                      </div>
                      <div>
                        <p>{rfidObject.layerReadKey}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.tsi__barcode__emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Venue and Event Organisation</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Venue Business</h4>
            <div className={styles.tsi__venue__venuBussines__radioContainer}>
              <div>
                <input
                  type="radio"
                  checked={props.tsiContentObject.idVenueNavigation.fair}
                />
                <p>Fair</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={props.tsiContentObject.idVenueNavigation.leisure}
                />
                <p>Leisure</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={props.tsiContentObject.idVenueNavigation.attraction}
                />
                <p>Attraction</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={props.tsiContentObject.idVenueNavigation.stadion}
                />
                <p>Stadion</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={props.tsiContentObject.idVenueNavigation.arena}
                />
                <p>Arena</p>
              </div>
              {props.tsiContentObject.idVenueNavigation.venueOwnValue !==
                undefined &&
                props.tsiContentObject.idVenueNavigation.venueOwnValue.length >
                  0 && (
                  <div>
                    <input type="radio" />
                    <p>
                      {props.tsiContentObject.idVenueNavigation.venueOwnValue}
                    </p>
                  </div>
                )}
            </div>

            <h4>Type of events</h4>
            <div className={styles.tsi__venue__typeOfEvents__radioContainer}>
              <div>
                <input
                  type="radio"
                  checked={props.tsiContentObject.idVenueNavigation.eventBased}
                />
                <p>Event based</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.tsiContentObject.idVenueNavigation.permanentOperation
                  }
                />
                <p>Permanent operation</p>
              </div>
              {props.tsiContentObject.idVenueNavigation.eventOwnValue !==
                undefined &&
                props.tsiContentObject.idVenueNavigation.eventOwnValue.length >
                  0 && (
                  <div>
                    <input type="radio" />
                    <p>
                      {props.tsiContentObject.idVenueNavigation.eventOwnValue}
                    </p>
                  </div>
                )}
            </div>

            <h4>Expected visitors</h4>
            <div className={styles.tsi__venue__expectedVisitors__container}>
              <div>
                <h6>Per Event</h6>
                <input
                  value={
                    props.tsiContentObject.idVenueNavigation.expectedPerEvent
                  }
                />
              </div>
              <div>
                <h6>Maximum per hour</h6>
                <input
                  value={
                    props.tsiContentObject.idVenueNavigation.maximumPerHour
                  }
                />
              </div>
              <div>
                <h6>Average per hour</h6>
                <input
                  value={
                    props.tsiContentObject.idVenueNavigation.averagePerHour
                  }
                />
              </div>
              <div>
                <h6>Average per day</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.averageperDay}
                />
              </div>
              <div>
                <h6>Average per year</h6>
                <input
                  value={
                    props.tsiContentObject.idVenueNavigation.averageperYear
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Venue structure</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Single or Multiple clients?</h4>

            {props.tsiContentObject.idVenueNavigation.isSingleClient ? (
              <p>Single</p>
            ) : (
              <p>Multiple</p>
            )}

            <h4>Venue architecture plan</h4>
            <p>
              {files.filter((str) => str.includes("venuePlan")).length > 0 ? (
                files
                  .filter((str) => str.includes("venuePlan"))
                  .map((filePath) => <Download filePath={filePath} />)
              ) : (
                <p>No files attached</p>
              )}
            </p>
            <h4>Amount of checkpoints</h4>
            <div className={styles.tsi__venue__checkpoints__container}>
              <div>
                <h6>Flex.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.flexGate}
                />
              </div>
              <div>
                <h6>Vario.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.varioGate}
                />
              </div>
              <div>
                <h6>Arena.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.arenaGate}
                />
              </div>
              <div>
                <h6>Mobile.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.mobileGate}
                />
              </div>
              <div>
                <h6>Handheld.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.handheldGate}
                />
              </div>
              <div>
                <h6>Lite.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.liteGate}
                />
              </div>
              <div>
                <h6>Door.Gate</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.doorGate}
                />
              </div>
              <div>
                <h6>Key Detector</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.keyDetector}
                />
              </div>
              <div>
                <h6>Other</h6>
                <input
                  value={props.tsiContentObject.idVenueNavigation.otherAmount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Integration Type</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Support of the following use cases must be given:</h4>
            <div>
              <input
                type="radio"
                checked={
                  props.tsiContentObject.idIntegrationTypeNavigation.standardTSI
                }
              />
              <p>Standard TSI Interface</p>
            </div>
            <div>
              <input
                type="radio"
                checked={props.tsiContentObject.idIntegrationTypeNavigation.pii}
              />
              <p>PII (Permission Inquiry Interface)</p>
            </div>
            <div>
              <input
                type="radio"
                checked={
                  props.tsiContentObject.idIntegrationTypeNavigation
                    .extendedCheck
                }
              />
              <p>Extended Check</p>
            </div>
            <div>
              <input
                type="radio"
                checked={
                  props.tsiContentObject.idIntegrationTypeNavigation.update
                }
              />
              <p>Update (existing integration)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    tsiContentObject: state.tsiContentObject,
    requestObject: state.requestObject,
    fileNames: state.fileNames,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUseCasesObject: (useCasesobject: IUseCase[]) =>
      dispatch(setUseCasesObject(useCasesobject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTSI);
