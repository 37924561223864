import { SetStakeholdersAction } from "../../interfaces/setStakeholdersAction";
import { setSTAKEHOLDERSOBJECT } from "../constants/stakeholders.types";

const INITIAL_STATE = null;

const StakeholdersObjectReducer = function (
  state = INITIAL_STATE,
  action: SetStakeholdersAction
) {
  switch (action.type) {
    //ADMIN
    case setSTAKEHOLDERSOBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default StakeholdersObjectReducer;
