import * as React from "react";
import styles from "./stakeholders.module.scss";
import NextBackButtons from "../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../redux/actions/currentPage.actions";
import { setRequestType } from "../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../redux/actions/progressBarStatus.actions";
import { useEffect, useState } from "react";

import { FaUserFriends } from "react-icons/fa";

import CustomerContactPerson from "../../../molecules/customerContactPersons/customerContactPersons";

import SdSubsidiaries from "../../../molecules/sdSubsidiaries/sdSubsidiaries";
import { IStakeholders } from "../../../../../interfaces/stakeholders";
import { setStakeholdersObject } from "../../../../../redux/actions/stakeholders.actions";
import { setRequestObject } from "../../../../../redux/actions/requestObject.actions";
import { IRequest } from "../../../../../interfaces/IRequest";
import CountryDropDown from "../../../molecules/countryDropDown/countryDropDown";

function Stakeholders(props: any) {
  //Requester
  const [requestingPerson, setRequestingPerson] = useState<string>("");
  const [email, setEmail] = useState<string>();
  const [contactPerson, setContactPerson] = useState("");
  const [subsidiary, setSubsidiary] = useState("");
  const [country, setCountry] = useState("");
  const handleCountryChange = (newCountry: string) => {
    setCountry(newCountry);
  };
  //Customer
  const [customerName, setCustomerName] = useState("");
  const [customerURL, setCustomerURL] = useState("");
  //Validation
  const [validationMessage, setValidationMessage] = useState(false);

  const userName = props.user;

  useEffect(() => {
    setRequestingPerson(userName);
    setEmail(props.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <FaUserFriends className={styles.requestStageIcon} />
        <h3>Stakeholders</h3>
        <p>
          List all relevant stakeholders who will define the requirements for
          the proposed solution, or will be using or interacting with it, or are
          involved at a business level or with the rollout.
        </p>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Requester</h4>
            <h5>
              Requesting Person<span>*</span>
            </h5>
            <input value={props.user} />
            <h5>Contact person</h5>
            <input
              placeholder="if different to Requester"
              value={contactPerson}
              onChange={(e: any) => setContactPerson(e.target.value)}
            />
            <h5>
              Subsidiary/Partner<span>*</span>
            </h5>
            <SdSubsidiaries
              subsidiary={subsidiary}
              setSubsidiary={setSubsidiary}
            />
            {subsidiary === "Other" && (
              <>
                <p></p>
                <input placeholder="Please specify partner name" />
              </>
            )}
            <h5>
              Country<span>*</span>
            </h5>
            <CountryDropDown onValueChange={handleCountryChange} />
          </div>
          <div className={styles.inputColumn}>
            <h4>Customer</h4>
            <h5>Name</h5>
            <input
              value={customerName}
              onChange={(e: any) => setCustomerName(e.target.value)}
            />
            <h5>URL</h5>
            <input
              placeholder="http://example.com/login"
              value={customerURL}
              onChange={(e: any) => setCustomerURL(e.target.value)}
            />
          </div>
        </div>
        <CustomerContactPerson />
      </div>
      <p
        className={styles.errorMessage}
        style={
          validationMessage
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        Please fill in all the required fields.
      </p>
      <NextBackButtons
        next={() => {
          if (requestingPerson !== "" && subsidiary !== "" && country !== "") {
            props.Increment();
            props.setStakeholdersObject({
              requestingPerson: requestingPerson,
              email: email,
              contactPerson: contactPerson,
              subsidiary: subsidiary,
              country: country,
              customerName: customerName,
              customerURL: customerURL,
              customerContactPerson: props.customerContactPersonArray,
            });
            props.setRequestObject({
              requestLabel: "New Request",
              creationDate: new Date().toISOString(),
              lastModifiedDate: new Date().toISOString(),
              status: "requesting",
              assignedDeveloper: "none",
            });
            props.setRequestType();
          } else {
            setValidationMessage(true);
          }
        }}
        back={() => {
          props.setHome();
          setValidationMessage(false);
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    user: state.user,
    email: state.email,
    customerContactPersonArray: state.customerContactPersonArray,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setStakeholdersObject: (stakeholdersObject: IStakeholders) =>
      dispatch(setStakeholdersObject(stakeholdersObject)),
    setRequestObject: (requestObject: IRequest) =>
      dispatch(setRequestObject(requestObject)),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stakeholders);
