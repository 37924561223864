import * as React from "react";
import styles from "./fifthTSI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFourth,
  setRequestType,
  setSecond,
  setSummary,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";

import { FaHandshake } from "react-icons/fa";
import { useEffect, useState } from "react";

import hsIMG from "../../../../../../assets/hs.jpg";
import { IdTsiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setTsiContentObject } from "../../../../../../redux/actions/tsiContentObject.actions";

function FirstTSI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [standardTSI, setstandardTSI] = useState(
    props.tsiContentObject.idIntegrationTypeNavigation.standardTSI
  );
  const [pii, setPii] = useState(
    props.tsiContentObject.idIntegrationTypeNavigation.pii
  );
  const [extendedCheck, setExtendedCheck] = useState(
    props.tsiContentObject.idIntegrationTypeNavigation.extendedCheck
  );
  const [update, setUpdate] = useState(
    props.tsiContentObject.idIntegrationTypeNavigation.update
  );

  const [validationMessage, setValidationMessage] = useState(false);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <FaHandshake className={styles.requestStageIcon} />
        <h5>Integration Type</h5>
        <p>
          The Handshake TSI is a bidirectional interface - the ticketing system
          send information and can request/inquire information from Handshake.
        </p>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h5>
              Support of the following use cases must be given:<span>*</span>
            </h5>
            <div
              className={styles.tsi__venue__venueBusiness__checkboxesContainer}
            >
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={standardTSI}
                    onChange={() => {
                      setstandardTSI(!standardTSI);
                    }}
                  />
                  <p>Standard TSI Interface (Whitelist/Blacklist, Inquiry)</p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={pii}
                    onChange={() => {
                      setPii(!pii);
                    }}
                  />
                  <p>PII (Permission Inquiry Interface)</p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={extendedCheck}
                    onChange={() => {
                      setExtendedCheck(!extendedCheck);
                    }}
                  />
                  <p>Extended Check (own market requirement needed)</p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={update}
                    onChange={() => {
                      setUpdate(!update);
                    }}
                  />
                  <p>Update (existing integration)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        className={styles.errorMessage}
        style={
          validationMessage
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        Please choose at least one checkbox.
      </p>
      <NextBackButtons
        next={() => {
          if (standardTSI || pii || extendedCheck) {
            props.setTsiContentObject({
              ...props.tsiContentObject,
              idIntegrationTypeNavigation: {
                standardTSI: standardTSI,
                pii: pii,
                extendedCheck: extendedCheck,
                update: update,
              },
            });
            props.Increment();
            props.setSummary();
          } else {
            setValidationMessage(true);
          }
        }}
        back={() => {
          props.setFourth();
          props.Decrement();
          setValidationMessage(false);
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    tsiContentObject: state.tsiContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),

    setFourth: () => dispatch(setFourth()),
    setSummary: () => dispatch(setSummary()),

    setTsiContentObject: (tsiContentObject: IdTsiNavigation) =>
      dispatch(setTsiContentObject(tsiContentObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstTSI);
