import * as React from "react";
import { useEffect } from "react";

import { connect } from "react-redux";

import { setPopOutTrue } from "../../../../../redux/actions/popOutWindow.actions";
import FirstTSI from "./First/firstTSI";
import SecondTSI from "./Second/secondTSI";
import ThirdTSI from "./Third/thirdTSI";
import FourthTSI from "./Fourth/fourthTSI";
import FifthTSI from "./Fifth/FifthTSI";
import Summary from "../summary/summary";

function TSI(props: any) {
  useEffect(() => {
    document.getElementById("logo")?.scrollIntoView({ behavior: "smooth" });
  }, [props.progressBarStatus]);

  return (
    <>
      {props.requestStage === "firstAfterType" && <FirstTSI />}
      {props.requestStage === "second" && <SecondTSI />}
      {props.requestStage === "third" && <ThirdTSI />}
      {props.requestStage === "fourth" && <FourthTSI />}
      {props.requestStage === "fifth" && <FifthTSI />}
      {props.requestStage === "summary" && <Summary />}
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestStage: state.requestStage,
    progressBarStatus: state.progressBarStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutTrue: () => dispatch(setPopOutTrue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TSI);
