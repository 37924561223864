import { setPOPOUTFALSE, setPOPOUTTRUE } from "../constants/popOutWindow.types";

const INITIAL_STATE: boolean = false;

const PopOutWindowReducer = function (
  state: boolean = INITIAL_STATE,
  action: any
) {
  switch (action.type) {
    case setPOPOUTTRUE:
      return true;
    case setPOPOUTFALSE:
      return false;
    default:
      return state;
  }
};

export default PopOutWindowReducer;
