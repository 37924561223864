import {
  DECREMENT,
  INCREMENT,
  RESETBAR,
  setFULL,
} from "../constants/progressBarStatus.types";

export const Increment = () => {
  return {
    type: INCREMENT,
  };
};

export const Decrement = () => {
  return {
    type: DECREMENT,
  };
};

export const setFull = () => {
  return {
    type: setFULL,
  };
};

export const ResetBar = () => {
  return {
    type: RESETBAR,
  };
};
