import * as React from "react";
import styles from "./secondEPI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFirstAfterType,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdPayment, MdPayments } from "react-icons/md";
import Dropzone from "react-dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import {
  IdEpiNavigation,
  IdPspNavigation,
} from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPspContentObject } from "../../../../../../redux/actions/pspContentObject.actions";
import { useEffect, useState } from "react";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";
import { addFileName } from "../../../../../../redux/actions/fileNames.actions";
import { setEpiContentObject } from "../../../../../../redux/actions/epiContentObject.actions";

function SecondEPI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [terminalModel, setTerminalModel] = useState(
    props.epiContentObject.idTerminalDetailsNavigation.terminalModel
  );
  const [physicalConnection, setPhysicalConnection] = useState(
    props.epiContentObject.idTerminalDetailsNavigation.physicalConnection
  );
  const [cardTypes, setCardTypes] = useState(
    props.epiContentObject.idTerminalDetailsNavigation.cardTypes
  );
  const [paymentMethods, setPaymentMethods] = useState(
    props.epiContentObject.idTerminalDetailsNavigation.paymentMethods
  );

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<Boolean>(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/progrInter-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          setIsFileUploaded(true);
          props.addFileName(file.name);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  React.useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdPayment className={styles.requestStageIcon} />
        <h5>Termianl Details</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>
              Termianl Model<span>*</span>
            </h4>
            <p>
              Please specify the terminal hardware manufacturer and model(s) to
              be used.
            </p>
            <textarea
              value={terminalModel}
              onChange={(e) => setTerminalModel(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>
              Physical connection<span>*</span>
            </h4>
            <p>
              How should the terminal be connected: Type(s) e.g. USB, ethernet,
              serial.
            </p>
            <textarea
              value={physicalConnection}
              onChange={(e) => setPhysicalConnection(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>
              Card types<span>*</span>
            </h4>
            <p>Which card types must be supported (NFC, mag-stripe, chip)?</p>
            <textarea
              value={cardTypes}
              onChange={(e) => setCardTypes(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>
              Programming interface documentation<span>*</span>
            </h4>
            <p>
              Please upload an English version of the API (Application
              Programming Interface) documentation.
            </p>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {isFileUploaded ? <li>{file?.name}</li> : <li>No files</li>}
            </ul>
            {isFileUploaded && <p>File has been successfully uploaded. </p>}
            {uploadingError && <p>{uploadingError}</p>}
          </div>
          <div className={styles.inputColumn}>
            <h4>Payment methods</h4>
            <p>
              Should specific payment methods be supported (e.g. Visa, MC,
              ApplePay, GooglePay, AliPay etc.)?{" "}
            </p>
            <textarea
              value={paymentMethods}
              onChange={(e) => setPaymentMethods(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setThird();
          props.setEpiContentObject({
            ...props.epiContentObject,
            idTerminalDetailsNavigation: {
              terminalModel: terminalModel,
              physicalConnection: physicalConnection,
              cardTypes: cardTypes,
              paymentMethods: paymentMethods,
            },
          });
        }}
        back={() => {
          props.setFirstAfterType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    epiContentObject: state.epiContentObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setThird: () => dispatch(setThird()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    addFileName: (fileName: string) => dispatch(addFileName(fileName)),
    setEpiContentObject: (epiContentObject: IdEpiNavigation) =>
      dispatch(setEpiContentObject(epiContentObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondEPI);
