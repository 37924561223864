import * as React from "react";
import styles from "./fourthPSP.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFifth,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdPayments } from "react-icons/md";
import { useEffect, useState } from "react";
import { setPspContentObject } from "../../../../../../redux/actions/pspContentObject.actions";
import { IdPspNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";

function FirstPLRC(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [language, setLanguage] = useState(
    props.pspContentObject.idLocalisationDataNavigation.language
  );
  const [locale, setLocale] = useState(
    props.pspContentObject.idLocalisationDataNavigation.locale
  );
  const [paymentMethods, setPaymentMethods] = useState(
    props.pspContentObject.idLocalisationDataNavigation.paymentMethods
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdPayments className={styles.requestStageIcon} />
        <h5>Localisation data</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Localization: Languages(s)</h4>
            <p>
              If texts and/or error messages need to be displayed/translated
            </p>
            <textarea
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Locale</h4>
            <p>
              Used to determine format of prices, dates etc. If right-to-left
              text is required.
            </p>
            <textarea
              value={locale}
              onChange={(e) => setLocale(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Payment methods to support</h4>
            <p>
              Of the possible payment methods supported by the PSP, which ones
              must be supported by this integration? "All"is a valid answer.
            </p>
            <textarea
              value={paymentMethods}
              onChange={(e) => setPaymentMethods(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setFifth();
          props.setPspContentObject({
            idLocalisationDataNavigation: {
              language: language,
              locale: locale,
              paymentMethods: paymentMethods,
            },
          });
        }}
        back={() => {
          props.setThird();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    pspContentObject: state.pspContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setThird: () => dispatch(setThird()),
    setFifth: () => dispatch(setFifth()),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),

    setPspContentObject: (pspObject: IdPspNavigation) =>
      dispatch(setPspContentObject(pspObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstPLRC);
