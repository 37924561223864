import * as React from "react";
import { connect } from "react-redux";
import { IUseCase } from "../../../../../../interfaces/IUseCase";
import { setUseCasesObject } from "../../../../../../redux/actions/useCases.actions";
import styles from "./summaryPLRC.module.scss";

function SummaryPLRC(props: any) {
  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Rules for special case</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Applicable time-windows</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .applicableTimeWindows.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .applicableTimeWindows
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Restricted car parks</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .restrictedCarParks.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .restrictedCarParks
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Dependant on previous stays</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .dependantOnPreviousStays.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .dependantOnPreviousStays
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Minimum/maximum stay duration</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .minMaxStayDuration.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .minMaxStayDuration
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Validation</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .validation.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .validation
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Payment method</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .paymentMethod.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .paymentMethod
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>P.L articles</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .plArticles.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .plArticles
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Use case description</h4>
            <p>
              {props.plrcContentObject.idRulesForSpecialCaseNavigation
                .useCaseDescription.length > 0 ? (
                props.plrcContentObject.idRulesForSpecialCaseNavigation
                  .useCaseDescription
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Rate change</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Change of article</h4>

            <p>
              {props.plrcContentObject.idRateChangeNavigation.changeOfArticle
                .length > 0 ? (
                props.plrcContentObject.idRateChangeNavigation.changeOfArticle
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Change of rate table</h4>
            <p>
              {props.plrcContentObject.idRateChangeNavigation.changeOfRateTable
                .length > 0 ? (
                props.plrcContentObject.idRateChangeNavigation.changeOfRateTable
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Adjustment of P.L rate</h4>
            <p>
              {props.plrcContentObject.idRateChangeNavigation.adjustmentOfPlRate
                .length > 0 ? (
                props.plrcContentObject.idRateChangeNavigation
                  .adjustmentOfPlRate
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Independant calculation of rate</h4>
            <p>
              {props.plrcContentObject.idRateChangeNavigation
                .independantCalculationOfRate.length > 0 ? (
                props.plrcContentObject.idRateChangeNavigation
                  .independantCalculationOfRate
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>Rate change description</h4>
            <p>
              {props.plrcContentObject.idRateChangeNavigation
                .rateChangeDescription.length > 0 ? (
                props.plrcContentObject.idRateChangeNavigation
                  .rateChangeDescription
              ) : (
                <>Empty</>
              )}
            </p>
            <h4>OEM parameters required</h4>
            <p>
              {props.plrcContentObject.idRateChangeNavigation
                .oemParametersRequired.length > 0 ? (
                props.plrcContentObject.idRateChangeNavigation
                  .oemParametersRequired
              ) : (
                <>Empty</>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    plrcContentObject: state.plrcContentObject,
    requestObject: state.requestObject,
    fileNames: state.fileNames,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUseCasesObject: (useCasesobject: IUseCase[]) =>
      dispatch(setUseCasesObject(useCasesobject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPLRC);
