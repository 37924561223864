import { addFILENAME, resetFILENAMES } from "../constants/fileNames.types";

export const addFileName = (fileName: string) => {
  return {
    type: addFILENAME,
    payload: fileName,
  };
};

export const resetFileName = () => {
  return {
    type: resetFILENAMES,
  };
};
