import * as React from "react";
import styles from "./summary.module.scss";
import NextBackButtons from "../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../redux/actions/currentPage.actions";
import {
  setFifth,
  setOutOfScope,
  setReports,
  setRequestType,
} from "../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
  setFull,
} from "../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../logic/requestTypeName.bs";
import { setPopOutTrue } from "../../../../../redux/actions/popOutWindow.actions";
import { setPopOutRequestSummary } from "../../../../../redux/actions/popOutWindowType.actions";
import { AiOutlineFileSearch } from "react-icons/ai";
import { ICustomerContactPerson } from "../../../../../interfaces/ICustomerContactPerson";
import SummaryPLGR from "./summaryPLGR/summaryPLGR";
import { IRequest } from "../../../../../interfaces/IRequest";
import axios from "axios";
import { useEffect, useState } from "react";
import { IContent } from "../../../../../interfaces/IContent";
import {
  CustomerContactPerson,
  IRequestObject,
} from "../../../../../interfaces/serviceModels/IRequestJSON";
import SummaryPSP from "./summaryPSP/summaryPSP";
import SummaryPLRC from "./summaryPLRC/summaryPLRC";
import SummaryEPI from "./summaryEPI/summaryEPI";
import SummaryTSI from "./summaryTSI/summaryTSI";
import { setAddResponseStatus } from "../../../../../redux/actions/addResponseStatus.actions";
import { setJsonRequestObject } from "../../../../../redux/actions/jsonRequestObject.actions";
import SummaryUpload from "./summaryUpload/summaryUpload";

function Summary(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);
  const [contentObject, setContentObject] = useState<IContent>(
    props.requestObject.Content
  );

  useEffect(() => {
    {
      props.requestType === "parkingRequest" &&
        setContentObject({
          ...contentObject,
          PLGRContent: {
            useCases: props.useCasesObject,
            functionsAndContext: props.functionsAndContext,
            userInterface: props.userInterfaceObject,
            reports: props.reportsObject,
            acceptanceCriteria: props.acceptanceObject,
            other: props.otherPLGRObject,
          },
        });
    }
    {
      props.requestType === "parkingRate" &&
        setContentObject({
          ...contentObject,
          PLRCContent: props.plrcContentObject,
        });
    }
    {
      props.requestType === "pspRequest" &&
        setContentObject({
          ...contentObject,
          PspContent: props.pspContentObject,
        });
    }
    {
      props.requestType === "epiRequest" &&
        setContentObject({
          ...contentObject,
          EPIContent: props.epiContentObject,
        });
    }
    {
      props.requestType === "ticketingRequest" &&
        setContentObject({
          ...contentObject,
          TSIContent: props.tsiContentObject,
        });
    }
  }, []);

  function transformRequestTypeForInt(requestType: string) {
    switch (requestType) {
      case "parkingRequest":
        return 1;

      case "parkingRate":
        return 2;

      case "ticketingRequest":
        return 3;

      case "pspRequest":
        return 4;

      case "epiRequest":
        return 5;

      case "fiscalDeviceRequest":
        return 6;

      case "uploadRequest":
        return 8;

      default:
        return 7;
    }
  }

  function customerContactPersonerialisation(
    customerContactPerson: ICustomerContactPerson[] | undefined
  ) {
    var newContactPerrsonsArray: CustomerContactPerson[] = [];

    if (customerContactPerson != undefined) {
      customerContactPerson.forEach((contact) => {
        var serializedContact: CustomerContactPerson;

        serializedContact = {
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phone: `${contact.prefix} ${contact.phone}`,
        };

        newContactPerrsonsArray.push(serializedContact);
      });
    }

    return newContactPerrsonsArray;
  }

  function sendRequestHandler(request: IRequest) {
    console.log("Provided request data: ");
    console.log(request);

    var requestJSON: IRequestObject;

    requestJSON = {
      requestLabel: request.requestLabel,
      creationDate: request.creationDate,
      assignedPerson: "none",
      idContentNavigation: {
        idRequestType: transformRequestTypeForInt(props.requestType),
        idPlgrNavigation: {
          useCases: request.Content.PLGRContent?.useCases,
          idFunctionsAndContextNavigation:
            request.Content.PLGRContent?.functionsAndContext,
          idUserInterfaceNavigation: request.Content.PLGRContent?.userInterface,
          idReportsNavigation: request.Content.PLGRContent?.reports,
          idAcceptanceCriteriaNavigation:
            request.Content.PLGRContent?.acceptanceCriteria,
          idOtherNavigation: request.Content.PLGRContent?.other,
        },
        idPspNavigation: request.Content.PspContent,
        idPlrcNavigation: request.Content.PLRCContent,
        idEpiNavigation: request.Content.EPIContent,
        idTsiNavigation: request.Content.TSIContent,
        idUploadNavigation: request.Content.UploadContent,
      },

      idStakeholdersNavigation: {
        requestingPerson: request.stakeholders.requestingPerson,
        email: request.stakeholders.email,
        contactPerson: request.stakeholders.contactPerson,
        idSubsidary: 2,
        subsidary: request.stakeholders.subsidiary,
        country: request.stakeholders.country,
        customerName: request.stakeholders.customerName,
        customerUrl: request.stakeholders.customerURL,
        customerContactPerson: customerContactPersonerialisation(
          request.stakeholders.customerContactPerson
        ),
      },
    };
    props.setJsonRequestObject(requestJSON);
    console.log("RequestJSON:");
    console.log(requestJSON);

    axios
      .post(
        `https://requestpointservice20230301084348.azurewebsites.net/request/add`,
        requestJSON
      )
      .then((response) => {
        console.log(response);
        props.setAddResponseStatus(response.status.toString());
        console.log(response.status);
        console.log("Service response:");
        console.log(response.data);
      })
      .catch((error) => {
        props.setAddResponseStatus(error.response.status);
        console.log(error.response);
      });
  }

  return (
    <div className={props.summary_container}>
      <div className={styles.summaryTitleContainer}>
        <AiOutlineFileSearch className={styles.requestStageIcon} />
        <h5>Summary</h5>

        <p>
          Kindly review the accuracy of the following data before submitting
          this request.
        </p>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.stageholdersContainer}>
          <div className={styles.sectionContainer__sideBar} />

          <div className={styles.stageholdersContainer__window}>
            <h3>Main Details</h3>
            <div
              className={styles.stageholdersContainer__window__contentContainer}
            >
              <div className={styles.stageTitleContainer}>
                <div>
                  <h4>Request Name</h4>
                  <p>{props.requestObject.requestLabel}</p>
                </div>
              </div>
              <h4>Request Type</h4>
              {props.requestType === "parkingRequest" && (
                <p>Parking.Logic Generic Request</p>
              )}
              {props.requestType === "parkingRate" && (
                <p>Parking.Logic Special Rate Calculation</p>
              )}
              {props.requestType === "pspRequest" && <p>PSP Request</p>}
              {props.requestType === "epiRequest" && <p>EPI Request</p>}
              {props.requestType === "ticketingRequest" && (
                <p>Ticketing Partner Integration</p>
              )}
              {props.requestType === "uploadRequest" && <p>Uploaded Request</p>}

              <h4>Creation Date</h4>
              <p>{props.requestObject.creationDate}</p>
              <h4>Last Modified Date</h4>
              <p>{props.requestObject.lastModifiedDate}</p>
            </div>
          </div>
        </div>

        <div className={styles.stageholdersContainer}>
          <div className={styles.sectionContainer__sideBar} />

          <div className={styles.stageholdersContainer__window}>
            <h3>Stakeholders</h3>
            <div
              className={styles.stageholdersContainer__window__contentContainer}
            >
              <div className={styles.useCasesSummary__requesterSection}>
                <h4>Requester</h4>
                <div className={styles.useCasesSummary__tableContainer}>
                  <div className={styles.useCasesSummary__tableHeader}>
                    <div>
                      <p>Requesting Person</p>
                    </div>
                    <div>
                      <p>Contact person</p>
                    </div>
                    <div>
                      <p>Subsidary / partner</p>
                    </div>
                    <div>
                      <p>Country</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles.stageholdersContainer__customerContactPerson__Row
                  }
                >
                  <div>
                    <p>{props.stakeholders.requestingPerson}</p>
                  </div>
                  <div>
                    <p>{props.stakeholders.contactPerson}</p>
                  </div>
                  <div>
                    <p>{props.stakeholders.subsidiary}</p>
                  </div>
                  <div>
                    <p>{props.stakeholders.country}</p>
                  </div>
                </div>
              </div>
              <div className={styles.useCasesSummary__customerSection}>
                <h4>Customer</h4>
                <div className={styles.useCasesSummary__tableContainer}>
                  <div className={styles.useCasesSummary__tableHeader}>
                    <div>
                      <p>Customer Name</p>
                    </div>
                    <div>
                      <p>Customer URL</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles.stageholdersContainer__customerContactPerson__Row
                  }
                >
                  <div>
                    <p>{props.stakeholders.customerName}</p>
                  </div>
                  <div>
                    <p>{props.stakeholders.customerURL}</p>
                  </div>
                </div>
              </div>

              <div
                className={styles.stageholdersContainer__customerContactPerson}
              >
                <h4>Customer Contact Persons</h4>

                <div className={styles.useCasesSummary__tableContainer}>
                  <div className={styles.useCasesSummary__tableHeader}>
                    <div>
                      <p>Name</p>
                    </div>
                    <div>
                      <p>email</p>
                    </div>
                    <div>
                      <p>phone</p>
                    </div>
                  </div>
                  {props.stakeholders.customerContactPerson.length > 0 ? (
                    props.stakeholders.customerContactPerson.map(
                      (contactPerson: ICustomerContactPerson) => (
                        <div
                          className={
                            styles.stageholdersContainer__customerContactPerson__Row
                          }
                        >
                          <div>
                            <p>
                              {contactPerson.firstName} {contactPerson.lastName}
                            </p>
                          </div>
                          <div>
                            <p>{contactPerson.email}</p>
                          </div>
                          <div>
                            <p>
                              {contactPerson.prefix} {contactPerson.phone}
                            </p>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className={styles.emptyTable}>
                      <p>Empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.requestType === "parkingRequest" && <SummaryPLGR />}
        {props.requestType === "parkingRate" && <SummaryPLRC />}
        {props.requestType === "pspRequest" && <SummaryPSP />}
        {props.requestType === "epiRequest" && <SummaryEPI />}
        {props.requestType === "ticketingRequest" && <SummaryTSI />}
        {props.requestType === "uploadRequest" && <SummaryUpload />}
      </div>
      <NextBackButtons
        next={() => {
          props.setFull();
          props.setPopOutTrue();
          props.setPopOutRequestSummary();
          if (props.requestType !== "uploadRequest") {
            sendRequestHandler({
              ...props.requestObject,
              status: "sent",
              stakeholders: props.stakeholders,
              Content: contentObject,
            });
          } else {
            sendRequestHandler({
              ...props.requestObject,
              status: "sent",
              stakeholders: props.stakeholders,
              Content: {
                ...props.requestObject.Content,
                UploadContent: {
                  additionalDescription:
                    props.requestObject.Content.UploadContent
                      .additionalDescription,
                },
              },
            });
          }
        }}
        back={() => {
          if (props.requestType === "parkingRequest") {
            props.setOutOfScope();
          } else if (props.requestType === "uploadRequest") {
            props.setRequestType();
          } else {
            props.setFifth();
          }
          props.Decrement();
        }}
      />
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    stakeholders: state.stakeholders,
    requestObject: state.requestObject,
    useCasesObject: state.useCasesObject,
    functionsAndContext: state.functionsAndContext,
    userInterfaceObject: state.userInterfaceObject,
    reportsObject: state.reportsObject,
    acceptanceObject: state.acceptanceObject,
    otherPLGRObject: state.otherPLGRObject,
    pspContentObject: state.pspContentObject,
    plrcContentObject: state.plrcContentObject,
    epiContentObject: state.epiContentObject,
    tsiContentObject: state.tsiContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutRequestSummary: () => dispatch(setPopOutRequestSummary()),
    setFull: () => dispatch(setFull()),
    setHome: () => dispatch(setHome()),
    setReports: () => dispatch(setReports()),
    setOutOfScope: () => dispatch(setOutOfScope()),
    setFifth: () => dispatch(setFifth()),
    setRequestType: () => dispatch(setRequestType()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setPopOutTrue: () => dispatch(setPopOutTrue()),
    setAddResponseStatus: (status: string) =>
      dispatch(setAddResponseStatus(status)),
    setJsonRequestObject: (request: IRequestObject) =>
      dispatch(setJsonRequestObject(request)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
