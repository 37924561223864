import { IRequest } from "../../interfaces/IRequest";
import { setREQUESTOBJECT } from "../constants/requestObject.types";

export const setRequestObject = (requestObject: IRequest | null) => {
  return {
    type: setREQUESTOBJECT,
    payload: requestObject,
  };
};

export const resetRequestObject = () => {
  return {
    type: setREQUESTOBJECT,
  };
};
