import * as React from "react";
import styles from "./requestView.module.scss";

import { connect } from "react-redux";

import axios from "axios";
import { useEffect, useState } from "react";

import { ICustomerContactPerson } from "../../../../interfaces/ICustomerContactPerson";
import { IRequestObject } from "../../../../interfaces/serviceModels/IRequestJSON";
import RequestViewPLGR from "./requestViewPLGR/requestViewPLGR";
import BackToMenuButton from "../../atoms/backToMenuButton/backToMenuButton";
import {
  setAdminPanel,
  setCurrentRequests,
  setHome,
} from "../../../../redux/actions/currentPage.actions";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import RequestViewPLRC from "./requestViewPLRC/requestViewPLRC";
import RequestViewPSP from "./requestViewPSP/requestViewPSP";
import RequestViewEPI from "./requestViewEPI/requestViewEPI";
import RequestViewTSI from "./requestViewTSI/requestViewTSI";
import RequestViewUpload from "./requestViewUpload/requestViewUpload";
import RequestViewImage from "../../../../assets/requestView.jpg";

function RequestView(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [currentRequestView, setCurrentRequestView] = useState<IRequestObject>(
    props.currentRequestObject
  );

  return (
    <div className={styles.requestMainCotnainer}>
      <div className={styles.contactList__buttonContainer}>
        {props.previousPage === "previousHome" && (
          <button onClick={() => props.setHome()}>
            <MdOutlineArrowBackIosNew />
            Back to Home
          </button>
        )}
        {props.previousPage === "previousAdmin" && (
          <button onClick={() => props.setAdminPanel()}>
            <MdOutlineArrowBackIosNew />
            Back to Admin
          </button>
        )}
        {props.previousPage === "previousCurrent" && (
          <button onClick={() => props.setCurrentRequests()}>
            <MdOutlineArrowBackIosNew />
            Back
          </button>
        )}
      </div>
      <div className={styles.requestContainer}>
        <div className={styles.requestHeader}>
          <h2>Request View</h2>
        </div>
        <div className={props.summary_container}>
          {currentRequestView === null ? (
            <>Loading...</>
          ) : (
            <div className={styles.requestContentContainer}>
              <div className={styles.stageholdersContainer}>
                <div className={styles.sectionContainer__sideBar} />

                <div className={styles.stageholdersContainer__window}>
                  <h3>Main Details</h3>
                  <div
                    className={
                      styles.stageholdersContainer__window__contentContainer
                    }
                  >
                    <div className={styles.stageTitleContainer}>
                      <div>
                        <h4>Request Name</h4>
                        <p>{currentRequestView.requestLabel}</p>
                      </div>
                      <div>
                        <h4>Request ID</h4>
                        <p>{currentRequestView.idRequest}</p>
                      </div>
                    </div>
                    <h4>Request Type</h4>
                    {currentRequestView.idContentNavigation.idRequestType ==
                      1 && <p>Parking.Logic Generic Request</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      2 && <p>Parking.Logic Special Rate Calculation</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      3 && <p>Ticketing Partner Integration</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      4 && <p>PSP Request</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      5 && <p>EPI Request</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      6 && <p>Fiscal Device Integration Request</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      7 && <p>Test</p>}
                    {currentRequestView.idContentNavigation.idRequestType ==
                      8 && <p>Uploaded Request</p>}
                    <h4>Creation Date</h4>
                    <p>{currentRequestView.creationDate}</p>
                    <h4>Last Modified Date</h4>
                    <p>{currentRequestView.lastModifiedDate}</p>
                  </div>
                </div>
              </div>
              <div className={styles.stageholdersContainer}>
                <div className={styles.sectionContainer__sideBar} />

                <div className={styles.stageholdersContainer__window}>
                  <h3>Stakeholders</h3>
                  <div
                    className={
                      styles.stageholdersContainer__window__contentContainer
                    }
                  >
                    <div className={styles.useCasesSummary__requesterSection}>
                      <h4>Requester</h4>
                      <div className={styles.useCasesSummary__tableContainer}>
                        <div className={styles.useCasesSummary__tableHeader}>
                          <div>
                            <p>Requesting Person</p>
                          </div>
                          <div>
                            <p>Contact person</p>
                          </div>
                          <div>
                            <p>Subsidary / partner</p>
                          </div>
                          <div>
                            <p>Country</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          styles.stageholdersContainer__customerContactPerson__Row
                        }
                      >
                        <div>
                          <p>
                            {
                              currentRequestView.idStakeholdersNavigation
                                .requestingPerson
                            }
                          </p>
                        </div>
                        <div>
                          <p>
                            {
                              currentRequestView.idStakeholdersNavigation
                                .contactPerson
                            }
                          </p>
                        </div>
                        <div>
                          <p>
                            {
                              currentRequestView.idStakeholdersNavigation
                                .subsidary
                            }
                          </p>
                        </div>
                        <div>
                          <p>
                            {
                              currentRequestView.idStakeholdersNavigation
                                .country
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.useCasesSummary__customerSection}>
                      <h4>Customer</h4>
                      <div className={styles.useCasesSummary__tableContainer}>
                        <div className={styles.useCasesSummary__tableHeader}>
                          <div>
                            <p>Customer Name</p>
                          </div>
                          <div>
                            <p>Customer URL</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          styles.stageholdersContainer__customerContactPerson__Row
                        }
                      >
                        <div>
                          <p>
                            {
                              currentRequestView.idStakeholdersNavigation
                                .customerName
                            }
                          </p>
                        </div>
                        <div>
                          <p>
                            {
                              currentRequestView.idStakeholdersNavigation
                                .customerUrl
                            }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        styles.stageholdersContainer__customerContactPerson
                      }
                    >
                      <h4>Customer Contact Persons</h4>

                      <div className={styles.useCasesSummary__tableContainer}>
                        <div className={styles.useCasesSummary__tableHeader}>
                          <div>
                            <p>Name</p>
                          </div>
                          <div>
                            <p>email</p>
                          </div>
                          <div>
                            <p>phone</p>
                          </div>
                        </div>
                        {currentRequestView.idStakeholdersNavigation
                          .customerContactPerson !== undefined &&
                        currentRequestView.idStakeholdersNavigation
                          .customerContactPerson.length > 0 ? (
                          currentRequestView.idStakeholdersNavigation.customerContactPerson.map(
                            (contactPerson: ICustomerContactPerson) => (
                              <div
                                className={
                                  styles.stageholdersContainer__customerContactPerson__Row
                                }
                              >
                                <div>
                                  <p>
                                    {contactPerson.firstName}{" "}
                                    {contactPerson.lastName}
                                  </p>
                                </div>
                                <div>
                                  <p>{contactPerson.email}</p>
                                </div>
                                <div>
                                  <p>
                                    {contactPerson.prefix} {contactPerson.phone}
                                  </p>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div className={styles.emptyTable}>
                            <p>Empty</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currentRequestView.idContentNavigation.idRequestType === 1 && (
                <RequestViewPLGR />
              )}
              {currentRequestView.idContentNavigation.idRequestType === 2 && (
                <RequestViewPLRC />
              )}
              {currentRequestView.idContentNavigation.idRequestType === 3 && (
                <RequestViewTSI />
              )}
              {currentRequestView.idContentNavigation.idRequestType === 4 && (
                <RequestViewPSP />
              )}
              {currentRequestView.idContentNavigation.idRequestType === 5 && (
                <RequestViewEPI />
              )}
              {currentRequestView.idContentNavigation.idRequestType === 8 && (
                <RequestViewUpload />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    currentRequestObject: state.currentRequestObject,
    previousPage: state.previousPage,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setAdminPanel: () => dispatch(setAdminPanel()),
    setCurrentRequests: () => dispatch(setCurrentRequests()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestView);
