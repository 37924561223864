import { IAcceptanceObject } from "../../interfaces/IAcceptanceObject";
import { setACCEPTANCEOBJECT } from "../constants/acceptanceObject.types";

const INITIAL_STATE: IAcceptanceObject = {
  acceptanceCriteriaDescription: "",
};

const AcceptanceObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IAcceptanceObject }
) {
  switch (action.type) {
    case setACCEPTANCEOBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default AcceptanceObjectReducer;
