import {
  setACCTEPTANCE,
  setCONTEXT,
  setFIFTH,
  setFIRSTAFTERTYPE,
  setFOURTH,
  setOUTOFSCOPE,
  setREPORTS,
  setREQUESTTYPE,
  setSECOND,
  setSTAKEHOLDERS,
  setSUMMARY,
  setTHIRD,
  setUSECASES,
  setUSERINTERFACE,
} from "../constants/requestStage.types ";

export const setStakeholders = () => {
  return {
    type: setSTAKEHOLDERS,
  };
};

export const setRequestType = () => {
  return {
    type: setREQUESTTYPE,
  };
};

export const setUseCases = () => {
  return {
    type: setUSECASES,
  };
};

export const setContext = () => {
  return {
    type: setCONTEXT,
  };
};

export const setUserInterface = () => {
  return {
    type: setUSERINTERFACE,
  };
};

export const setReports = () => {
  return {
    type: setREPORTS,
  };
};

export const setAcceptance = () => {
  return {
    type: setACCTEPTANCE,
  };
};

export const setOutOfScope = () => {
  return {
    type: setOUTOFSCOPE,
  };
};

export const setSummary = () => {
  return {
    type: setSUMMARY,
  };
};

export const setFirstAfterType = () => {
  return {
    type: setFIRSTAFTERTYPE,
  };
};
export const setSecond = () => {
  return {
    type: setSECOND,
  };
};
export const setThird = () => {
  return {
    type: setTHIRD,
  };
};
export const setFourth = () => {
  return {
    type: setFOURTH,
  };
};
export const setFifth = () => {
  return {
    type: setFIFTH,
  };
};
