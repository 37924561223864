import * as React from "react";

import { connect } from "react-redux";

import { useEffect } from "react";

import Summary from "../summary/summary";
import { setPopOutTrue } from "../../../../../redux/actions/popOutWindow.actions";
import FirstEPI from "./first/firstEPI";
import SecondEPI from "./second/secondEPI";
import ThirdEPI from "./third/thirdEPI";
import FourthEPI from "./fourth/fourthEPI";
import FifthEPI from "./fifth/fifthEPI";

function EPI(props: any) {
  useEffect(() => {
    document.getElementById("logo")?.scrollIntoView({ behavior: "smooth" });
  }, [props.progressBarStatus]);

  return (
    <>
      {props.requestStage === "firstAfterType" && <FirstEPI />}
      {props.requestStage === "second" && <SecondEPI />}
      {props.requestStage === "third" && <ThirdEPI />}
      {props.requestStage === "fourth" && <FourthEPI />}
      {props.requestStage === "fifth" && <FifthEPI />}
      {props.requestStage === "summary" && <Summary />}
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestStage: state.requestStage,
    progressBarStatus: state.progressBarStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutTrue: () => dispatch(setPopOutTrue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EPI);
