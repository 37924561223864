import * as React from "react";

function Help(props: any) {
  return (
    <>
      <h2>Help</h2>
    </>
  );
}

export default Help;
