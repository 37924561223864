import { IRequest } from "../../interfaces/IRequest";
import {
  resetREQUESTOBJECT,
  setREQUESTOBJECT,
} from "../constants/requestObject.types";

const INITIAL_STATE: IRequest | null = null;

const RequestObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IRequest }
) {
  switch (action.type) {
    case setREQUESTOBJECT:
      return action.payload;
    case resetREQUESTOBJECT:
      return state;
    default:
      return state;
  }
};

export default RequestObjectReducer;
