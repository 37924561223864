import * as React from "react";
import styles from "./firstPSP.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setRequestType,
  setSecond,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdPayments } from "react-icons/md";
import { IdPspNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPspContentObject } from "../../../../../../redux/actions/pspContentObject.actions";
import { useEffect, useState } from "react";

function FirstPSP(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [productToIntegrate, setProductToIntegrate] = useState<string>(
    props.pspContentObject.idProductDescriptionNavigation.sdproductToIntegrate
  );
  const [countryRegion, setCountryRegion] = useState<string>(
    props.pspContentObject.idProductDescriptionNavigation.countryRegion
  );
  const [revenueShare, setRevenueShare] = useState<string>(
    props.pspContentObject.idProductDescriptionNavigation.revenueShare
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdPayments className={styles.requestStageIcon} />
        <h5>Product Description</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>SD product(s) to integrate</h4>
            <p>E.g. sweb Mobile Pay, eCom, Validate, sweb Reserve</p>
            <textarea
              value={productToIntegrate}
              onChange={(e) => setProductToIntegrate(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Country(s) or region(s)</h4>
            <p>For which countries will this integtaion be available</p>
            <textarea
              value={countryRegion}
              onChange={(e) => setCountryRegion(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>With revenue-share?</h4>
            <p>This should be checked with TRMA</p>
            <textarea
              value={revenueShare}
              onChange={(e) => setRevenueShare(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setSecond();
          props.setPspContentObject({
            idProductDescriptionNavigation: {
              sdproductToIntegrate: productToIntegrate,
              countryRegion: countryRegion,
              revenueShare: revenueShare,
            },
          });
        }}
        back={() => {
          props.setRequestType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    pspContentObject: state.pspContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setSecond: () => dispatch(setSecond()),
    setPspContentObject: (pspObject: IdPspNavigation) =>
      dispatch(setPspContentObject(pspObject)),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstPSP);
