import * as React from "react";

import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { connect } from "react-redux";
import { setHome } from "../../../../redux/actions/currentPage.actions";
import CurrentRequestTabele from "../../molecules/currentRequestTabele/currentRequestTabele";

import styles from "./adminPanel.module.scss";
import { useState } from "react";
import RequestView from "../requestView/requestView";

function AdminPanel(props: any) {
  const [isRequestView, setIsRequestView] = useState(false);

  return (
    <div className={styles.contactList__mainContainer}>
      <div className={styles.contactList__buttonContainer}>
        {isRequestView ? (
          <button onClick={() => setIsRequestView(false)}>
            <MdOutlineArrowBackIosNew />
            Back to Admin
          </button>
        ) : (
          <button onClick={() => props.setHome()}>
            <MdOutlineArrowBackIosNew />
            Back to Home
          </button>
        )}
      </div>
      <CurrentRequestTabele
        setIsRequestView={setIsRequestView}
        previousPage={"previousAdmin"}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
  };
};

export default connect(null, mapDispatchToProps)(AdminPanel);
