import {
  DECREMENT,
  INCREMENT,
  RESETBAR,
  setFULL,
} from "../constants/progressBarStatus.types";

const INITIAL_STATE = 10;

const ProgressBarStatusReducer = function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case INCREMENT:
      return state + 10;
    case DECREMENT:
      return state - 10;
    case setFULL:
      return (state = 100);
    case RESETBAR:
      return (state = INITIAL_STATE);
    default:
      return state;
  }
};

export default ProgressBarStatusReducer;
