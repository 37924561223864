import * as React from "react";

import { connect } from "react-redux";

import { setPopOutTrue } from "../../../../../redux/actions/popOutWindow.actions";
import Summary from "../summary/summary";

import FirstPSP from "./First/firstPSP";
import SecondPSP from "./Second/secondPSP";
import ThirdPSP from "./Third/thirdPSP";
import FourthPSP from "./Fourth/fourthPSP";
import FifthPSP from "./Fifth/fifthPSP";

function PSP(props: any) {
  React.useEffect(() => {
    document.getElementById("logo")?.scrollIntoView({ behavior: "smooth" });
  }, [props.progressBarStatus]);

  return (
    <>
      {props.requestStage === "firstAfterType" && <FirstPSP />}
      {props.requestStage === "second" && <SecondPSP />}
      {props.requestStage === "third" && <ThirdPSP />}
      {props.requestStage === "fourth" && <FourthPSP />}
      {props.requestStage === "fifth" && <FifthPSP />}
      {props.requestStage === "summary" && <Summary />}
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestStage: state.requestStage,
    progressBarStatus: state.progressBarStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutTrue: () => dispatch(setPopOutTrue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PSP);
