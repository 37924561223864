import * as React from "react";
import styles from "./addButton.module.scss";
import { MdOutlineAddCircleOutline } from "react-icons/md";

function AddButton(props: any) {
  return (
    <button className={styles.addButton} onClick={props.onClick}>
      <MdOutlineAddCircleOutline /> Add
    </button>
  );
}

export default AddButton;
