import { connect } from "react-redux";
import styles from "./currentRequestTabele.module.scss";

import axios from "axios";
import { useEffect, useState } from "react";
import { request } from "http";
import { IRequest } from "../../../../interfaces/IRequest";
import {
  setRequest,
  setRequestView,
} from "../../../../redux/actions/currentPage.actions";
import { setSummary } from "../../../../redux/actions/requestStage.actions ";
import { setCurrentRequestObject } from "../../../../redux/actions/currentRequestObject.actions";
import {
  setPreviousAdmin,
  setPreviousCurrent,
  setPreviousHome,
} from "../../../../redux/actions/previousPage.actions";
import { AiOutlineLoading } from "react-icons/ai";

function CurrentRequestTabele(props: any) {
  const [currentUserName, setCurrentUserName] = useState(props.user);
  const [userRequests, setUserRequests] = useState<IRequest[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [connections, setConnections] = useState(0);

  const [url, setUrl] = useState<string>(``);
  const [header, setHeader] = useState<string>("");

  useEffect(() => {
    if (props.page === "adminPanel") {
      setUrl(
        "https://requestpointservice20230301084348.azurewebsites.net/request/all"
      );
      setHeader("All Requests");
    } else {
      setUrl(
        `https://requestpointservice20230301084348.azurewebsites.net/request/requester/${props.user}`
      );
      setHeader("Current Requests");
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      axios.get(url).then((response) => {
        var currentRequestsArray: IRequest[] = [];
        var newRequest: IRequest;

        try {
          response.data.forEach((responseRequest: any) => {
            newRequest = {
              id: responseRequest.idRequest,
              requestLabel: responseRequest.requestLabel,
              creationDate: responseRequest.creationDate,
              lastModifiedDate: responseRequest.creationDate,
              assignedDeveloper: responseRequest.assignedPerson,
              status: responseRequest.idStatus,
              stakeholders: { requestingPerson: currentUserName },
              Content: {
                RequestType: responseRequest.idStatus,
              },
            };

            currentRequestsArray.push(newRequest);
          });
        } catch (error) {
          setConnections(connections + 1);
          console.log("connections++");
        }

        setUserRequests(
          currentRequestsArray.sort((a: any, b: any) => b.id - a.id)
        );
        if (currentRequestsArray.length > 0 || connections >= 3) {
          setIsLoading(false);
          console.log("isLoading = false");
        } else {
          setConnections(connections + 1);
          console.log("connections++");
        }

        console.log("Try: " + connections);
        console.log(response.data);
      });
    } else {
      console.log("isLoading = false");
    }
  }, [connections]);

  useEffect(() => {
    setCurrentUserName(props.user);
  });

  function setCurrentRequestHandler(id: string | undefined) {
    axios
      .get(
        `https://requestpointservice20230301084348.azurewebsites.net/request/detailed/${id}`
      )
      .then((response) => {
        props.setCurrentRequestObject(response.data);
        console.log(response.data);
        props.setRequestView();
        {
          props.previousPage === "previousHome" && props.setPreviousHome();
        }
        {
          props.previousPage === "previousAdmin" && props.setPreviousAdmin();
        }
        {
          props.previousPage === "previousCurrent" &&
            props.setPreviousCurrent();
        }
      })
      .finally(() => {
        props.setIsRequestView(true);
      });
  }

  function ReturnStatusString(statusId: number) {
    switch (statusId) {
      case 1:
        return "none";
      case 2:
        return "sent";
      case 3:
        return "developing";
      case 4:
        return "canceled";
      case 5:
        return "done";
      case 6:
        return "test";
      default:
        return "test";
    }
  }

  return (
    <div className={styles.homeDescriptionSection}>
      <div className={styles.currentRequestHeader}>
        <h2>{header}</h2>
      </div>
      <div className={styles.currentRequest__table__header}>
        <div>
          <p>Id</p>
        </div>
        <div className={styles.currentRequest__table__nameField}>
          <p>Name</p>
        </div>
        <div className={styles.currentRequest__table__dateField}>
          <p>Creation Date</p>
        </div>
        <div>
          <p>Status</p>
        </div>
        <div>
          <p>Assigned</p>
        </div>
      </div>
      <div className={styles.rows__container}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <p>Loading...</p>
            <span>
              <AiOutlineLoading />
            </span>
          </div>
        ) : userRequests.length === 0 ? (
          <p>No requests</p>
        ) : (
          userRequests.map((request: IRequest) => (
            <div
              className={styles.currentRequest__table__row}
              onClick={() => {
                setCurrentRequestHandler(request.id);
              }}
            >
              <div>
                <p>{request.id}</p>
              </div>
              <div className={styles.currentRequest__table__nameField}>
                <p>{request.requestLabel}</p>
              </div>
              <div className={styles.currentRequest__table__dateField}>
                <p>{new Date(request.creationDate).toLocaleString("de")}</p>
              </div>
              <div>
                <p>{ReturnStatusString(parseInt(request.status))}</p>
              </div>
              <div>
                <p>{request.assignedDeveloper}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    page: state.page,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPreviousHome: () => dispatch(setPreviousHome()),
    setPreviousAdmin: () => dispatch(setPreviousAdmin()),
    setPreviousCurrent: () => dispatch(setPreviousCurrent()),
    setRequestView: () => dispatch(setRequestView()),
    setCurrentRequestObject: (currentRequestObject: IRequest) =>
      dispatch(setCurrentRequestObject(currentRequestObject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentRequestTabele);
