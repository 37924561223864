import * as React from "react";
import styles from "./firstPLRC.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setRequestType,
  setSecond,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney } from "react-icons/md";
import { useEffect, useState } from "react";
import { setPlrcContentObject } from "../../../../../../redux/actions/plrcContentObject.actions";
import { IdPlrcNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";

function FirstPLRC(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [applicableTime, setApplicableTime] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation
      .applicableTimeWindows
  );
  const [restrictedCarParks, setRestrictedCarParks] = useState<string>(
    props.plrcContentObject.idRulesForSpecialCaseNavigation.restrictedCarParks
  );
  const [dependantOnPreviousStays, setDependantOnPreviousStays] =
    useState<string>(
      props.plrcContentObject.idRulesForSpecialCaseNavigation
        .dependantOnPreviousStays
    );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdAttachMoney className={styles.requestStageIcon} />
        <h5>Rules for special case (1/3):</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Applicable time-windows</h4>
            <p>
              Is the special rate dependant on vehicle passsage within specified
              time windows? Describe the time windows.
            </p>
            <textarea
              value={applicableTime}
              onChange={(e) => setApplicableTime(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Restricted car parks</h4>
            <p>
              Is the special rate dependant on vehicle entry in specified car
              park(s)? Which ones?
            </p>
            <textarea
              value={restrictedCarParks}
              onChange={(e) => setRestrictedCarParks(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Dependant on previous stays</h4>
            <p>
              If the vehicle had a previous stay on same day, previous
              day/week/month - how does this affect the special rate?
            </p>
            <textarea
              value={dependantOnPreviousStays}
              onChange={(e) => setDependantOnPreviousStays(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setSecond();
          props.setPlrcContentObject({
            idRulesForSpecialCaseNavigation: {
              ...props.plrcContentObject.idRulesForSpecialCaseNavigation,
              applicableTimeWindows: applicableTime,
              restrictedCarParks: restrictedCarParks,
              dependantOnPreviousStays: dependantOnPreviousStays,
            },
          });
        }}
        back={() => {
          props.setRequestType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    plrcContentObject: state.plrcContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setSecond: () => dispatch(setSecond()),

    setPlrcContentObject: (plrcObject: IdPlrcNavigation) =>
      dispatch(setPlrcContentObject(plrcObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstPLRC);
