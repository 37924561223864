import * as React from "react";
import styles from "./home.module.scss";

import {
  setCurrentRequests,
  setHelp,
  setRequest,
} from "../../../../redux/actions/currentPage.actions";
import { connect } from "react-redux";
import { Person } from "@microsoft/mgt-react";
import MainMenuTiles from "../../molecules/mainMenuTiles/mainMenuTiles";
import CurrentRequestTabele from "../../molecules/currentRequestTabele/currentRequestTabele";
import { useEffect, useState } from "react";
import { IRequest } from "../../../../interfaces/IRequest";
import {
  resetRequestObject,
  setRequestObject,
} from "../../../../redux/actions/requestObject.actions";
import axios from "axios";

function Home(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [connections, setConnections] = useState(0);
  const [lastRequest, setLastRequest] = useState<IRequest>();
  const [lastUpdate, setLastUpdate] = useState<IRequest>();

  useEffect(() => {
    if (isLoading) {
      axios
        .get(
          `https://requestpointservice20230301084348.azurewebsites.net/request/requester/${props.user}`
        )
        .then((response) => {
          var currentRequestsArray: IRequest[] = [];
          var newRequest: IRequest;

          try {
            response.data.forEach((responseRequest: any) => {
              newRequest = {
                id: responseRequest.idRequest,
                requestLabel: responseRequest.requestLabel,
                creationDate: responseRequest.creationDate,
                lastModifiedDate: responseRequest.creationDate,
                assignedDeveloper: responseRequest.assignedPerson,
                status: responseRequest.idStatus,
                stakeholders: { requestingPerson: props.user },
                Content: {
                  RequestType: responseRequest.idStatus,
                },
              };

              currentRequestsArray.push(newRequest);
            });
          } catch (error) {
            setConnections(connections + 1);
            console.log("connections++");
          }

          setLastRequest(
            currentRequestsArray.sort((a, b) => {
              const dateA = Date.parse(a.creationDate);
              const dateB = Date.parse(b.creationDate);
              return dateB - dateA;
            })[0]
          );

          setLastUpdate(
            currentRequestsArray.sort((a, b) => {
              const dateA = Date.parse(a.lastModifiedDate);
              const dateB = Date.parse(b.lastModifiedDate);
              return dateB - dateA;
            })[0]
          );

          if (currentRequestsArray.length > 0 || connections >= 3) {
            setIsLoading(false);
            console.log("isLoading = false");
          } else {
            setConnections(connections + 1);
            console.log("connections++");
          }

          console.log("Try: " + connections);
          console.log(response.data);
        });
    } else {
      console.log("isLoading = false");
    }
  }, [connections]);

  return (
    <div className={styles.homeMainContentContainer}>
      <div className={styles.homeFirstRowContainer}>
        <div className={styles.homeAccountContainer}>
          <div>
            <h3>Account:</h3>
            <Person personQuery="me" view={5} />
          </div>
          <div>
            {" "}
            <h3>Last Update:</h3>
            {lastUpdate !== undefined ? (
              <>
                <p>{lastUpdate.requestLabel}</p>
                <p>{lastUpdate.creationDate}</p>
              </>
            ) : (
              <p>No request</p>
            )}
          </div>
          <div>
            <h3>Last Request:</h3>
            {lastRequest !== undefined ? (
              <>
                <p>{lastRequest.requestLabel}</p>
                <p>{lastRequest.creationDate}</p>
              </>
            ) : (
              <p>No request</p>
            )}
          </div>
        </div>
        <div className={styles.homeNotificationsContainer}>
          <h3>Notifications settings:</h3>
          <div>
            <input type="checkbox" checked />
            <label>Receive all status change notifications by e-mail</label>
          </div>
        </div>
      </div>
      <div className={styles.homeContentContainer}>
        <div className={styles.currentRequestContainer}>
          <CurrentRequestTabele previousPage={"previousHome"} />
        </div>
        <MainMenuTiles />
      </div>
    </div>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    page: state.page,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRequest: () => dispatch(setRequest()),
    setCurrentRequest: () => dispatch(setCurrentRequests()),
    setHelp: () => dispatch(setHelp()),
    setRequestObject: (requestObject: IRequest) =>
      dispatch(setRequestObject(requestObject)),
    resetRequestObject: () => dispatch(resetRequestObject()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
