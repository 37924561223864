import { IRequest } from "../../interfaces/IRequest";
import {
  setCURRENTREQUESTOBJECT,
  resetCURRENTREQUESTOBJECT,
} from "../constants/currentRequestObject.types";

export const setCurrentRequestObject = (requestObject: IRequest | null) => {
  return {
    type: setCURRENTREQUESTOBJECT,
    payload: requestObject,
  };
};

export const resetRequestObject = () => {
  return {
    type: resetCURRENTREQUESTOBJECT,
  };
};
