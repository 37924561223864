import * as React from "react";
import styles from "./thirdPSP.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFourth,
  setSecond,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdPayments } from "react-icons/md";
import { useEffect, useState } from "react";
import { IdPspNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setPspContentObject } from "../../../../../../redux/actions/pspContentObject.actions";

function ThirdPLRC(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [choiceOfIntegrationmethod, setChoiceOfIntegrationmethod] = useState(
    props.pspContentObject.idIntegrationDescriptionNavigation
      .choiceOfIntegrationMethd
  );
  const [processFlowDescription, setProcessFlowDescription] = useState(
    props.pspContentObject.idIntegrationDescriptionNavigation
      .processFlowDescription
  );
  const [asynchronusCallback, setAsynchronusCallback] = useState(
    props.pspContentObject.idIntegrationDescriptionNavigation
      .asynchronusCallback
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdPayments className={styles.requestStageIcon} />
        <h5>Integration Description</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Choice of integration method</h4>
            <p>If multiple integration methods exist in the API</p>
            <textarea
              value={choiceOfIntegrationmethod}
              onChange={(e) => setChoiceOfIntegrationmethod(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Process flow description(s)</h4>
            <p>
              Possible process flows e.g., Authorise -{">"}{" "}
              capture/refund/cancel
            </p>
            <textarea
              value={processFlowDescription}
              onChange={(e) => setProcessFlowDescription(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Asynchronous callbacks or notifications </h4>
            <p>
              Are there asynchronous callbacks or notifications that can or
              should be implemented and for what reasons?
            </p>
            <textarea
              value={asynchronusCallback}
              onChange={(e) => setAsynchronusCallback(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setFourth();
          props.setPspContentObject({
            idIntegrationDescriptionNavigation: {
              choiceOfIntegrationMethd: choiceOfIntegrationmethod,
              processFlowDescription: processFlowDescription,
              asynchronusCallback: asynchronusCallback,
            },
          });
        }}
        back={() => {
          props.setSecond();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    pspContentObject: state.pspContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setSecond: () => dispatch(setSecond()),
    setFourth: () => dispatch(setFourth()),

    setPspContentObject: (pspObject: IdPspNavigation) =>
      dispatch(setPspContentObject(pspObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPLRC);
