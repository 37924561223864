import * as React from "react";
import { connect } from "react-redux";

import styles from "./requestViewTSI.module.scss";
import { useEffect, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import Download from "../../../atoms/downloadButton/download";

function RequestViewTSI(props: any) {
  interface FileListProps {
    url: string;
  }
  const containerName = "requestfiles";
  const accountName = "requestpoint";
  const sasToken =
    "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T23:37:22Z&st=2023-04-18T14:37:22Z&spr=https&sig=kk7j1tFdRCxNp5MZAJhH2kn9JNajJpzhyrKve5DD%2B08%3D";

  const [files, setFiles] = useState<string[]>([]);

  var directory = `${props.currentRequestObject.idStakeholdersNavigation.requestingPerson}/${props.currentRequestObject.requestLabel}/`;

  useEffect(() => {
    const listFiles = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net${sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const response = containerClient.listBlobsFlat({ prefix: directory });
        const fileList = [];
        for await (const blob of response) {
          fileList.push(blob.name);
        }
        setFiles(fileList);
        console.log(fileList);
      } catch (error) {
        console.error(error);
      }
    };

    listFiles();
  }, [
    accountName,
    sasToken,
    props.containerName,
    directory,
    props.currentRequestObject,
  ]);

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Partner and Customer</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Strategic Integration</h4>
            {props.currentRequestObject.idContentNavigation.idTsiNavigation
              .idTsiprojectInformationNavigation.strategic ? (
              <p>Yes</p>
            ) : (
              <p>No</p>
            )}
            <h4>Order Number</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .idTsiprojectInformationNavigation.orderNumber === "" ? (
                <>Empty</>
              ) : (
                <>
                  {
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idTsiprojectInformationNavigation
                      .orderNumber
                  }
                </>
              )}
            </p>
            <h4>Ticketing Partner company name</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .idTsiprojectInformationNavigation.partnerCompanyName === "" ? (
                <>Empty</>
              ) : (
                <>
                  {
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idTsiprojectInformationNavigation
                      .partnerCompanyName
                  }
                </>
              )}
            </p>
            <h4>Partner Company URL</h4>
            <p>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .idTsiprojectInformationNavigation.partnerUrl === "" ? (
                <>Empty</>
              ) : (
                <>
                  {
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idTsiprojectInformationNavigation
                      .partnerUrl
                  }
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Timeline</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>TSI Integration Kick-Off</h4>
            <p>
              {
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idTimelineNavigation.kickOffDate
              }
            </p>
            <h4>Software installation</h4>
            <p>
              {
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idTimelineNavigation.softwareInstallation
              }
            </p>
            <h4>Hardware installation</h4>
            <p>
              {
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idTimelineNavigation.hardwareInstallation
              }
            </p>
            <h4>User acceptance test</h4>
            <p>
              {
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idTimelineNavigation.userAcceptanceTest
              }
            </p>
            <h4>1st live event</h4>
            <p>
              {
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idTimelineNavigation.firstLiveEvent
              }
            </p>
            <h4>Additional timeline description</h4>

            {props.currentRequestObject.idContentNavigation.idTsiNavigation
              .idTimelineNavigation.additionalDescription === "" ? (
              <p>Empty</p>
            ) : (
              <p>
                {
                  props.currentRequestObject.idContentNavigation.idTsiNavigation
                    .idTimelineNavigation.additionalDescription
                }
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Ticket and Data Carriers:</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Product Catalogue</h4>
            {files.filter((str) => str.includes("tsiPC")).length > 0 ? (
              files
                .filter((str) => str.includes("tsiPC"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}

            <h4>Sample Tickets</h4>

            {files.filter((str) => str.includes("tsiST")).length > 0 ? (
              files
                .filter((str) => str.includes("tsiST"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
            <h4>Barcode Data Carriers</h4>
            <div className={styles.tsi__barcode__tableContainer}>
              <div className={styles.tsi__barcode__tableContainer__header}>
                <div
                  className={
                    styles.tsi__barcode__tableContainer__header__description
                  }
                >
                  <p>Barcode Type</p>
                </div>
                <div>
                  <p>Ticket Type</p>
                </div>
                <div>
                  <p>Paper Dimensions</p>
                </div>
                <div>
                  <p>Barcode Dimensions</p>
                </div>
              </div>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .barcodeDataCarriers.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idTsiNavigation.barcodeDataCarriers.map(
                  (barcodeObject: any) => (
                    <div
                      className={
                        styles.tsi__barcode__tableContainer__rowWithButtons
                      }
                    >
                      <div className={styles.tsi__barcode__tableContainer__row}>
                        <div>
                          <p>{barcodeObject.barcodeType}</p>
                        </div>
                        <div>
                          <p>{barcodeObject.ticketType}</p>
                        </div>
                        <div>
                          <p>{barcodeObject.paperDimensions}</p>
                        </div>
                        <div>
                          <p>{barcodeObject.barcodeDimensions}</p>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className={styles.tsi__barcode__emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </div>
            <h4>RFID Technology</h4>
            <div className={styles.tsi__barcode__tableContainer}>
              <div className={styles.tsi__barcode__tableContainer__header}>
                <div
                  className={
                    styles.tsi__barcode__tableContainer__header__description
                  }
                >
                  <p>RFID Type</p>
                </div>
                <div>
                  <p>Serial No Only</p>
                </div>
                <div>
                  <p>Block Sector</p>
                </div>
                <div>
                  <p>ApplicationId</p>
                </div>
                <div>
                  <p>MAD Read Key</p>
                </div>
                <div>
                  <p>Layer Read Key</p>
                </div>
              </div>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .rfidtechnology.length > 0 ? (
                props.currentRequestObject.idContentNavigation.idTsiNavigation.rfidtechnology.map(
                  (rfidObject: any) => (
                    <div
                      className={
                        styles.tsi__barcode__tableContainer__rowWithButtons
                      }
                    >
                      <div className={styles.tsi__barcode__tableContainer__row}>
                        <div>
                          <p>{rfidObject.rfidType}</p>
                        </div>
                        <div>
                          <p>{rfidObject.serialNoOnly ? "Yes" : "No"}</p>
                        </div>
                        <div>
                          <p>{rfidObject.blockSector}</p>
                        </div>
                        <div>
                          <p>{rfidObject.applicationId}</p>
                        </div>
                        <div>
                          <p>{rfidObject.madReadKey}</p>
                        </div>
                        <div>
                          <p>{rfidObject.layerReadKey}</p>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className={styles.tsi__barcode__emptyContainer}>
                  <p>Empty</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Venue and Event Organisation</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Venue Business</h4>
            <div className={styles.tsi__venue__venuBussines__radioContainer}>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.fair
                  }
                />
                <p>Fair</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.leisure
                  }
                />
                <p>Leisure</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.attraction
                  }
                />
                <p>Attraction</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.stadion
                  }
                />
                <p>Stadion</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.arena
                  }
                />
                <p>Arena</p>
              </div>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .idVenueNavigation.venueOwnValue !== null &&
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idVenueNavigation.venueOwnValue !== undefined &&
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idVenueNavigation.venueOwnValue.length > 0 && (
                  <div>
                    <input type="radio" />
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.venueOwnValue
                      }
                    </p>
                  </div>
                )}
            </div>

            <h4>Type of events</h4>
            <div className={styles.tsi__venue__typeOfEvents__radioContainer}>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.eventBased
                  }
                />
                <p>Event based</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idVenueNavigation.permanentOperation
                  }
                />
                <p>Permanent operation</p>
              </div>
              {props.currentRequestObject.idContentNavigation.idTsiNavigation
                .idVenueNavigation.eventOwnValue !== undefined &&
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idVenueNavigation.eventOwnValue !== null &&
                props.currentRequestObject.idContentNavigation.idTsiNavigation
                  .idVenueNavigation.eventOwnValue.length > 0 && (
                  <div>
                    <input type="radio" />
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.eventOwnValue
                      }
                    </p>
                  </div>
                )}
            </div>
            <h4>Expected visitors</h4>
            <div className={styles.tsi__barcode__tableContainer}>
              <div
                className={styles.tsi__barcode__tableContainer__header__single}
              >
                <div>
                  <p>Per Event</p>
                </div>
                <div>
                  <p>Maximum per hour</p>
                </div>
                <div>
                  <p>Average per hour</p>
                </div>
                <div>
                  <p>Average per day</p>
                </div>
                <div>
                  <p>Average per year</p>
                </div>
              </div>
              <div
                className={styles.tsi__barcode__tableContainer__rowWithButtons}
              >
                <div
                  className={styles.tsi__barcode__tableContainer__row__single}
                >
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.expectedPerEvent
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.maximumPerHour
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.averagePerHour
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.averageperDay
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.averageperYear
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Venue structure</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Single or Multiple clients?</h4>

            {props.currentRequestObject.idContentNavigation.idTsiNavigation
              .idVenueNavigation.isSingleClient ? (
              <p>Single</p>
            ) : (
              <p>Multiple</p>
            )}

            <h4>Venue architecture plan</h4>
            {files.filter((str) => str.includes("venuePlan")).length > 0 ? (
              files
                .filter((str) => str.includes("venuePlan"))
                .map((filePath) => <Download filePath={filePath} />)
            ) : (
              <p>No files attached</p>
            )}
            <h4>Amount of checkpoints</h4>
            <div className={styles.tsi__barcode__tableContainer}>
              <div
                className={styles.tsi__barcode__tableContainer__header__single}
              >
                <div>
                  <p>Flex.Gate</p>
                </div>
                <div>
                  <p>Vario.Gate</p>
                </div>
                <div>
                  <p>Arena.Gate</p>
                </div>
                <div>
                  <p>Mobile.Gate</p>
                </div>
                <div>
                  <p>Handheld.Gate</p>
                </div>
                <div>
                  <p>Lite.Gate</p>
                </div>
                <div>
                  <p>Door.Gate</p>
                </div>
                <div>
                  <p>Key Detector</p>
                </div>
                <div>
                  <p>Other</p>
                </div>
              </div>
              <div
                className={styles.tsi__barcode__tableContainer__rowWithButtons}
              >
                <div
                  className={styles.tsi__barcode__tableContainer__row__single}
                >
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.flexGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.varioGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.arenaGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.mobileGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.handheldGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.liteGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.doorGate
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.keyDetector
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      {
                        props.currentRequestObject.idContentNavigation
                          .idTsiNavigation.idVenueNavigation.otherAmount
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionContainer__sideBar} />
        <div className={styles.sectionContainer__window}>
          <h3>Integration Type</h3>
          <div className={styles.sectionContainer__window__contentContainer}>
            <h4>Support of the following use cases must be given:</h4>
            <div
              className={
                styles.sectionContainer__window__integrationTypes__container
              }
            >
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idIntegrationTypeNavigation.standardTSI
                  }
                />
                <p>Standard TSI Interface</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idIntegrationTypeNavigation.pii
                  }
                />
                <p>PII (Permission Inquiry Interface)</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idIntegrationTypeNavigation.extendedCheck
                  }
                />
                <p>Extended Check</p>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    props.currentRequestObject.idContentNavigation
                      .idTsiNavigation.idIntegrationTypeNavigation.update
                  }
                />
                <p>Update (existing integration)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps: any = (state: any) => {
  return {
    currentRequestObject: state.currentRequestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestViewTSI);
