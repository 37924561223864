import { Person } from "@microsoft/mgt-react";
import * as React from "react";

import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { connect } from "react-redux";
import { setHome } from "../../../../redux/actions/currentPage.actions";

import styles from "./ContactList.module.scss";

function ContactList(props: any) {
  return (
    <div className={styles.contactList__mainContainer}>
      <div className={styles.contactList__buttonContainer}>
        <button onClick={() => props.setHome()}>
          <MdOutlineArrowBackIosNew />
          Back to Home
        </button>
      </div>
      <h2>Contact List</h2>
      <div>
        <h3>Central Customized Solution Team</h3>
        <Person personQuery="me" view={5} />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
  };
};

export default connect(null, mapDispatchToProps)(ContactList);
