import { useState } from "react";
import styles from "./download.module.scss";

function Download(props: { filePath: string }) {
  const { filePath } = props;

  const downloadFile = () => {
    fetch(`https://requestpoint.blob.core.windows.net/requestfiles/${filePath}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filePath.split("/").pop() || "download";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error));
  };

  return (
    <button className={styles.buttonDownload} onClick={downloadFile}>
      {filePath.split("/").pop() || "file"}
    </button>
  );
}

export default Download;
