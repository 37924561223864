import { IReportsObject } from "../../interfaces/IReportsObject";
import { setREPORTSOBJECT } from "../constants/reportsObject.types";

const INITIAL_STATE: IReportsObject = {
  reportsDescription: "",
};

const ReportsObjectReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IReportsObject }
) {
  switch (action.type) {
    case setREPORTSOBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default ReportsObjectReducer;
