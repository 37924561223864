import styles from "./firstTSI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import {
  setHome,
  setTicketing,
} from "../../../../../../redux/actions/currentPage.actions";
import {
  setRequestType,
  setSecond,
  setStakeholders,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
  ResetBar,
} from "../../../../../../redux/actions/progressBarStatus.actions";

import { MdPeopleOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { setDefaultRequestType } from "../../../../../../redux/actions/requestType.actions";
import ToggleButton from "react-toggle-button";
import SdSubsidiaries from "../../../../molecules/sdSubsidiaries/sdSubsidiaries";
import CustomerContactPersonsTSI from "../../../../molecules/customerContactPersonsTSI/customerContactPersonsTSI";
import { IStakeholders } from "../../../../../../interfaces/stakeholders";
import { setStakeholdersObject } from "../../../../../../redux/actions/stakeholders.actions";
import { setRequestObject } from "../../../../../../redux/actions/requestObject.actions";
import { IRequest } from "../../../../../../interfaces/IRequest";
import { setTsiContentObject } from "../../../../../../redux/actions/tsiContentObject.actions";
import { IdTsiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import CountryDropDown from "../../../../molecules/countryDropDown/countryDropDown";

function FirstTSI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [isStrategic, setIsStrategic] = useState(
    props.tsiContentObject.idTsiprojectInformationNavigation.strategic
  );
  const [projectName, setProjectName] = useState(
    props.requestObject.requestLabel
  );
  const [orderNumber, setOrderNumber] = useState(
    props.tsiContentObject.idTsiprojectInformationNavigation.orderNumber
  );
  const [ticketingPartnerCompanyName, setTicketingPartnerCompanyName] =
    useState(
      props.tsiContentObject.idTsiprojectInformationNavigation
        .partnerCompanyName
    );

  //Requester
  const [requestingPerson, setRequestingPerson] = useState<string>("");
  const [email, setEmail] = useState<string>();
  const [contactPerson, setContactPerson] = useState("");
  const [subsidiary, setSubsidiary] = useState("");
  const [country, setCountry] = useState("");

  const handleCountryChange = (newCountry: string) => {
    setCountry(newCountry);
  };
  //Partner
  const [ticketingPartnerURL, setTicketingPartnerURL] = useState(
    props.tsiContentObject.idTsiprojectInformationNavigation.partnerUrl
  );

  //Customer
  const [customerName, setCustomerName] = useState("");
  const [webshopUrl, setWebshopUrl] = useState("");

  //Validation
  const [isFirstValidationDone, setIsFirstValidationDone] = useState(false);

  const [validationMessage, setValidationMessage] = useState(false);
  const [nameValidationMessage, setNameValidationMessage] = useState(false);
  const urlPattern = /^((www\.)|(ftp|http|https):\/\/)[^ "]+$/;

  const [isSubsidairyValid, setIsSubsidairyValid] = useState(true);
  const [isCountryValid, setIsCountryValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isOrderNumberValid, setIsOrderNumberValid] = useState(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isCustomerNameValid, setIsCustomerNameValid] = useState(true);
  const [isPartnerUrlValid, setIsPartnerUrlValid] = useState(true);
  const [isWebshoopUrlValid, setIsWebshoopUrlValid] = useState(true);

  const userName = props.user;

  useEffect(() => {
    setRequestingPerson(userName);
    setEmail(props.email);
    if (props.stakeholders !== null) {
      setContactPerson(props.stakeholders.contactPerson);
      setSubsidiary(props.stakeholders.subsidiary);
      setCountry(props.stakeholders.country);
      setCustomerName(props.stakeholders.customerName);
      setWebshopUrl(props.stakeholders.customerURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subsidiary !== "") {
      setIsSubsidairyValid(true);
    } else {
      setIsSubsidairyValid(false);
    }
  }, [subsidiary]);

  useEffect(() => {
    if (country !== "") {
      setIsCountryValid(true);
    } else {
      setIsCountryValid(false);
    }
  }, [country]);

  useEffect(() => {
    if (
      projectName &&
      projectName !== "New TSI Request" &&
      projectName.length > 3
    ) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  }, [projectName]);

  useEffect(() => {
    if (orderNumber !== "") {
      setIsOrderNumberValid(true);
    } else {
      setIsOrderNumberValid(false);
    }
  }, [orderNumber]);

  useEffect(() => {
    if (ticketingPartnerCompanyName !== "") {
      setIsCompanyNameValid(true);
    } else {
      setIsCompanyNameValid(false);
    }
  }, [ticketingPartnerCompanyName]);

  useEffect(() => {
    if (customerName !== "") {
      setIsCustomerNameValid(true);
    } else {
      setIsCustomerNameValid(false);
    }
  }, [customerName]);

  useEffect(() => {
    if (ticketingPartnerURL === "" || urlPattern.test(ticketingPartnerURL)) {
      setIsPartnerUrlValid(true);
    } else {
      setIsPartnerUrlValid(false);
    }
  }, [ticketingPartnerURL]);

  useEffect(() => {
    if (webshopUrl === "" || urlPattern.test(webshopUrl)) {
      setIsWebshoopUrlValid(true);
    } else {
      setIsWebshoopUrlValid(false);
    }
  }, [webshopUrl]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdPeopleOutline className={styles.requestStageIcon} />
        <h5>Project Information & Stakeholders</h5>
      </div>
      <div className={styles.inputColumn__requester__container}>
        <h4>Requester</h4>
        <div className={styles.inputColumn__requester__columns__container}>
          <div className={styles.inputColumn__requester__column}>
            <h5>
              Requesting Person<span>*</span>
            </h5>
            <input value={props.user} />
            <h5>Contact person</h5>
            <input
              placeholder="if different to Requester"
              value={contactPerson}
              onChange={(e: any) => setContactPerson(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn__requester__column}>
            <h5>
              Subsidiary/Partner<span>*</span>
            </h5>
            <SdSubsidiaries
              subsidiary={subsidiary}
              setSubsidiary={setSubsidiary}
              showValidationStyle={isFirstValidationDone && subsidiary === ""}
            />
            {subsidiary === "Other" && (
              <>
                <p></p>
                <input placeholder="Please specify partner name" />
              </>
            )}
            <h5>
              Country<span>*</span>
            </h5>
            <CountryDropDown
              onValueChange={handleCountryChange}
              showValidationStyle={isFirstValidationDone && country === ""}
            />
          </div>
        </div>
      </div>

      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div
            className={styles.inputColumn}
            style={{ border: "none", padding: "0" }}
          >
            <div>
              <h4>Project</h4>
              <h5>
                Project Name<span>*</span>
              </h5>
              <p>
                A unique project name is important to identify the project and
                to prevent misunderstandings. Project name as well as Order
                Number m ust be the same as in AX.
              </p>
              <div className={styles.inputBox__Container}>
                <input
                  className={
                    !isNameValid &&
                    isFirstValidationDone &&
                    styles.input__validationNotSuccesfull
                  }
                  value={projectName}
                  onChange={(e: any) => setProjectName(e.target.value)}
                />
                {!isNameValid && isFirstValidationDone && (
                  <div className={styles.input__validationBox}>
                    <p>
                      Please provide unique project name (longer than 3
                      characters)
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div
              className={styles.tsi__stakeholders__strategicCheckbox__container}
            >
              <div>
                <span className={styles.toggle__wrapper}>
                  <ToggleButton
                    value={isStrategic}
                    onToggle={(value: boolean) => {
                      setIsStrategic(!value);
                    }}
                    inactiveLabel={<p className={styles.toggle__text}>No</p>}
                    activeLabel={<p className={styles.toggle__text}>Yes</p>}
                    colors={{
                      activeThumb: {
                        base: "rgb(240, 240, 240)",
                      },
                      inactiveThumb: {
                        base: "rgb(240, 240, 240)",
                      },
                      active: {
                        base: "rgb(218, 165, 32)",
                      },
                      inactive: {
                        base: "rgb(95,96,98)",
                      },
                    }}
                  />
                </span>
                <h6>Strategic Integration</h6>
              </div>
            </div>
          </div>
          <div className={styles.inputColumn}>
            <div>
              <h5>
                Order Number<span>*</span>
              </h5>

              <p>Order Number must be the same as in AX.</p>
              <div className={styles.inputBox__Container}>
                <input
                  className={
                    !isOrderNumberValid &&
                    isFirstValidationDone &&
                    styles.input__validationNotSuccesfull
                  }
                  value={orderNumber}
                  onChange={(e: any) => setOrderNumber(e.target.value)}
                />
                {!isOrderNumberValid && isFirstValidationDone && (
                  <div className={styles.input__validationBox}>
                    <p>Please provide order number</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h4>Ticketing Partner</h4>
          <div className={styles.inputColumn}>
            <div>
              <h5>
                Company Name<span>*</span>
              </h5>
              <p>Provide the name of the ticketing partner</p>
              <div className={styles.inputBox__Container}>
                <input
                  className={
                    !isCompanyNameValid &&
                    isFirstValidationDone &&
                    styles.input__validationNotSuccesfull
                  }
                  value={ticketingPartnerCompanyName}
                  onChange={(e: any) =>
                    setTicketingPartnerCompanyName(e.target.value)
                  }
                />
                {!isCompanyNameValid && isFirstValidationDone && (
                  <div className={styles.input__validationBox}>
                    <p>Please provide company name</p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <h5>Partner URL</h5>

              <p>
                Please provide an URL to the homepage of the ticketing partner
              </p>
              <div className={styles.inputBox__Container}>
                <input
                  className={
                    !isPartnerUrlValid &&
                    isFirstValidationDone &&
                    styles.input__validationNotSuccesfull
                  }
                  value={ticketingPartnerURL}
                  onChange={(e: any) => setTicketingPartnerURL(e.target.value)}
                />
                {!isPartnerUrlValid && isFirstValidationDone && (
                  <div className={styles.input__validationBox}>
                    <p>Please provide the URL in the correct format.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h4>Customer</h4>
          <div className={styles.inputColumn}>
            <div>
              <h5>
                Name<span>*</span>
              </h5>
              <p>Venue e.g. Manchester United</p>
              <div className={styles.inputBox__Container}>
                <input
                  className={
                    !isCustomerNameValid &&
                    isFirstValidationDone &&
                    styles.input__validationNotSuccesfull
                  }
                  value={customerName}
                  onChange={(e: any) => setCustomerName(e.target.value)}
                />
                {!isCustomerNameValid && isFirstValidationDone && (
                  <div className={styles.input__validationBox}>
                    <p>Please provide company name</p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <h5>Webshop URL</h5>
              <p>
                If exist provide information about the webshop URL where tickets
                are sold
              </p>
              <div className={styles.inputBox__Container}>
                <input
                  className={
                    !isWebshoopUrlValid &&
                    isFirstValidationDone &&
                    styles.input__validationNotSuccesfull
                  }
                  value={webshopUrl}
                  onChange={(e: any) => setWebshopUrl(e.target.value)}
                />
                {!isWebshoopUrlValid && isFirstValidationDone && (
                  <div className={styles.input__validationBox}>
                    <p>Please provide the URL in the correct format.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerContactPersonsTSI />
      <p
        className={styles.errorMessage}
        style={validationMessage ? { display: "block" } : { display: "none" }}
      >
        Please fill in all the required fields.
      </p>
      <NextBackButtons
        next={() => {
          if (
            isSubsidairyValid &&
            isCountryValid &&
            isNameValid &&
            isCompanyNameValid &&
            isCustomerNameValid &&
            isOrderNumberValid &&
            isPartnerUrlValid &&
            isWebshoopUrlValid
          ) {
            setValidationMessage(false);
            props.Increment();
            props.setSecond();
            props.setRequestObject({
              ...props.requestObject,
              requestLabel: projectName,
            });
            props.setStakeholdersObject({
              requestingPerson: requestingPerson,
              email: email,
              contactPerson: contactPerson,
              subsidiary: subsidiary,
              country: country,
              customerName: customerName,
              customerURL: webshopUrl,
              customerContactPerson: props.customerContactPersonArray,
            });
            props.setTsiContentObject({
              ...props.tsiContentObject,
              idTsiprojectInformationNavigation: {
                orderNumber: orderNumber,
                strategic: isStrategic,
                partnerCompanyName: ticketingPartnerCompanyName,
                partnerUrl: ticketingPartnerURL,
              },
            });
          } else {
            setIsFirstValidationDone(true);
            setValidationMessage(true);
          }
        }}
        back={() => {
          props.setTicketing();
          props.ResetBar();
          props.setDefaultRequestType();
          props.setStakeholders();
          setValidationMessage(false);
          setNameValidationMessage(false);
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    user: state.user,
    email: state.email,
    customerContactPersonArray: state.customerContactPersonArray,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
    tsiContentObject: state.tsiContentObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestObject: (requestObject: IRequest) =>
      dispatch(setRequestObject(requestObject)),
    setRequestType: () => dispatch(setRequestType()),
    setSecond: () => dispatch(setSecond()),
    setTicketing: () => dispatch(setTicketing()),
    setDefaultRequestType: () => dispatch(setDefaultRequestType()),
    setStakeholdersObject: (stakeholdersObject: IStakeholders) =>
      dispatch(setStakeholdersObject(stakeholdersObject)),
    setTsiContentObject: (tsiContentObject: IdTsiNavigation) =>
      dispatch(setTsiContentObject(tsiContentObject)),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    ResetBar: () => dispatch(ResetBar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstTSI);
function setCustmerURL(customerURL: any) {
  throw new Error("Function not implemented.");
}
