import { IFunctionsAndContext } from "../../interfaces/IFunctionsAndContext";
import { setFUNCTIONSANDCONTEXT } from "../constants/functionsAndContext.types";

const INITIAL_STATE: IFunctionsAndContext = {
  functionsFeatures: "",
  solutionContext: "",
  supportedPlenv: "",
};

const FunctionsAndContextReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IFunctionsAndContext }
) {
  switch (action.type) {
    case setFUNCTIONSANDCONTEXT:
      return action.payload;
    default:
      return state;
  }
};

export default FunctionsAndContextReducer;
