export default function RequestTypeName(requestType: string | null) {
  let requestTypeName = ["Undefined", "Request"];

  if (requestType === null) {
    return requestTypeName;
  }

  switch (requestType) {
    case "parkingRequest":
      requestTypeName = ["Parking.Logic", "Generic Request"];
      break;
    case "parkingRate":
      requestTypeName = ["Parking.Logic", "Special Rate Calculation"];
      break;
    case "ticketingRequest":
      requestTypeName = ["Ticketing Partner", "Integration"];
      break;
    case "pspRequest":
      requestTypeName = ["PSP", "Request"];
      break;
    case "epiRequest":
      requestTypeName = ["EPI", "Request"];
      break;
    case "fiscalDeviceRequest":
      requestTypeName = ["Fiscal Device", "Integration"];
      break;
    case "uploadRequest":
      requestTypeName = ["Upload", "Request"];
      break;
    default:
      requestTypeName = ["Undefined", "Request"];
  }
  return requestTypeName;
}
