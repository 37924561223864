import {
  setADMINPANEL,
  setCONTACTLIST,
  setCURRENTREQUESTS,
  setHELP,
  setHOME,
  setREQUEST,
  setREQUESTVIEW,
  setTICKETING,
} from "../constants/currentPage.types";

export const setHome = () => {
  return {
    type: setHOME,
  };
};

export const setRequest = () => {
  return {
    type: setREQUEST,
  };
};

export const setTicketing = () => {
  return {
    type: setTICKETING,
  };
};

export const setCurrentRequests = () => {
  return {
    type: setCURRENTREQUESTS,
  };
};

export const setHelp = () => {
  return {
    type: setHELP,
  };
};

export const setContactList = () => {
  return {
    type: setCONTACTLIST,
  };
};

export const setAdminPanel = () => {
  return {
    type: setADMINPANEL,
  };
};

export const setRequestView = () => {
  return {
    type: setREQUESTVIEW,
  };
};
