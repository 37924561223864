import { addFILENAME, resetFILENAMES } from "../constants/fileNames.types";

const INITIAL_STATE: string[] = [];

const FileNamesReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: string }
) {
  switch (action.type) {
    case addFILENAME:
      return [...state, action.payload];
    case resetFILENAMES:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default FileNamesReducer;
