import * as React from "react";
import styles from "./fourthEPI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFifth,
  setFirstAfterType,
  setFourth,
  setSecond,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdConstruction, MdLibraryAddCheck, MdPayment } from "react-icons/md";

import { IdEpiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";

import { useEffect, useState } from "react";

import { setEpiContentObject } from "../../../../../../redux/actions/epiContentObject.actions";

function FourthEPI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [currencies, setCurrencies] = useState(
    props.epiContentObject.idConfigurationNavigation.currencies
  );
  const [languages, setLanguages] = useState(
    props.epiContentObject.idConfigurationNavigation.languages
  );
  const [refunds, setRefunds] = useState(
    props.epiContentObject.idConfigurationNavigation.refunds
  );
  const [cancelation, setCancelation] = useState(
    props.epiContentObject.idConfigurationNavigation.cancelation
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdConstruction className={styles.requestStageIcon} />
        <h5>Configuration</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>
              Currencies<span>*</span>
            </h4>
            <p>Should specific currencies be restricted or allowed?</p>
            <textarea
              value={currencies}
              onChange={(e) => setCurrencies(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>
              Languages<span>*</span>
            </h4>
            <p>
              If the terminal interface returns end-user messages or error
              codes, should these be translated to messages in different
              languages? Which languages are needed?
            </p>
            <textarea
              value={languages}
              onChange={(e) => setLanguages(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Refunds</h4>
            <p>
              In case of failure to book a payment in the access system, should
              a refund to the user’s card account be implemented (otherwise a
              credit note is issued to the user)?
            </p>
            <textarea
              value={refunds}
              onChange={(e) => setRefunds(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>Cancelation</h4>
            <p>Is a special handling required for cancellations?</p>
            <textarea
              value={cancelation}
              onChange={(e) => setCancelation(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setFifth();
          props.setEpiContentObject({
            ...props.epiContentObject,
            idConfigurationNavigation: {
              currencies: currencies,
              languages: languages,
              refunds: refunds,
              cancelation: cancelation,
            },
          });
        }}
        back={() => {
          props.setThird();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    epiContentObject: state.epiContentObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setFifth: () => dispatch(setFifth()),
    setThird: () => dispatch(setThird()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setEpiContentObject: (epiContentObject: IdEpiNavigation) =>
      dispatch(setEpiContentObject(epiContentObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FourthEPI);
