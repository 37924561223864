import * as React from "react";

import { connect } from "react-redux";

import { setPopOutTrue } from "../../../../../redux/actions/popOutWindow.actions";
import Summary from "../summary/summary";
import FifthPLRC from "./Fifth/fifthPLRC";
import FirstPLRC from "./First/firstPLRC";
import FourthPLRC from "./Fourth/fourthPLRC";
import SecondPLRC from "./Second/secondPLRC";
import ThirdPLRC from "./Third/thirdPLRC";

function PLRC(props: any) {
  React.useEffect(() => {
    document.getElementById("logo")?.scrollIntoView({ behavior: "smooth" });
  }, [props.progressBarStatus]);

  return (
    <>
      {props.requestStage === "firstAfterType" && <FirstPLRC />}
      {props.requestStage === "second" && <SecondPLRC />}
      {props.requestStage === "third" && <ThirdPLRC />}
      {props.requestStage === "fourth" && <FourthPLRC />}
      {props.requestStage === "fifth" && <FifthPLRC />}
      {props.requestStage === "summary" && <Summary />}
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestStage: state.requestStage,
    progressBarStatus: state.progressBarStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopOutTrue: () => dispatch(setPopOutTrue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PLRC);
