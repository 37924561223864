import * as React from "react";
import styles from "./thirdEPI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFirstAfterType,
  setFourth,
  setSecond,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdLibraryAddCheck, MdPayment } from "react-icons/md";

import { IdEpiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";

import { useEffect, useState } from "react";

import { setEpiContentObject } from "../../../../../../redux/actions/epiContentObject.actions";

function ThirdEPI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [testTerminal, setTestTerminal] = useState(
    props.epiContentObject.idTestEnvironmentNavigation.testTerminal
  );
  const [virtualEcrTest, setvirtualEcrTest] = useState(
    props.epiContentObject.idTestEnvironmentNavigation.virtualEcrTest
  );
  const [testCards, setTestCards] = useState(
    props.epiContentObject.idTestEnvironmentNavigation.testCards
  );

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdLibraryAddCheck className={styles.requestStageIcon} />
        <h5>Testing Environment</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>
              Test terminal availability<span>*</span>
            </h4>
            <p>
              Please specify if you are able to provide a test terminal for the
              developer and what will be included (e.g. serial cable etc). This
              equipment and setup should reflect they way the terminal will be
              used in the field. How quickly after ordering the CCS integration
              can the terminal be delivered?
            </p>
            <textarea
              value={testTerminal}
              onChange={(e) => setTestTerminal(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>
              Virtual ECR test environment<span>*</span>
            </h4>
            <p>
              Is there a virtual ECR (Electronic Cash Register) environment that
              the terminal can be connected to, to see expected messages,
              sequences and behaviour? Can this virtual ECR be used accross
              country boarders?
            </p>
            <textarea
              value={virtualEcrTest}
              onChange={(e) => setvirtualEcrTest(e.target.value)}
            />
          </div>
          <div className={styles.inputColumn}>
            <h4>
              Availability of test cards<span>*</span>
            </h4>
            <p>
              Can you provide payment cards for testing? What special cases can
              these cards create (CC, debit, wallet app, error cases - rejected,
              invalid, no funds etc.)
            </p>
            <textarea
              value={testCards}
              onChange={(e) => setTestCards(e.target.value)}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.Increment();
          props.setFourth();
          props.setEpiContentObject({
            ...props.epiContentObject,
            idTestEnvironmentNavigation: {
              testTerminal: testTerminal,
              virtualEcrTest: virtualEcrTest,
              testCards: testCards,
            },
          });
        }}
        back={() => {
          props.setSecond();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    epiContentObject: state.epiContentObject,
    requestObject: state.requestObject,
    stakeholders: state.stakeholders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setFirstAfterType: () => dispatch(setFirstAfterType()),
    setFourth: () => dispatch(setFourth()),
    setSecond: () => dispatch(setSecond()),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
    setEpiContentObject: (epiContentObject: IdEpiNavigation) =>
      dispatch(setEpiContentObject(epiContentObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdEPI);
