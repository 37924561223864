import * as React from "react";

import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { connect } from "react-redux";
import { setHome } from "../../../../redux/actions/currentPage.actions";
import CurrentRequestTabele from "../../molecules/currentRequestTabele/currentRequestTabele";

import styles from "./currentRequests.module.scss";

function ContactList(props: any) {
  return (
    <div className={styles.contactList__mainContainer}>
      <div className={styles.contactList__buttonContainer}>
        <button onClick={() => props.setHome()}>
          <MdOutlineArrowBackIosNew />
          Back to Home
        </button>
      </div>
      <CurrentRequestTabele previousPage={"previousCurrent"} />
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
  };
};

export default connect(null, mapDispatchToProps)(ContactList);
