import * as React from "react";
import styles from "./fourthTSI.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setFifth,
  setRequestType,
  setSecond,
  setThird,
} from "../../../../../../redux/actions/requestStage.actions ";

import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { MdAttachMoney, MdDone, MdSportsHandball } from "react-icons/md";
import { useEffect, useState } from "react";
import { HiOutlineTicket } from "react-icons/hi";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
} from "@azure/storage-blob";
import Dropzone from "react-dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IdTsiNavigation } from "../../../../../../interfaces/serviceModels/IRequestJSON";
import { setTsiContentObject } from "../../../../../../redux/actions/tsiContentObject.actions";

function FourthTSI(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [fair, setFair] = useState(
    props.tsiContentObject.idVenueNavigation.fair
  );
  const [leisure, setLeisure] = useState(
    props.tsiContentObject.idVenueNavigation.leisure
  );
  const [attraction, setAttraction] = useState(
    props.tsiContentObject.idVenueNavigation.attraction
  );
  const [stadion, setStadion] = useState(
    props.tsiContentObject.idVenueNavigation.stadion
  );
  const [area, setArea] = useState(
    props.tsiContentObject.idVenueNavigation.area
  );
  const [ownValue, setOwnValue] = useState(false);
  const [ownValueString, setOwnValueString] = useState(
    props.tsiContentObject.idVenueNavigation.ownValue
  );

  const [eventBased, setEventBased] = useState(
    props.tsiContentObject.idVenueNavigation.eventBased
  );
  const [permanentOperaton, setPermanentOperaton] = useState(
    props.tsiContentObject.idVenueNavigation.permanentOperation
  );
  const [eventOwnValue, setEventOwnValue] = useState(false);
  const [eventOwnValueString, setEventOwnValueString] = useState(
    props.tsiContentObject.idVenueNavigation.eventOwnValueString
  );

  const [expectedPerEvent, setExpectedPerEvent] = useState(
    props.tsiContentObject.idVenueNavigation.expectedPerEvent
  );
  const [maximumPerHour, setMaximumPerHour] = useState(
    props.tsiContentObject.idVenueNavigation.maximumPerHour
  );
  const [averagePerHour, setAveragePerHour] = useState(
    props.tsiContentObject.idVenueNavigation.averagePerHour
  );
  const [averagePerDay, setAveragePerDay] = useState(
    props.tsiContentObject.idVenueNavigation.averageperDay
  );
  const [averagePerYear, setAveragePerYear] = useState(
    props.tsiContentObject.idVenueNavigation.averageperYear
  );

  const [singleClient, setSingleClient] = useState(
    props.tsiContentObject.idVenueNavigation.isSingleClient
  );
  const [multipleClient, setMultipleClient] = useState(
    !props.tsiContentObject.idVenueNavigation.isSingleClient
  );

  const [flexGate, setFlexGate] = useState(
    props.tsiContentObject.idVenueNavigation.flexGate
  );
  const [varioGate, setVarioGate] = useState(
    props.tsiContentObject.idVenueNavigation.varioGate
  );
  const [arenaGate, setArenaGate] = useState(
    props.tsiContentObject.idVenueNavigation.arenaGate
  );
  const [mobileGate, setMobileGate] = useState(
    props.tsiContentObject.idVenueNavigation.mobileGate
  );
  const [handheldGate, setHandheldGate] = useState(
    props.tsiContentObject.idVenueNavigation.handheldGate
  );
  const [liteGate, setLiteGate] = useState(
    props.tsiContentObject.idVenueNavigation.liteGate
  );
  const [doorGate, setDoorGate] = useState(
    props.tsiContentObject.idVenueNavigation.doorGate
  );
  const [keyDetector, setKeyDetector] = useState(
    props.tsiContentObject.idVenueNavigation.keyDetector
  );
  const [otherAmount, setOtherAmount] = useState(
    props.tsiContentObject.idVenueNavigation.otherAmount
  );

  const [validationMessage, setValidationMessage] = useState(false);

  //File Upload Handler
  const AccountName = "requestpoint";
  const SasToken =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-03-01T17:10:28Z&st=2024-02-21T09:10:28Z&spr=https,http&sig=Qy0uW5EVw2fRg2%2F1LZerWZp4WAeY3C%2BbaZGBpgCysXU%3D";
  const ContainerName = "requestfiles";

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const [file, setFile] = React.useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = React.useState<Boolean>(false);
  const [uploadingError, setUploadingError] = React.useState<string | null>(
    null
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
          `https://${AccountName}.blob.core.windows.net?${SasToken}`,
          anonymousCredential
        );

        // Create a container client
        const containerClient =
          blobServiceClient.getContainerClient(ContainerName);

        // Check if the container exists
        const containerExists = await containerClient.exists();
        if (!containerExists) {
          // Create the container if it does not exist
          await containerClient.create();
        }

        // Encode the file as a Base64 string
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          // Upload the file to Azure Blob Storage
          const filePath = `${props.stakeholders.requestingPerson}/${props.requestObject.requestLabel}/${props.requestObject.creationDate}/$venuePlan-${file.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(filePath);

          const response: BlobUploadCommonResponse =
            await blockBlobClient.uploadData(file);

          console.log(`File "${file.name}" uploaded to Azure Blob Storage.`);
          setIsFileUploaded(true);
          props.addFileName(file.name);
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadingError(`Error uploading file: ${error}`);
      }
    }
  };

  React.useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <MdSportsHandball className={styles.requestStageIcon} />
        <h5>The Venue:</h5>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputColumn}>
            <h4>Venue and Event Organisation</h4>
            <p>
              Provide information about the venue and which type of venue it is.
            </p>
            <h5>
              Venue Business<span>*</span>
            </h5>
            <div
              className={styles.tsi__venue__venueBusiness__checkboxesContainer}
            >
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={fair}
                    onChange={() => setFair(!fair)}
                  />
                  <p>Fair</p>
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={leisure}
                    onChange={() => setLeisure(!leisure)}
                  />
                  <p>Leisure</p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={attraction}
                    onChange={() => setAttraction(!attraction)}
                  />
                  <p>Attraction</p>
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={stadion}
                    onChange={() => setStadion(!stadion)}
                  />
                  <p>Stadion </p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={area}
                    onChange={() => setArea(!area)}
                  />
                  <p>Arena </p>
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={ownValue}
                    onChange={() => setOwnValue(!ownValue)}
                  />
                  <p>Specify your own value</p>
                </div>
              </div>
            </div>
            {ownValue && (
              <div
                className={
                  styles.tsi__venue__venueBusiness__ownInput__container
                }
              >
                <input
                  placeholder="Specify your value"
                  className={styles.tsi__venue__venueBusiness__ownInput}
                  value={ownValueString}
                  onChange={(e: any) => setOwnValueString(e.target.value)}
                />
                <button className={styles.tsi__venue__venueBusiness__button}>
                  <MdDone />
                </button>
              </div>
            )}
            <h5>Provide information about the type of events</h5>

            <div
              className={styles.tsi__venue__venueBusiness__checkboxesContainer}
            >
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={eventBased}
                    onChange={() => setEventBased(!eventBased)}
                  />
                  <p>Event based </p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={permanentOperaton}
                    onChange={() => setPermanentOperaton(!permanentOperaton)}
                  />
                  <p>Permanent operation</p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={eventOwnValue}
                    onChange={() => setEventOwnValue(!eventOwnValue)}
                  />
                  <p>Specify your own value</p>
                </div>
              </div>
            </div>
            {eventOwnValue && (
              <div
                className={
                  styles.tsi__venue__venueBusiness__ownInput__container
                }
              >
                <input
                  placeholder="Specify your value"
                  className={styles.tsi__venue__venueBusiness__ownInput}
                  value={eventOwnValueString}
                  onChange={(e: any) => setEventOwnValue(e.target.value)}
                />
                <button className={styles.tsi__venue__venueBusiness__button}>
                  <MdDone />
                </button>
              </div>
            )}
            <h5>
              Provide information about expected visitors<span>*</span>
            </h5>
            <div className={styles.tsi__venue__expectedVisitors__container}>
              <div>
                <h6>Per Event</h6>
                <input
                  value={expectedPerEvent}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setExpectedPerEvent(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Maximum per hour</h6>
                <input
                  value={maximumPerHour}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setMaximumPerHour(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Average per hour</h6>
                <input
                  value={averagePerHour}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setAveragePerHour(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Average per day</h6>
                <input
                  value={averagePerDay}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setAveragePerDay(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Average per year</h6>
                <input
                  value={averagePerYear}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setAveragePerYear(numericInput);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputColumn}>
            <h4>Venue structure</h4>
            <h5>
              Is the venue uses by one single client or by multiple clients?
            </h5>
            <div
              className={styles.tsi__venue__venueBusiness__checkboxesContainer}
            >
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={singleClient}
                    onChange={() => {
                      setSingleClient(!singleClient);
                      setMultipleClient(false);
                    }}
                  />
                  <p>Single Client </p>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={multipleClient}
                    onChange={() => {
                      setMultipleClient(!multipleClient);
                      setSingleClient(false);
                    }}
                  />
                  <p>Multiple Client </p>
                </div>
              </div>
            </div>
            <h5>Provide a graphical plan of the venue architecture</h5>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                setFile(acceptedFiles[0]);
                handleUpload();
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <div className={styles.dropBoxIconWrapper}>
                    <IoIosAddCircleOutline />
                  </div>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone>
            <p className={styles.addedFillesText}>Added File:</p>
            <ul>
              {isFileUploaded ? <li>{file?.name}</li> : <li>No files</li>}
            </ul>
            {isFileUploaded && <p>File has been successfully uploaded. </p>}
            {uploadingError && <p>{uploadingError}</p>}
            <h5>
              Provide information about the amount of checkpoints per type.
            </h5>
            <div className={styles.tsi__venue__checkpoints__container}>
              <div>
                <h6>Flex.Gate</h6>
                <input
                  value={flexGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setFlexGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Vario.Gate</h6>
                <input
                  value={varioGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setVarioGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Arena.Gate</h6>
                <input
                  value={arenaGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setArenaGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Mobile.Gate</h6>
                <input
                  value={mobileGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setMobileGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Handheld.Gate</h6>
                <input
                  value={handheldGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setHandheldGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Lite.Gate</h6>
                <input
                  value={liteGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setLiteGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Door.Gate</h6>
                <input
                  value={doorGate}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setDoorGate(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Key Detector</h6>
                <input
                  value={keyDetector}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setKeyDetector(numericInput);
                  }}
                />
              </div>
              <div>
                <h6>Other</h6>
                <input
                  value={otherAmount}
                  onChange={(e: any) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setOtherAmount(numericInput);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        className={styles.errorMessage}
        style={
          validationMessage
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        Please fill in all the required fields.
      </p>
      <NextBackButtons
        next={() => {
          if (fair || leisure || attraction || stadion || area || ownValue) {
            if (
              expectedPerEvent != "" ||
              maximumPerHour != "" ||
              averagePerHour != "" ||
              averagePerDay != "" ||
              averagePerYear != ""
            ) {
              props.Increment();
              props.setFifth();
              props.setTsiContentObject({
                ...props.tsiContentObject,
                idVenueNavigation: {
                  fair: fair,
                  leisure: leisure,
                  attraction: attraction,
                  stadion: stadion,
                  arena: area,
                  venueOwnValue: ownValueString,
                  eventBased: eventBased,
                  permanentOperation: permanentOperaton,
                  eventOwnValue: eventOwnValueString,
                  expectedPerEvent: expectedPerEvent,
                  maximumPerHour: maximumPerHour,
                  averagePerHour: averagePerHour,
                  averageperDay: averagePerDay,
                  averageperYear: averagePerYear,
                  isSingleClient: singleClient,
                  flexGate: flexGate,
                  varioGate: varioGate,
                  arenaGate: arenaGate,
                  mobileGate: mobileGate,
                  handheldGate: handheldGate,
                  liteGate: liteGate,
                  doorGate: doorGate,
                  keyDetector: keyDetector,
                  otherAmount: otherAmount,
                },
              });
            } else {
              setValidationMessage(true);
            }
          } else {
            setValidationMessage(true);
          }
        }}
        back={() => {
          props.setThird();
          props.Decrement();
          setValidationMessage(false);
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    tsiContentObject: state.tsiContentObject,
    stakeholders: state.stakeholders,
    requestObject: state.requestObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setThird: () => dispatch(setThird()),
    setFifth: () => dispatch(setFifth()),

    setTsiContentObject: (tsiContentObject: IdTsiNavigation) =>
      dispatch(setTsiContentObject(tsiContentObject)),

    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FourthTSI);
