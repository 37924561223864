import { IOtherPLGR } from "../../interfaces/IOtherPLGR";
import { setOTHERPLGR } from "../constants/otherPLGRObject.types";

const INITIAL_STATE: IOtherPLGR = {
  outOfScope: "",
  revenatDecisionsn: "",
  nonFunctionalRequirements: "",
  assumptionsMade: "",
};

const OtherPLGRReducer = function (
  state = INITIAL_STATE,
  action: { type: string; payload: IOtherPLGR }
) {
  switch (action.type) {
    case setOTHERPLGR:
      return action.payload;
    default:
      return state;
  }
};

export default OtherPLGRReducer;
