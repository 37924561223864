import * as React from "react";
import styles from "./useCases.module.scss";
import NextBackButtons from "../../../../molecules/nextBackButtons/nextBackButtons";

import { connect } from "react-redux";
import { setHome } from "../../../../../../redux/actions/currentPage.actions";
import {
  setContext,
  setRequestType,
} from "../../../../../../redux/actions/requestStage.actions ";

import AddButton from "../../../../atoms/backToMenuButton/addButton/addButton";
import {
  Decrement,
  Increment,
} from "../../../../../../redux/actions/progressBarStatus.actions";
import RequestTypeName from "../../logic/requestTypeName.bs";
import { FaCogs } from "react-icons/fa";
import { useEffect, useState } from "react";
import { IUseCase } from "../../../../../../interfaces/IUseCase";
import { setUseCasesObject } from "../../../../../../redux/actions/useCases.actions";
import { MdClose } from "react-icons/md";

const emptyUseCase: IUseCase = {
  description: "",
  preConditions: "",
  postConditions: "",
};

function UseCases(props: any) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let requestTypeName = RequestTypeName(props.requestType);

  const [useCase, setUseCase] = useState<IUseCase>(emptyUseCase);

  return (
    <>
      <div className={styles.stageTitleContainer}>
        <FaCogs className={styles.requestStageIcon} />
        <h5>UseCases</h5>
        <p>
          Describe the use-cases (customer journey/experience) in a detailed,
          step-by-step way. Explain how the solution is expected to behave in
          each step and include use-cases for user-misuse and other exceptional
          situations. Describe the initial conditions and final condition in
          each case.
        </p>
      </div>
      <div className={styles.requestContentContainer}>
        <div className={styles.useCases__tableContainer}>
          <div className={styles.useCases__tableContainer__header}>
            <div
              className={styles.useCases__tableContainer__header__description}
            >
              <p>Description</p>
            </div>
            <div>
              <p>Pre-conditions</p>
            </div>
            <div>
              <p>Post-Condition</p>
            </div>
          </div>
          {props.useCasesObject.length > 0 ? (
            props.useCasesObject.map((useCase: IUseCase) => (
              <div className={styles.useCases__tableContainer__rowWithButtons}>
                <div className={styles.useCases__tableContainer__row}>
                  <div>
                    <p>{useCase.description}</p>
                  </div>
                  <div>
                    <p>{useCase.preConditions}</p>
                  </div>
                  <div>
                    <p>{useCase.postConditions}</p>
                  </div>
                </div>
                <div className={styles.table__buttonsContainer}>
                  <button
                    onClick={() =>
                      props.setUseCasesObject(
                        props.useCasesObject.filter(function (el: IUseCase) {
                          return el !== useCase;
                        })
                      )
                    }
                  >
                    <MdClose />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.emptyContainer}>
              <p>Empty</p>
            </div>
          )}
        </div>
        <div className={styles.inputContainer}>
          <h4>Add Use Case</h4>
          <div className={styles.descriptionInputContainer}>
            <h5>Description</h5>
            <textarea
              placeholder="For example: We can authorize on page http://example.com/login"
              value={useCase?.description}
              onChange={(e: any) =>
                setUseCase({
                  description: e.target.value,
                  preConditions: useCase?.preConditions,
                  postConditions: useCase?.postConditions,
                })
              }
            />
          </div>
          <div className={styles.conditionInputContainer}>
            <div>
              <h5>Pre-conditions</h5>
              <textarea
                placeholder="Empty value"
                value={useCase?.preConditions}
                onChange={(e: any) =>
                  setUseCase({
                    description: useCase?.description,
                    preConditions: e.target.value,
                    postConditions: useCase?.postConditions,
                  })
                }
              />
            </div>
            <div>
              <h5>Post-conditions</h5>
              <textarea
                placeholder="Empty value"
                value={useCase?.postConditions}
                onChange={(e: any) =>
                  setUseCase({
                    description: useCase?.description,
                    preConditions: useCase?.preConditions,
                    postConditions: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.addButtonContainer}>
            <AddButton
              onClick={() => {
                if (useCase !== emptyUseCase) {
                  props.setUseCasesObject([...props.useCasesObject, useCase]);
                  setUseCase(emptyUseCase);
                }
              }}
            />
          </div>
        </div>
      </div>
      <NextBackButtons
        next={() => {
          props.setContext();
          props.Increment();
        }}
        back={() => {
          props.setRequestType();
          props.Decrement();
        }}
      />
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    requestType: state.requestType,
    useCasesObject: state.useCasesObject,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setRequestType: () => dispatch(setRequestType()),
    setContext: () => dispatch(setContext()),
    setUseCasesObject: (useCasesobject: IUseCase[]) =>
      dispatch(setUseCasesObject(useCasesobject)),
    Increment: () => dispatch(Increment()),
    Decrement: () => dispatch(Decrement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UseCases);
